export const hardModeSongs = [
  {
    "albumName": "Land of Fans and Music 2",
    "artist": [
      "Yan Rodriguez"
    ],
    "name": "Trapped",
    "tags": [
      "Karkat",
      "Veil"
    ],
    "youtubeId": "BzUmLUgjkdk"
  },
  {
    "albumName": "Land of Fans and Music",
    "artist": [
      "Xenocidebot"
    ],
    "name": "MegaloVaniaC",
    "tags": [
      "Vriska"
    ],
    "youtubeId": "a9URB9XKhH4"
  },
  {
    "albumName": "Land of Fans and Music",
    "artist": [
      "Thomas Ferkol"
    ],
    "name": "Land of Quartz and Melody",
    "tags": [
      "LoQaM"
    ],
    "youtubeId": "frJDF4Z2iI8"
  },
  {
    "albumName": "Land of Fans and Music 3",
    "artist": [
      "Dakota Medina"
    ],
    "name": "The Blast",
    "tags": [
      "Aradia",
      "Sollux",
      "Alternia"
    ],
    "youtubeId": "lF-FrdtRYl4"
  },
  {
    "albumName": "Land of Fans and Music 2",
    "artist": [
      "Trent West"
    ],
    "name": "Four",
    "tags": [
      "Skaia",
      "LoWaS",
      "LoLaR",
      "LoHaC",
      "LoFaF"
    ],
    "youtubeId": "eB2Isac-eXs"
  },
  {
    "albumName": "Land of Fans and Music 4",
    "artist": [
      "Marcy Nabors"
    ],
    "name": "Listless Squiddly Bliss",
    "tags": [
      "Squiddles"
    ],
    "youtubeId": "m3sA6i8c7y0"
  },
  {
    "albumName": "Land of Fans and Music 5",
    "artist": [
      "Kusoro"
    ],
    "name": "Final Spice (Remix)",
    "tags": [
      "Joey"
    ],
    "youtubeId": "ejwHOMimBz8"
  },
  {
    "albumName": "Homestuck for the Holidays",
    "artist": [
      "Thomas Ferkol"
    ],
    "name": "Gog Rest Ye Merry Prospitians",
    "tags": [
      "John",
      "Rose",
      "Dave",
      "Jade",
      "Squiddles",
      "Birds",
      "LoFaF"
    ],
    "youtubeId": "H1s0RX4ikfE"
  },
  {
    "albumName": "Land of Fans and Music 3",
    "artist": [
      "Astro Kid"
    ],
    "name": "Starblaze",
    "tags": [
      "Jane",
      "Roxy",
      "Dirk",
      "Jake",
      "LoCaH"
    ],
    "youtubeId": "xOSapZrPB6Q"
  },
  {
    "albumName": "Land of Fans and Music 4",
    "artist": [
      "Tamara Fritz"
    ],
    "name": "Red",
    "tags": [
      "Mituna",
      "Latula"
    ],
    "youtubeId": "2DYQtfGgntI"
  },
  {
    "albumName": "Land of Fans and Music 2",
    "artist": [
      "Willow Ascenzo",
      "D. Crystal"
    ],
    "name": "Doghead",
    "tags": [
      "Jade",
      "Green Sun"
    ],
    "youtubeId": "wXJ4nXAu2CM"
  },
  {
    "albumName": "Land of Fans and Music",
    "artist": [
      "Traikan"
    ],
    "name": "A War of One Bullet",
    "tags": [],
    "youtubeId": "IiQCV9XDw9o"
  },
  {
    "albumName": "Land of Fans and Music",
    "artist": [
      "Catboss"
    ],
    "name": "Beginnings (Press Start to Play)",
    "tags": [
      "John",
      "Rose",
      "Dave",
      "Jade",
      "Earth"
    ],
    "youtubeId": "WLa8iqsW_x8"
  },
  {
    "albumName": "Land of Fans and Music 4",
    "artist": [
      "Aris Martinian"
    ],
    "name": "Dersite",
    "tags": [
      "Carapacians",
      "Derse"
    ],
    "youtubeId": "M_KnHTd2jn0"
  },
  {
    "albumName": "Land of Fans and Music 2",
    "artist": [
      "Joe Ouellet"
    ],
    "name": "Electrocumulus",
    "tags": [
      "John",
      "WV",
      "Battlefield"
    ],
    "youtubeId": "GN3RTQJ2y2o"
  },
  {
    "albumName": "Land of Fans and Music 4",
    "artist": [
      "ehlsea"
    ],
    "name": "Black Hole / White Door",
    "tags": [
      "John",
      "Rose",
      "Dave",
      "Jade",
      "Jane",
      "Roxy",
      "Dirk",
      "Jake",
      "Karkat",
      "Kanaya",
      "Terezi",
      "Calliope",
      "Skaia"
    ],
    "youtubeId": "ZQ2rZH_4xWY"
  },
  {
    "albumName": "Land of Fans and Music 5",
    "artist": [
      "Momora"
    ],
    "name": "Crystalschalasthemequins",
    "tags": [
      "LoFaM"
    ],
    "youtubeId": "LzVlLI5h_p8"
  },
  {
    "albumName": "Land of Fans and Music 4",
    "artist": [
      "Ngame"
    ],
    "name": "Three in the Morning (Ngame's Bowmix) [Explicit]",
    "tags": [
      "Snowman"
    ],
    "youtubeId": "Vo7tZPGX9Sg"
  },
  {
    "albumName": "Land of Fans and Music 5",
    "artist": [
      "Kanishka"
    ],
    "name": "Pipe Dream",
    "tags": [
      "LoWaS"
    ],
    "youtubeId": "SVLv3dQP6Bo"
  },
  {
    "albumName": "Land of Fans and Music 3",
    "artist": [
      "Yan Rodriguez"
    ],
    "name": "Run-Stab-Run",
    "tags": [
      "Jack Noir",
      "Underlings",
      "LoPaH"
    ],
    "youtubeId": "hGWajP5G6yU"
  },
  {
    "albumName": "Land of Fans and Music 5",
    "artist": [
      "Gryotharian"
    ],
    "name": "TEETH AND BLOOD",
    "tags": [
      "Caliborn",
      "Earth"
    ],
    "youtubeId": "8rELmI9IpQY"
  },
  {
    "albumName": "Land of Fans and Music",
    "artist": [
      "Brenna Curran"
    ],
    "name": "Vigilante",
    "tags": [
      "Terezi"
    ],
    "youtubeId": "LqmPOZvgY9c"
  },
  {
    "albumName": "Land of Fans and Music 2",
    "artist": [
      "Brenden Cameron"
    ],
    "name": "He Is Already Here",
    "tags": [
      "AH"
    ],
    "youtubeId": "XTyFPX0dn_U"
  },
  {
    "albumName": "Land of Fans and Music 4",
    "artist": [
      "Interrobang"
    ],
    "name": "Pilotwave",
    "tags": [
      "Jade",
      "Becquerel",
      "LoWaS",
      "LoLaR",
      "LoHaC"
    ],
    "youtubeId": "Ozqewxm2QcQ"
  },
  {
    "albumName": "Homestuck for the Holidays",
    "artist": [
      "Robert J! Lake"
    ],
    "name": "Candlelight",
    "tags": [
      "The Felt"
    ],
    "youtubeId": "9G1ttVEPGN8"
  },
  {
    "albumName": "Land of Fans and Music 2",
    "artist": [
      "Ian White",
      "Lauren Collis",
      "Mike Anderson"
    ],
    "name": "The Ballad of Maplehoof",
    "tags": [
      "John",
      "Rose",
      "Dave",
      "Jade",
      "John's dad",
      "Maplehoof",
      "Earth"
    ],
    "youtubeId": "0NrHukinkmw"
  },
  {
    "albumName": "Land of Fans and Music 4",
    "artist": [
      "ndividedbyzero"
    ],
    "name": "Fruitjam",
    "tags": [
      "John",
      "Rose",
      "Dave",
      "Jade"
    ],
    "youtubeId": "kKKh0izriyc"
  },
  {
    "albumName": "Land of Fans and Music",
    "artist": [
      "Isora\u0137athe\u00f0 Zorethan F."
    ],
    "name": "Maibasojen",
    "tags": [],
    "youtubeId": "-P9FdBPpY04"
  },
  {
    "albumName": "The Baby is You",
    "artist": [
      "Toby Fox"
    ],
    "name": "Throguh Song",
    "tags": [
      "John",
      "Karkat",
      "Dave"
    ],
    "youtubeId": "KSHPZp33vv4"
  },
  {
    "albumName": "Land of Fans and Music 4",
    "artist": [
      "Thomas Ferkol"
    ],
    "name": "Cleopatra's Cocktail Party",
    "tags": [
      "Roxy",
      "LoPaN"
    ],
    "youtubeId": "YL9xC_Ft-5s"
  },
  {
    "albumName": "Land of Fans and Music 2",
    "artist": [
      "Thomas Ferkol"
    ],
    "name": "Nevermore (Can't Sleep, Crows Will Find Me)",
    "tags": [
      "Birds"
    ],
    "youtubeId": "SCNRj_vp9SM"
  },
  {
    "albumName": "Land of Fans and Music 4",
    "artist": [
      "Veritas Unae"
    ],
    "name": "Dance of the Dead",
    "tags": [
      "Jack Noir",
      "DD",
      "CD",
      "HB"
    ],
    "youtubeId": "qJii_lbgYN0"
  },
  {
    "albumName": "Land of Fans and Music 2",
    "artist": [
      "Astro Kid"
    ],
    "name": "ElecTrollCopSwing",
    "tags": [
      "Dave",
      "Karkat",
      "Terezi",
      "WV"
    ],
    "youtubeId": "cMzUrZMMIG0"
  },
  {
    "albumName": "Land of Fans and Music 4",
    "artist": [
      "Hadron"
    ],
    "name": "Formation",
    "tags": [
      "Caliborn",
      "Earth"
    ],
    "youtubeId": "dml3MDHvjmM"
  },
  {
    "albumName": "Homestuck for the Holidays",
    "artist": [
      "Nick Smalley"
    ],
    "name": "The Santa Claus Interdimensional Travel Sleigh",
    "tags": [
      "John",
      "Rose",
      "Dave",
      "Jade",
      "Squiddles",
      "Birds",
      "LoFaF"
    ],
    "youtubeId": "rQSGIuPHQs0"
  },
  {
    "albumName": "Homestuck for the Holidays",
    "artist": [
      "Nick Smalley"
    ],
    "name": "Hella Sweet",
    "tags": [
      "John",
      "Rose",
      "Dave",
      "Jade",
      "Squiddles",
      "Birds",
      "LoFaF"
    ],
    "youtubeId": "UsxUACjWDiw"
  },
  {
    "albumName": "Land of Fans and Music 2",
    "artist": [
      "Joe Ouellet"
    ],
    "name": "A Stitch In Time",
    "tags": [
      "The Felt",
      "Felt Manor"
    ],
    "youtubeId": "_y_NODhJfDE"
  },
  {
    "albumName": "Land of Fans and Music 5",
    "artist": [
      "heir-of-puns"
    ],
    "name": "ALIVE",
    "tags": [
      "Aradia"
    ],
    "youtubeId": "C1BsYfejnEI"
  },
  {
    "albumName": "Land of Fans and Music",
    "artist": [
      "Trent West"
    ],
    "name": "Skaian Air",
    "tags": [
      "John",
      "Battlefield"
    ],
    "youtubeId": "jost0oi6dLY"
  },
  {
    "albumName": "Land of Fans and Music",
    "artist": [
      "Willow Ascenzo"
    ],
    "name": "The Drawing of the Four",
    "tags": [
      "John",
      "Rose",
      "Dave",
      "Jade",
      "Earth"
    ],
    "youtubeId": "_Ory1eb632I"
  },
  {
    "albumName": "Land of Fans and Music 4",
    "artist": [
      "Kusoro"
    ],
    "name": "One and a Half Midnight",
    "tags": [
      "Jack Noir"
    ],
    "youtubeId": "Cv6Ca070MPk"
  },
  {
    "albumName": "Land of Fans and Music 5",
    "artist": [
      "Kusoro"
    ],
    "name": "Abstract",
    "tags": [
      "The Theseus"
    ],
    "youtubeId": "TV0NEBDv03k"
  },
  {
    "albumName": "Land of Fans and Music 2",
    "artist": [
      "Kevin Grant"
    ],
    "name": "Fighting Spirit",
    "tags": [
      "John",
      "Rose",
      "Dave",
      "Jade"
    ],
    "youtubeId": "2Scn4TRi7m0"
  },
  {
    "albumName": "Land of Fans and Music 4",
    "artist": [
      "Astro Kid"
    ],
    "name": "Beatup (Shutdown)",
    "tags": [
      "Dave",
      "Dave's bro",
      "Earth"
    ],
    "youtubeId": "HyLNyBrJp7U"
  },
  {
    "albumName": "Land of Fans and Music 2",
    "artist": [
      "Kevin Grant"
    ],
    "name": "Fighting Spirit ~Second Form~",
    "tags": [
      "Dirk",
      "Sawtooth",
      "Squarewave",
      "Earth"
    ],
    "youtubeId": "gX4RAeGIDEM"
  },
  {
    "albumName": "Land of Fans and Music 5",
    "artist": [
      "Kusoro"
    ],
    "name": "One Hour Later",
    "tags": [
      "Jack Noir",
      "Dave",
      "Dirk"
    ],
    "youtubeId": "jsduPSUVQdQ"
  },
  {
    "albumName": "Land of Fans and Music 4",
    "artist": [
      "Cement City"
    ],
    "name": "Savior of the Waking World (Cement City Remix)",
    "tags": [
      "John",
      "LoWaS"
    ],
    "youtubeId": "wBzoGYEZtUQ"
  },
  {
    "albumName": "Land of Fans and Music 3",
    "artist": [
      "Rob Little"
    ],
    "name": "Tombs & Krypton",
    "tags": [
      "Dirk",
      "SBaHJ",
      "LoTaK"
    ],
    "youtubeId": "ZtRIbRMe5Jc"
  },
  {
    "albumName": "Land of Fans and Music 4",
    "artist": [
      "psithurist"
    ],
    "name": "Limelight",
    "tags": [
      "Alternate Calliope",
      "Green Sun"
    ],
    "youtubeId": "nHOzQpvGbHY"
  },
  {
    "albumName": "Land of Fans and Music 5",
    "artist": [
      "SplitSuns"
    ],
    "name": "F\u221e \u30af\u30e9\u30a4\u30df\u30f3\u30b0\u300eCLIMBING\u300f",
    "tags": [
      "LoLaR"
    ],
    "youtubeId": "1UWlt1H1FrI"
  },
  {
    "albumName": "Land of Fans and Music 4",
    "artist": [
      "Nicholas Nakano"
    ],
    "name": "Solar Momentum",
    "tags": [
      "PM",
      "Jack Noir"
    ],
    "youtubeId": "rV9WHJyx30A"
  },
  {
    "albumName": "Land of Fans and Music 2",
    "artist": [
      "PhemieC"
    ],
    "name": "Ugly Story",
    "tags": [
      "Eridan",
      "Sollux",
      "Feferi",
      "Vriska"
    ],
    "youtubeId": "Nbxu8jl_jVU"
  },
  {
    "albumName": "Land of Fans and Music 3",
    "artist": [
      "Yan Rodriguez"
    ],
    "name": "Thermporia",
    "tags": [
      "Dave",
      "LoHaC"
    ],
    "youtubeId": "fw6QZk-KT3c"
  },
  {
    "albumName": "Land of Fans and Music 3",
    "artist": [
      "Arzenic"
    ],
    "name": "Medium",
    "tags": [
      "Consorts",
      "Denizens",
      "Skaia",
      "Prospit",
      "LoWaS",
      "LoLaR",
      "LoHaC",
      "LoFaF",
      "Veil",
      "Derse",
      "Horrorterrors"
    ],
    "youtubeId": "ZSnq_Tfalsc"
  },
  {
    "albumName": "Land of Fans and Music 5",
    "artist": [
      "artist:kobacat"
    ],
    "name": "PAST FUNK IS WORSE THAN FUTURE FUNK",
    "tags": [
      "Karkat",
      "Prospit",
      "Felt Manor"
    ],
    "youtubeId": "coySV5sYMR0"
  },
  {
    "albumName": "Land of Fans and Music 5",
    "artist": [
      "Circlejourney"
    ],
    "name": "On an Island Far Away",
    "tags": [
      "Jade",
      "Becquerel",
      "Frog Temple",
      "Earth"
    ],
    "youtubeId": "ZeAOEkQMrHU"
  },
  {
    "albumName": "Land of Fans and Music 5",
    "artist": [
      "subversiveasset"
    ],
    "name": "Heir of Grief (Sax Cover)",
    "tags": [
      "PM",
      "Green Sun"
    ],
    "youtubeId": "3pZ_hspYvz4"
  },
  {
    "albumName": "Land of Fans and Music 4",
    "artist": [
      "cookiefonster"
    ],
    "name": "The Gemoni Mustard Blood",
    "tags": [
      "Sollux",
      "Bees",
      "Alternia"
    ],
    "youtubeId": "vf9Cwf5OqgE"
  },
  {
    "albumName": "Land of Fans and Music 2",
    "artist": [
      "Kalibration"
    ],
    "name": "Three in the Morning (Kali's 2 in the AM PM Edit)",
    "tags": [
      "Snowman",
      "The Felt"
    ],
    "youtubeId": "i1na9f3xHiA"
  },
  {
    "albumName": "Land of Fans and Music 5",
    "artist": [
      "Andrew \"Rainy\" Obeng"
    ],
    "name": "Spritely Gardener",
    "tags": [
      "Jade",
      "Jadesprite",
      "Earth"
    ],
    "youtubeId": "fIwO9UJUHTw"
  },
  {
    "albumName": "Land of Fans and Music 4",
    "artist": [
      "Cerulean"
    ],
    "name": "Airtime Assault",
    "tags": [
      "John",
      "Dave",
      "LoWaS"
    ],
    "youtubeId": "UJRtFCU3HwU"
  },
  {
    "albumName": "Land of Fans and Music 5",
    "artist": [
      "FlareDragon"
    ],
    "name": "Harley-Claire Retro Time!",
    "tags": [
      "Joey",
      "Jude",
      "Earth"
    ],
    "youtubeId": "dNOjjP4TY7Y"
  },
  {
    "albumName": "Land of Fans and Music 2",
    "artist": [
      "Charles Neudorf"
    ],
    "name": "Soulb0t",
    "tags": [
      "Aradia"
    ],
    "youtubeId": "3d0jm_sPD9A"
  },
  {
    "albumName": "Land of Fans and Music 2",
    "artist": [
      "Erik Scheele"
    ],
    "name": "Crystalguitarthemums",
    "tags": [
      "Jade",
      "Becquerel",
      "Earth"
    ],
    "youtubeId": "fkmxRiQZdb4"
  },
  {
    "albumName": "Land of Fans and Music 3",
    "artist": [
      "Lark Mordancy"
    ],
    "name": "720413",
    "tags": [
      "John",
      "Rose",
      "Dave",
      "Jade",
      "Jane",
      "Roxy",
      "Dirk",
      "Jake",
      "Gate"
    ],
    "youtubeId": "DhVjLTpVKe8"
  },
  {
    "albumName": "Land of Fans and Music 4",
    "artist": [
      "Kusoro"
    ],
    "name": "All the Luck",
    "tags": [
      "Vriska"
    ],
    "youtubeId": "MZXlfsMFPNw"
  },
  {
    "albumName": "Land of Fans and Music",
    "artist": [
      "Artisticpolo"
    ],
    "name": "Jackie Treats",
    "tags": [
      "Dave",
      "Jade",
      "Jack Noir",
      "Battlefield"
    ],
    "youtubeId": "8aMnTdLJ57A"
  },
  {
    "albumName": "Land of Fans and Music 5",
    "artist": [
      "Sylvarn"
    ],
    "name": "Upon Whom Sacrifice Calls",
    "tags": [
      "The Psiioniic"
    ],
    "youtubeId": "NNaOG8lEcdc"
  },
  {
    "albumName": "Land of Fans and Music 2",
    "artist": [
      "Ian White"
    ],
    "name": "Brofessor Layton (Every Puzzle Has A Brolution)",
    "tags": [
      "Dave",
      "Dave's bro"
    ],
    "youtubeId": "gIeRocDm8Aw"
  },
  {
    "albumName": "Land of Fans and Music 4",
    "artist": [
      "Sean Gorter"
    ],
    "name": "Blanc",
    "tags": [
      "Jack Noir",
      "DD",
      "CD",
      "HB"
    ],
    "youtubeId": "_q2ox0WK1g4"
  },
  {
    "albumName": "Land of Fans and Music 5",
    "artist": [
      "FrostyMac"
    ],
    "name": "I Am Bigger Than This Story",
    "tags": [
      "MSPA Reader",
      "Green Sun"
    ],
    "youtubeId": "rfYjvCMK4bM"
  },
  {
    "albumName": "Land of Fans and Music 3",
    "artist": [
      "Brad Griffin"
    ],
    "name": "Forest Fire",
    "tags": [
      "Rose",
      "Green Sun"
    ],
    "youtubeId": "yTDaozw_9Vo"
  },
  {
    "albumName": "Land of Fans and Music 5",
    "artist": [
      "Kanishka"
    ],
    "name": "Special Delivery",
    "tags": [
      "PM",
      "Ruined Earth"
    ],
    "youtubeId": "hjZKrh02m2Y"
  },
  {
    "albumName": "Homestuck for the Holidays",
    "artist": [
      "David Ko"
    ],
    "name": "Squiddly Night",
    "tags": [
      "John",
      "Rose",
      "Dave",
      "Jade",
      "Squiddles",
      "Birds",
      "LoFaF"
    ],
    "youtubeId": "i50tv01mJvI"
  },
  {
    "albumName": "Land of Fans and Music",
    "artist": [
      "Red Pen"
    ],
    "name": "Ruins of Rajavihara",
    "tags": [
      "LoFaF"
    ],
    "youtubeId": "xyKvZV-UlRE"
  },
  {
    "albumName": "Land of Fans and Music 4",
    "artist": [
      "Joe Ouellet"
    ],
    "name": "Intermission",
    "tags": [
      "John",
      "Consorts",
      "LoWaS"
    ],
    "youtubeId": "Qu-VrKoUSKU"
  },
  {
    "albumName": "Land of Fans and Music 4",
    "artist": [
      "Noisemaker"
    ],
    "name": "Buffskull",
    "tags": [
      "Vriska",
      "Lord English"
    ],
    "youtubeId": "UtZZTF8ZMgc"
  },
  {
    "albumName": "Homestuck for the Holidays",
    "artist": [
      "Solatrus",
      "Nick Smalley"
    ],
    "name": "Anthem of Rime",
    "tags": [
      "John",
      "Rose",
      "Dave",
      "Jade",
      "Squiddles",
      "Birds",
      "LoFaF"
    ],
    "youtubeId": "AS9bJhBFXyY"
  },
  {
    "albumName": "Land of Fans and Music 5",
    "artist": [
      "Aradiawithgeese"
    ],
    "name": "Princess Berryboo and Her Orchestra Present \"Friendship is Paramount\" ft. Squishy Tentacle On Piano",
    "tags": [
      "MSPA Reader",
      "Squiddles"
    ],
    "youtubeId": "hDuIDLoKMqs"
  },
  {
    "albumName": "Land of Fans and Music 5",
    "artist": [
      "Cryptanark"
    ],
    "name": "Spacetime Starstriker",
    "tags": [
      "Rose"
    ],
    "youtubeId": "pSJbelWuUDI"
  },
  {
    "albumName": "Land of Fans and Music 5",
    "artist": [
      "Tee-vee"
    ],
    "name": "Snake Case Closin' '18",
    "tags": [
      "Joey",
      "Xefros",
      "Earth"
    ],
    "youtubeId": "C8xeXGVokHM"
  },
  {
    "albumName": "Land of Fans and Music 5",
    "artist": [
      "Aradiawithgeese"
    ],
    "name": "Reunion",
    "tags": [
      "John",
      "John's dad",
      "Skaia"
    ],
    "youtubeId": "_mQkYy6nttM"
  },
  {
    "albumName": "Land of Fans and Music 5",
    "artist": [
      "TirantBacon"
    ],
    "name": "Scratching Consequences",
    "tags": [
      "Doc Scratch",
      "John",
      "Green Sun",
      "LoTaK"
    ],
    "youtubeId": "ke8_hZ5edXw"
  },
  {
    "albumName": "Land of Fans and Music 2",
    "artist": [
      "Brad Griffin"
    ],
    "name": "Rabbunctious!",
    "tags": [
      "Rabbit"
    ],
    "youtubeId": "2_dL-0FzxTE"
  },
  {
    "albumName": "Land of Fans and Music 5",
    "artist": [
      "Interrobang"
    ],
    "name": "Stab - Stab - Stab",
    "tags": [
      "Jack Noir",
      "Lord English"
    ],
    "youtubeId": "gqw3lZ6AUlY"
  },
  {
    "albumName": "Land of Fans and Music 3",
    "artist": [
      "Eston Schweickart"
    ],
    "name": "Bathearst's Theme",
    "tags": [
      "LoFaM"
    ],
    "youtubeId": "1Mrj-Ga6-3E"
  },
  {
    "albumName": "Land of Fans and Music 5",
    "artist": [
      "$C4RL3TT"
    ],
    "name": "Sburban City",
    "tags": [
      "Underlings",
      "Prospit",
      "Derse",
      "LoWaS",
      "LoLaR",
      "LoHaC",
      "LoFaF",
      "Consorts"
    ],
    "youtubeId": "timMEhYQxjY"
  },
  {
    "albumName": "Land of Fans and Music 4",
    "artist": [
      "Kal-la-kal-la"
    ],
    "name": "Fractal Flowers Nui",
    "tags": [
      "LoFaM"
    ],
    "youtubeId": "RN5LBP9kqm0"
  },
  {
    "albumName": "Land of Fans and Music 2",
    "artist": [
      "David Dycus"
    ],
    "name": "Red Miles",
    "tags": [
      "Jack Noir"
    ],
    "youtubeId": "uYkKgHTi-S0"
  },
  {
    "albumName": "Land of Fans and Music 4",
    "artist": [
      "Viko Rif\u00e9o"
    ],
    "name": "Pax Condesca",
    "tags": [
      "The Condesce",
      "Alternia"
    ],
    "youtubeId": "H-uu7kKH8dk"
  },
  {
    "albumName": "Land of Fans and Music 3",
    "artist": [
      "Matt Lee"
    ],
    "name": "Bite the Apple",
    "tags": [
      "John"
    ],
    "youtubeId": "ifulxtLc1_o"
  },
  {
    "albumName": "Land of Fans and Music",
    "artist": [
      "Eligecos"
    ],
    "name": "Final Stand",
    "tags": [
      "John",
      "Rose",
      "Dave",
      "Jade",
      "Jack Noir"
    ],
    "youtubeId": "vSj-kLixKH8"
  },
  {
    "albumName": "Land of Fans and Music 4",
    "artist": [
      "psithurist"
    ],
    "name": "The Sin and the Salvation",
    "tags": [
      "Gamzee",
      "Caliborn",
      "Calliope",
      "Lord English",
      "Alternate Calliope",
      "Lil Cal"
    ],
    "youtubeId": "reMO9m2haoU"
  },
  {
    "albumName": "Land of Fans and Music 4",
    "artist": [
      "Kanishka"
    ],
    "name": "Shatter",
    "tags": [
      "Prospit",
      "Skaia"
    ],
    "youtubeId": "dxjSHHDLekQ"
  },
  {
    "albumName": "Land of Fans and Music 5",
    "artist": [
      "$C4RL3TT"
    ],
    "name": "King Crab",
    "tags": [
      "Karkat"
    ],
    "youtubeId": "j6kns5jv8L8"
  },
  {
    "albumName": "Land of Fans and Music 2",
    "artist": [
      "horizon"
    ],
    "name": "Flare (With Vocals)",
    "tags": [
      "John",
      "Rose",
      "Dave",
      "Jade"
    ],
    "youtubeId": "btHd1zrjkqY"
  },
  {
    "albumName": "Land of Fans and Music 5",
    "artist": [
      "heir-of-puns",
      "Ucklin (vocals, final mastering)"
    ],
    "name": "[S] ==>",
    "tags": [
      "John",
      "Rose",
      "Dave",
      "Jade",
      "Skaia",
      "LoWaS",
      "LoFaF"
    ],
    "youtubeId": "ar8Z_6atoE0"
  },
  {
    "albumName": "Land of Fans and Music 4",
    "artist": [
      "Difarem"
    ],
    "name": "Skaian Dreamers",
    "tags": [
      "John",
      "Rose",
      "Dave",
      "Jade",
      "Jane",
      "Roxy",
      "Dirk",
      "Jake",
      "Skaia"
    ],
    "youtubeId": "bLRgAwqfvHw"
  },
  {
    "albumName": "Land of Fans and Music 5",
    "artist": [
      "Circlejourney"
    ],
    "name": "A Story That Consumes Itself",
    "tags": [
      "Skaia",
      "Horrorterrors"
    ],
    "youtubeId": "tQpAKoeAbEg"
  },
  {
    "albumName": "Land of Fans and Music",
    "artist": [
      "Kalibration"
    ],
    "name": "Ira quod Angelus",
    "tags": [
      "Angels",
      "LoWaA"
    ],
    "youtubeId": "jt_unlzLBpE"
  },
  {
    "albumName": "Land of Fans and Music 5",
    "artist": [
      "Mathias Ramalho"
    ],
    "name": "Pondsquatter (Demaster/Demix)",
    "tags": [
      "Genesis Frog",
      "Lotus"
    ],
    "youtubeId": "8-NN_LZJeko"
  },
  {
    "albumName": "Land of Fans and Music 5",
    "artist": [
      "Kal-la-kal-la"
    ],
    "name": "Predomination",
    "tags": [
      "Lord English",
      "Calliope"
    ],
    "youtubeId": "DT2dH80Ee_Q"
  },
  {
    "albumName": "Land of Fans and Music 4",
    "artist": [
      "Astro Kid"
    ],
    "name": "Crystalmethequins (Broken Bad)",
    "tags": [
      "Vriska",
      "Aradia"
    ],
    "youtubeId": "dIfHLsPtDJE"
  },
  {
    "albumName": "Land of Fans and Music 4",
    "artist": [
      "Alex Votl"
    ],
    "name": "The Sock Ruse",
    "tags": [
      "SBaHJ"
    ],
    "youtubeId": "id6W02iqdwk"
  },
  {
    "albumName": "Land of Fans and Music 5",
    "artist": [
      "Monckat"
    ],
    "name": "Furthest Ringside",
    "tags": [
      "Rose",
      "Dave",
      "Horrorterrors",
      "Derse"
    ],
    "youtubeId": "vFi9v2d94SU"
  },
  {
    "albumName": "The Baby is You",
    "artist": [
      "Toby Fox"
    ],
    "name": "bootes",
    "tags": [
      "John",
      "Karkat",
      "Dave"
    ],
    "youtubeId": "aoDIAbQnV88"
  },
  {
    "albumName": "Land of Fans and Music 2",
    "artist": [
      "Veritas Unae"
    ],
    "name": "\u2022~Disk 2~\u2022",
    "tags": [
      "LoFaM"
    ],
    "youtubeId": "vSVpByKsjwA"
  },
  {
    "albumName": "Land of Fans and Music 2",
    "artist": [
      "Charles Neudorf",
      "Marcy Nabors"
    ],
    "name": "Jackpot",
    "tags": [
      "Roxy",
      "Underlings"
    ],
    "youtubeId": "QQoiR0npnSs"
  },
  {
    "albumName": "Land of Fans and Music 2",
    "artist": [
      "Catboss"
    ],
    "name": "Psychedelic Psychodelic",
    "tags": [
      "Jack Noir",
      "Lord English"
    ],
    "youtubeId": "Sq42ueaXI-4"
  },
  {
    "albumName": "Land of Fans and Music 3",
    "artist": [
      "Thomas Ferkol"
    ],
    "name": "The Metamorphosis of Rose Lalonde",
    "tags": [
      "Rose"
    ],
    "youtubeId": "KG_2qYN5woQ"
  },
  {
    "albumName": "Land of Fans and Music 5",
    "artist": [
      "FrostyMac"
    ],
    "name": "Urban Jungle",
    "tags": [
      "June",
      "Rose",
      "Dave",
      "Jade",
      "John",
      "Earth"
    ],
    "youtubeId": "3Qw2tXmDBhA"
  },
  {
    "albumName": "Land of Fans and Music",
    "artist": [
      "Eston Schweickart"
    ],
    "name": "Growing Up",
    "tags": [
      "John",
      "John's dad"
    ],
    "youtubeId": "L-XX9zvxugU"
  },
  {
    "albumName": "Homestuck for the Holidays",
    "artist": [
      "Nick Smalley"
    ],
    "name": "The More You Know",
    "tags": [
      "John",
      "Rose",
      "Dave",
      "Jade",
      "Squiddles",
      "Birds",
      "LoFaF"
    ],
    "youtubeId": "69l-OnIJS5Y"
  },
  {
    "albumName": "Land of Fans and Music 4",
    "artist": [
      "Circlejourney"
    ],
    "name": "Frost and Clockwork",
    "tags": [
      "Dave",
      "Jade",
      "LoFaF",
      "LoHaC"
    ],
    "youtubeId": "3Bf_au1-r9A"
  },
  {
    "albumName": "Land of Fans and Music 4",
    "artist": [
      "Shandy"
    ],
    "name": "Waltz for Calliope",
    "tags": [
      "Alternate Calliope",
      "Dream Bubbles"
    ],
    "youtubeId": "o3hdT6bOXvk"
  },
  {
    "albumName": "Land of Fans and Music 5",
    "artist": [
      "WarxTron"
    ],
    "name": "Skaian Spirograph",
    "tags": [
      "John",
      "Rose",
      "Skaia"
    ],
    "youtubeId": "wl2AvGz-c2I"
  },
  {
    "albumName": "Land of Fans and Music 5",
    "artist": [
      "Andrew \"Rainy\" Obeng"
    ],
    "name": "All Alone (Redux)",
    "tags": [
      "Damara"
    ],
    "youtubeId": "xGFE9vOUsaM"
  },
  {
    "albumName": "Land of Fans and Music 2",
    "artist": [
      "Thomas Ferkol"
    ],
    "name": "The Seer and the Tribesmen",
    "tags": [
      "Rose",
      "Consorts"
    ],
    "youtubeId": "pjbFi9f_F7Y"
  },
  {
    "albumName": "Land of Fans and Music",
    "artist": [
      "Mal McGannon"
    ],
    "name": "Clockbreaker",
    "tags": [
      "Jack Noir"
    ],
    "youtubeId": "ZKhzNLEXlAI"
  },
  {
    "albumName": "Land of Fans and Music 2",
    "artist": [
      "Jamie Paige Stanley"
    ],
    "name": "Chronology",
    "tags": [
      "Feferi",
      "Doc Scratch",
      "Lord English",
      "Horrorterrors",
      "Battlefield",
      "Alternia"
    ],
    "youtubeId": "AP6PIJRi4_w"
  },
  {
    "albumName": "Land of Fans and Music 3",
    "artist": [
      "PhemieC"
    ],
    "name": "Fool [Explicit]",
    "tags": [
      "Gamzee"
    ],
    "youtubeId": "23Ekyew6ljc"
  },
  {
    "albumName": "Land of Fans and Music",
    "artist": [
      "Willow Ascenzo"
    ],
    "name": "Crystalanachrony",
    "tags": [
      "Dave",
      "Aradia",
      "Green Sun",
      "Dream Bubbles"
    ],
    "youtubeId": "UAONvQ4VQaA"
  },
  {
    "albumName": "Land of Fans and Music",
    "artist": [
      "Kristian Anderson"
    ],
    "name": "The Land of Wind and Shade",
    "tags": [
      "John",
      "LoWaS"
    ],
    "youtubeId": "nFAMTC_I-Ns"
  },
  {
    "albumName": "Land of Fans and Music 5",
    "artist": [
      "heir-of-puns"
    ],
    "name": "At the Threshold",
    "tags": [
      "Alternate Calliope",
      "Jade",
      "Green Sun"
    ],
    "youtubeId": "k1mfCm6WCVc"
  },
  {
    "albumName": "Land of Fans and Music",
    "artist": [
      "Chase"
    ],
    "name": "First Guardian, Last Stand",
    "tags": [
      "Doc Scratch",
      "LoWaS"
    ],
    "youtubeId": "kVbmMiXPbC8"
  },
  {
    "albumName": "Land of Fans and Music 2",
    "artist": [
      "Thomas Ibarra"
    ],
    "name": "Rectify",
    "tags": [
      "Jack Noir",
      "Green Sun"
    ],
    "youtubeId": "GTtM6ZIyJ7Q"
  },
  {
    "albumName": "The Baby is You",
    "artist": [
      "Toby Fox"
    ],
    "name": "rose pragnant",
    "tags": [
      "John",
      "Karkat",
      "Dave"
    ],
    "youtubeId": "l6BDUDe4eQo"
  },
  {
    "albumName": "Homestuck for the Holidays",
    "artist": [
      "Thomas Ferkol"
    ],
    "name": "Land of Light and Cheer",
    "tags": [
      "John",
      "Rose",
      "Dave",
      "Jade",
      "Squiddles",
      "Birds",
      "LoFaF"
    ],
    "youtubeId": "fwqFAtNqWhA"
  },
  {
    "albumName": "Land of Fans and Music 4",
    "artist": [
      "Viko Rif\u00e9o"
    ],
    "name": "Prospitian Monody",
    "tags": [
      "Carapacians",
      "Battlefield"
    ],
    "youtubeId": "DvWBb8vi4-I"
  },
  {
    "albumName": "Land of Fans and Music 5",
    "artist": [
      "Tee-vee"
    ],
    "name": "InterNep",
    "tags": [
      "Nepeta",
      "Equius"
    ],
    "youtubeId": "LVBJT3ljeoU"
  },
  {
    "albumName": "Homestuck for the Holidays",
    "artist": [
      "Solatrus"
    ],
    "name": "Candles and Merry Gentlemen",
    "tags": [
      "John",
      "Rose",
      "Dave",
      "Jade",
      "Squiddles",
      "Birds",
      "LoFaF"
    ],
    "youtubeId": "TG0NSG8f1RY"
  },
  {
    "albumName": "Land of Fans and Music 4",
    "artist": [
      "olegoleg123"
    ],
    "name": "Aura of Colour",
    "tags": [
      "Rose",
      "Consorts",
      "LoLaR"
    ],
    "youtubeId": "rxiNzk64tSw"
  },
  {
    "albumName": "Land of Fans and Music 5",
    "artist": [
      "Pascal van den Bos"
    ],
    "name": "Collapse Reprise",
    "tags": [
      "LoFaM"
    ],
    "youtubeId": "D93xs_BP8F0"
  },
  {
    "albumName": "Land of Fans and Music",
    "artist": [
      "Max Wright"
    ],
    "name": "Prince of Seas",
    "tags": [
      "Eridan"
    ],
    "youtubeId": "X4uMbtMmoxI"
  },
  {
    "albumName": "Land of Fans and Music",
    "artist": [
      "Steve Everson"
    ],
    "name": "Thought and Flow",
    "tags": [
      "LoTaF"
    ],
    "youtubeId": "Bj-3pyAbc3U"
  },
  {
    "albumName": "Land of Fans and Music 3",
    "artist": [
      "Thomas Ferkol"
    ],
    "name": "Fracta Sub Luna",
    "tags": [
      "Snowman",
      "Alternia"
    ],
    "youtubeId": "hkLz5tuPvJk"
  },
  {
    "albumName": "Land of Fans and Music 5",
    "artist": [
      "L4Vo5"
    ],
    "name": "Minor Manor",
    "tags": [
      "Joey",
      "Earth"
    ],
    "youtubeId": "-SSOQZAt9wE"
  },
  {
    "albumName": "Land of Fans and Music 4",
    "artist": [
      "LydianChord"
    ],
    "name": "Upward Meowvement (Davepetasprite^2 Owns)",
    "tags": [
      "Davepeta",
      "Green Sun"
    ],
    "youtubeId": "wkbjr98bx9I"
  },
  {
    "albumName": "Land of Fans and Music 4",
    "artist": [
      "Kouta"
    ],
    "name": "Denizen",
    "tags": [
      "Denizens",
      "John",
      "LoWaS"
    ],
    "youtubeId": "Avz8eRHnoDA"
  },
  {
    "albumName": "Land of Fans and Music 4",
    "artist": [
      "Shandy"
    ],
    "name": "Reunited",
    "tags": [
      "Rose",
      "Roxy",
      "Skaia"
    ],
    "youtubeId": "kBx8zlGYYvk"
  },
  {
    "albumName": "Land of Fans and Music 3",
    "artist": [
      "Willow Ascenzo"
    ],
    "name": "Dance of Viridian and Violet",
    "tags": [
      "Rose",
      "Kanaya",
      "Meteor"
    ],
    "youtubeId": "eHLP_behr8M"
  },
  {
    "albumName": "The Baby is You",
    "artist": [
      "Toby Fox"
    ],
    "name": "uh oh",
    "tags": [
      "John",
      "Karkat",
      "Dave"
    ],
    "youtubeId": "QJWPUe5cdKc"
  },
  {
    "albumName": "Land of Fans and Music 2",
    "artist": [
      "David Ellis"
    ],
    "name": "Rainbow Rebirth",
    "tags": [
      "Kanaya"
    ],
    "youtubeId": "ziiK_Q23WbA"
  },
  {
    "albumName": "Land of Fans and Music 3",
    "artist": [
      "Astro Kid"
    ],
    "name": "Invertebrate",
    "tags": [
      "Squiddles"
    ],
    "youtubeId": "7v-ELzTgSHo"
  },
  {
    "albumName": "Land of Fans and Music 5",
    "artist": [
      "Pascal van den Bos"
    ],
    "name": "Alpha Admission",
    "tags": [
      "Dirk",
      "Kate's dad (Desynced)",
      "Imperial Drones",
      "Earth"
    ],
    "youtubeId": "2gM77uBarZo"
  },
  {
    "albumName": "Land of Fans and Music 5",
    "artist": [
      "Kanishka"
    ],
    "name": "Anamnesis",
    "tags": [
      "Vriska",
      "Jack Noir",
      "Green Sun"
    ],
    "youtubeId": "Qm_red7oXxg"
  },
  {
    "albumName": "Land of Fans and Music 4",
    "artist": [
      "cookiefonster"
    ],
    "name": "Jungle",
    "tags": [
      "Jane",
      "Roxy",
      "Dirk",
      "Frog Temple",
      "Earth"
    ],
    "youtubeId": "QP60dR-SEhY"
  },
  {
    "albumName": "Land of Fans and Music 5",
    "artist": [
      "Sylvarn"
    ],
    "name": "Cut the Chain",
    "tags": [
      "Jack Noir",
      "Prospit",
      "Skaia"
    ],
    "youtubeId": "GKV750wSox0"
  },
  {
    "albumName": "Land of Fans and Music 5",
    "artist": [
      "TirantBacon",
      "WarxTron"
    ],
    "name": "Strain",
    "tags": [
      "Dirk",
      "LoTaK",
      "SBaHJ"
    ],
    "youtubeId": "juCVUBIxzHI"
  },
  {
    "albumName": "Land of Fans and Music 4",
    "artist": [
      "Noisemaker"
    ],
    "name": "Resend",
    "tags": [
      "Caliborn",
      "Gamzee",
      "Rabbit"
    ],
    "youtubeId": "ECzDFJ_ACj0"
  },
  {
    "albumName": "Land of Fans and Music 4",
    "artist": [
      "SplitSuns"
    ],
    "name": "Collision Course (Davepeta's Movement)",
    "tags": [
      "Davepeta",
      "Lil Cal",
      "Earth"
    ],
    "youtubeId": "sIJW6g2kygU"
  },
  {
    "albumName": "Land of Fans and Music 4",
    "artist": [
      "Seth Massey"
    ],
    "name": "Beyond the Death of the King",
    "tags": [
      "Calmasis"
    ],
    "youtubeId": "NjZUfVUvXOg"
  },
  {
    "albumName": "Land of Fans and Music",
    "artist": [
      "Alex Rosetti"
    ],
    "name": "Sburban Elevator",
    "tags": [],
    "youtubeId": "SqJiI9ouAhU"
  },
  {
    "albumName": "Land of Fans and Music 3",
    "artist": [
      "Veritas Unae"
    ],
    "name": "\u02da Disk 2 \u02da",
    "tags": [
      "John",
      "Rose",
      "Dave",
      "Jade",
      "Jane",
      "Roxy",
      "Dirk",
      "Jake",
      "Earth"
    ],
    "youtubeId": "_sfcbpk3S9I"
  },
  {
    "albumName": "Land of Fans and Music 5",
    "artist": [
      "$C4RL3TT"
    ],
    "name": "Black ($C4RL3TT Remix)",
    "tags": [
      "Derse"
    ],
    "youtubeId": "dkSA2M-YnoI"
  },
  {
    "albumName": "Land of Fans and Music",
    "artist": [
      "Toby Fox"
    ],
    "name": "Starkind",
    "tags": [
      "Rose",
      "Green Sun"
    ],
    "youtubeId": "kAQAkPr6Jr8"
  },
  {
    "albumName": "Land of Fans and Music 4",
    "artist": [
      "WHATISLOSTINTHEMINES"
    ],
    "name": "Noble Ascendance",
    "tags": [
      "Jane",
      "Roxy",
      "Dirk",
      "Jake",
      "Prospit",
      "Derse"
    ],
    "youtubeId": "L7PcXM_NuqI"
  },
  {
    "albumName": "Land of Fans and Music 4",
    "artist": [
      "Catboss"
    ],
    "name": "Daywalker ~Rise~",
    "tags": [
      "Kanaya"
    ],
    "youtubeId": "U6VQKqsiW4s"
  },
  {
    "albumName": "Land of Fans and Music 3",
    "artist": [
      "David Dycus"
    ],
    "name": "Dogfight (Dirtiest's Dubstep Remix)",
    "tags": [
      "PM",
      "Jack Noir"
    ],
    "youtubeId": "4umrMK8f89M"
  },
  {
    "albumName": "Land of Fans and Music 4",
    "artist": [
      "ndividedbyzero"
    ],
    "name": "Quest Crypt",
    "tags": [
      "Jane",
      "Prospit"
    ],
    "youtubeId": "xl97AV5vxSc"
  },
  {
    "albumName": "Land of Fans and Music 5",
    "artist": [
      "Levc"
    ],
    "name": "The Power of RETCON",
    "tags": [
      "John"
    ],
    "youtubeId": "aSuUmmolnfc"
  },
  {
    "albumName": "Land of Fans and Music 4",
    "artist": [
      "theh0nestman"
    ],
    "name": "I'm a Member of the Midnight Crew (theh0nestman Version)",
    "tags": [
      "CD"
    ],
    "youtubeId": "g_kgqsN-Bmc"
  },
  {
    "albumName": "Land of Fans and Music 2",
    "artist": [
      "Trent West"
    ],
    "name": "Jade Aubade",
    "tags": [
      "Jade",
      "Green Sun"
    ],
    "youtubeId": "NHjYM56U2os"
  },
  {
    "albumName": "Land of Fans and Music 4",
    "artist": [
      "SplitSuns"
    ],
    "name": "Thyme on My Fries",
    "tags": [
      "Dirk",
      "Jake",
      "DD",
      "Carapacians",
      "Earth",
      "Derse"
    ],
    "youtubeId": "IYZCJAMCWLg"
  },
  {
    "albumName": "Land of Fans and Music 3",
    "artist": [
      "Robyo"
    ],
    "name": "I'm Not a Hero",
    "tags": [
      "Dave"
    ],
    "youtubeId": "2PBxH568OIE"
  },
  {
    "albumName": "Land of Fans and Music 4",
    "artist": [
      "ndividedbyzero"
    ],
    "name": "HOMOSUCK. DIRECTOR'S CUT, OF THE YEAR EDITION.",
    "tags": [
      "John",
      "Rose",
      "Dave",
      "Jade",
      "Caliborn",
      "Homosuck"
    ],
    "youtubeId": "OKIXqg_ZzcM"
  },
  {
    "albumName": "Land of Fans and Music 2",
    "artist": [
      "Willow Ascenzo"
    ],
    "name": "Elegy for a Thief (Dreamscape Remix)",
    "tags": [
      "Vriska"
    ],
    "youtubeId": "dod8eS9-JMc"
  },
  {
    "albumName": "Land of Fans and Music 5",
    "artist": [
      "Gryotharian"
    ],
    "name": "Post-Ironic Radical Child Abuse Anthem",
    "tags": [
      "Dave's bro"
    ],
    "youtubeId": "AB7Y0CkGZs8"
  },
  {
    "albumName": "Land of Fans and Music",
    "artist": [
      "Brenna Curran"
    ],
    "name": "Vigilante ~ Cornered",
    "tags": [
      "Terezi",
      "Vriska"
    ],
    "youtubeId": "5Yb4SeK7-Mw"
  },
  {
    "albumName": "Land of Fans and Music 2",
    "artist": [
      "Willow Ascenzo"
    ],
    "name": "Ave Dei Novi",
    "tags": [
      "Jade",
      "LoWaS",
      "LoLaR",
      "LoHaC",
      "LoFaF"
    ],
    "youtubeId": "y3jkIF6yMkc"
  },
  {
    "albumName": "Land of Fans and Music 3",
    "artist": [
      "Brad Griffin"
    ],
    "name": "Aqueous Armagourddon",
    "tags": [
      "Roxy"
    ],
    "youtubeId": "Zx4tuVvWJ64"
  },
  {
    "albumName": "Homestuck for the Holidays",
    "artist": [
      "Nick Smalley"
    ],
    "name": "Time for a Story",
    "tags": [
      "John",
      "Rose",
      "Dave",
      "Jade",
      "Squiddles",
      "Birds",
      "LoFaF"
    ],
    "youtubeId": "u7PHEnU9YAo"
  },
  {
    "albumName": "Land of Fans and Music",
    "artist": [
      "Brad Griffin"
    ],
    "name": "Sburban Piano Doctor",
    "tags": [
      "LoWaS"
    ],
    "youtubeId": "AUkt5bpcJdQ"
  },
  {
    "albumName": "Land of Fans and Music 4",
    "artist": [
      "Noisemaker"
    ],
    "name": "Premortem",
    "tags": [
      "Gamzee",
      "Kurloz",
      "LoTaM"
    ],
    "youtubeId": "5cJNegoYjuU"
  },
  {
    "albumName": "Land of Fans and Music 4",
    "artist": [
      "Emelia K."
    ],
    "name": "Conflict!",
    "tags": [
      "Horses"
    ],
    "youtubeId": "Duj1TbqQ4Bs"
  },
  {
    "albumName": "Land of Fans and Music 3",
    "artist": [
      "David Dycus"
    ],
    "name": "You're the Hero (It's You)",
    "tags": [
      "John"
    ],
    "youtubeId": "4joVcHKDX3w"
  },
  {
    "albumName": "Land of Fans and Music 4",
    "artist": [
      "Nicholas Nakano"
    ],
    "name": "[GRIEF]",
    "tags": [
      "LoFaM"
    ],
    "youtubeId": "GKwajHc6l4I"
  },
  {
    "albumName": "Land of Fans and Music 4",
    "artist": [
      "Cement City"
    ],
    "name": "Moonsetter (Strider Remix)",
    "tags": [
      "Dave",
      "Dirk",
      "HB"
    ],
    "youtubeId": "sbsaBi8OH2c"
  },
  {
    "albumName": "Homestuck for the Holidays",
    "artist": [
      "Nick Smalley"
    ],
    "name": "Oh, No! It's the Midnight Crew!",
    "tags": [
      "John",
      "Rose",
      "Dave",
      "Jade",
      "Squiddles",
      "Birds",
      "LoFaF"
    ],
    "youtubeId": "VlZHoHoiOa4"
  },
  {
    "albumName": "Land of Fans and Music 4",
    "artist": [
      "Aris Martinian"
    ],
    "name": "Revisit/Rewind",
    "tags": [
      "John",
      "Aradia",
      "Skaia"
    ],
    "youtubeId": "2Tm2S88RlCg"
  },
  {
    "albumName": "Homestuck for the Holidays",
    "artist": [
      "Nick Smalley"
    ],
    "name": "Choo Choo",
    "tags": [
      "John",
      "Rose",
      "Dave",
      "Jade",
      "Squiddles",
      "Birds",
      "LoFaF"
    ],
    "youtubeId": "_dDEvkBEJoA"
  },
  {
    "albumName": "Land of Fans and Music 3",
    "artist": [
      "Eston Schweickart"
    ],
    "name": "The Delicate Art of Temporal Manipulation",
    "tags": [
      "Calliope",
      "Lord English"
    ],
    "youtubeId": "52uozC1h_YU"
  },
  {
    "albumName": "Land of Fans and Music 5",
    "artist": [
      "subversiveasset"
    ],
    "name": "Keep Your Head Down, Rustblood!",
    "tags": [
      "Xefros",
      "Imperial Drones",
      "Alternia"
    ],
    "youtubeId": "f7n2Khq4LAA"
  },
  {
    "albumName": "Land of Fans and Music",
    "artist": [
      "Evan Copeland"
    ],
    "name": "Quartz Lullaby",
    "tags": [
      "Aradia",
      "LoQaM"
    ],
    "youtubeId": "MjbvKSLqAiw"
  },
  {
    "albumName": "Land of Fans and Music 2",
    "artist": [
      "Nitrosparxx"
    ],
    "name": "Who Mourns for Jack Noir?",
    "tags": [
      "Jade",
      "Jack Noir"
    ],
    "youtubeId": "usR1fpqUXR0"
  },
  {
    "albumName": "Land of Fans and Music 4",
    "artist": [
      "The Great Anansi"
    ],
    "name": "En Masse",
    "tags": [
      "Doc Scratch"
    ],
    "youtubeId": "NSx06mX8aEI"
  },
  {
    "albumName": "Land of Fans and Music 5",
    "artist": [
      "Circlejourney"
    ],
    "name": "Sailing (In The Sea Of Stars)",
    "tags": [
      "The Theseus"
    ],
    "youtubeId": "cYkgtztQ1VI"
  },
  {
    "albumName": "Land of Fans and Music 5",
    "artist": [
      "Gryotharian"
    ],
    "name": "A Dream Of Broken Clocks",
    "tags": [
      "Doc Scratch",
      "Felt Manor"
    ],
    "youtubeId": "iXToxT3NsCs"
  },
  {
    "albumName": "Land of Fans and Music 5",
    "artist": [
      "Artisticpolo"
    ],
    "name": "Rex Duodecim Angelus (Remix)",
    "tags": [
      "BK"
    ],
    "youtubeId": "TpaFPn8pEzc"
  },
  {
    "albumName": "Land of Fans and Music 4",
    "artist": [
      "David Dycus"
    ],
    "name": "Ghosts",
    "tags": [
      "Aradia",
      "Alternia"
    ],
    "youtubeId": "RZ6YpMNED_s"
  },
  {
    "albumName": "Land of Fans and Music",
    "artist": [
      "Ian White"
    ],
    "name": "Land of Wrath and Angels",
    "tags": [
      "Eridan",
      "Angels",
      "LoWaA"
    ],
    "youtubeId": "qiPWW6m3QUQ"
  },
  {
    "albumName": "Land of Fans and Music 2",
    "artist": [
      "Astro Kid"
    ],
    "name": "Skaian Starstorm",
    "tags": [
      "Jade",
      "Skaia"
    ],
    "youtubeId": "oTOwhix_Zqw"
  },
  {
    "albumName": "Land of Fans and Music 3",
    "artist": [
      "Ian White"
    ],
    "name": "March of the Aluminum Soldiers",
    "tags": [
      "AR",
      "Ruined Earth"
    ],
    "youtubeId": "Ss3LYhN-SjI"
  },
  {
    "albumName": "The Baby is You",
    "artist": [
      "Toby Fox"
    ],
    "name": "the rose rap",
    "tags": [
      "John",
      "Karkat",
      "Dave"
    ],
    "youtubeId": "o3rJTGHgYTk"
  },
  {
    "albumName": "Land of Fans and Music 2",
    "artist": [
      "Max Wright"
    ],
    "name": "Embittered Shore",
    "tags": [
      "Mobius Trip",
      "Hadron Kaleido"
    ],
    "youtubeId": "6NIkdexVdT4"
  },
  {
    "albumName": "Land of Fans and Music 2",
    "artist": [
      "Svix"
    ],
    "name": "Showtime (Svix Mix)",
    "tags": [
      "John",
      "John's dad",
      "Earth"
    ],
    "youtubeId": "dodzDnjmY9Q"
  },
  {
    "albumName": "Land of Fans and Music 4",
    "artist": [
      "Elaine Wang"
    ],
    "name": "Expanses of Interstellar Space",
    "tags": [
      "PM",
      "Jack Noir"
    ],
    "youtubeId": "sMcenyPOHeE"
  },
  {
    "albumName": "Land of Fans and Music 4",
    "artist": [
      "Lambda Bledsoe",
      "ndividedbyzero"
    ],
    "name": "~~DISC 3~~",
    "tags": [
      "LoFaM"
    ],
    "youtubeId": "sSXmhZZMDNQ"
  },
  {
    "albumName": "Land of Fans and Music",
    "artist": [
      "Ian White"
    ],
    "name": "Growin' Up Strider",
    "tags": [
      "Dave",
      "Dave's bro"
    ],
    "youtubeId": "xnPkExwPsiE"
  },
  {
    "albumName": "Homestuck for the Holidays",
    "artist": [
      "Toby Fox"
    ],
    "name": "Carefree Perigee",
    "tags": [
      "John",
      "Rose",
      "Dave",
      "Jade",
      "Squiddles",
      "Birds",
      "LoFaF"
    ],
    "youtubeId": "sEFEEfJ0Rzk"
  },
  {
    "albumName": "Land of Fans and Music 2",
    "artist": [
      "Nick Smalley",
      "Andrew Huo"
    ],
    "name": "Dirgeish",
    "tags": [
      "Vriska",
      "Jack Noir"
    ],
    "youtubeId": "t9KgQojuzQ4"
  },
  {
    "albumName": "Land of Fans and Music 2",
    "artist": [
      "David Ellis"
    ],
    "name": "A Study In Skaia",
    "tags": [
      "John",
      "Battlefield"
    ],
    "youtubeId": "csgfrTQGOaw"
  },
  {
    "albumName": "Land of Fans and Music 5",
    "artist": [
      "Kal-la-kal-la"
    ],
    "name": "The Dreamer and the Dream",
    "tags": [
      "Jade",
      "Prospit",
      "Skaia"
    ],
    "youtubeId": "MhCPzgmq9f4"
  },
  {
    "albumName": "Land of Fans and Music 4",
    "artist": [
      "Seijen"
    ],
    "name": "Exsanguinated",
    "tags": [
      "Kanaya"
    ],
    "youtubeId": "_X8T8PjS8N4"
  },
  {
    "albumName": "Land of Fans and Music 4",
    "artist": [
      "rilez",
      "Natrox"
    ],
    "name": "Wisest Wizord",
    "tags": [
      "Roxy",
      "Cats"
    ],
    "youtubeId": "pdKy7Ex0WAk"
  },
  {
    "albumName": "Land of Fans and Music 4",
    "artist": [
      "Marcy Nabors"
    ],
    "name": "Atomyk Clockwyrk",
    "tags": [
      "Dave",
      "LoHaC"
    ],
    "youtubeId": "jeStLlyaJ5s"
  },
  {
    "albumName": "Land of Fans and Music 5",
    "artist": [
      "heir-of-puns"
    ],
    "name": "Somnambulist",
    "tags": [
      "Roxy",
      "Dream Bubbles"
    ],
    "youtubeId": "t0l3dugTt_0"
  },
  {
    "albumName": "Land of Fans and Music 2",
    "artist": [
      "Eston Schweickart"
    ],
    "name": "Unforgiving Terrain",
    "tags": [
      "WV",
      "AR",
      "PM",
      "Serenity",
      "Jack Noir",
      "Frog Temple"
    ],
    "youtubeId": "I0THAb_V18A"
  },
  {
    "albumName": "Homestuck for the Holidays",
    "artist": [
      "Alex Rosetti"
    ],
    "name": "The Squiddles Save Christmas",
    "tags": [
      "John",
      "Rose",
      "Dave",
      "Jade",
      "Squiddles",
      "Birds",
      "LoFaF"
    ],
    "youtubeId": "YyGhvEWMyd4"
  },
  {
    "albumName": "Land of Fans and Music",
    "artist": [
      "Catboss"
    ],
    "name": "MeGaDanceVaNia",
    "tags": [
      "Vriska"
    ],
    "youtubeId": "UIcXeunzAaM"
  },
  {
    "albumName": "Land of Fans and Music 2",
    "artist": [
      "Joe Ouellet"
    ],
    "name": "Wind In My Heir",
    "tags": [
      "John"
    ],
    "youtubeId": "hwYXNdhsZIA"
  },
  {
    "albumName": "Land of Fans and Music",
    "artist": [
      "Fuzz"
    ],
    "name": "Farewell",
    "tags": [
      "John",
      "Karkat",
      "Terezi"
    ],
    "youtubeId": "SHzmF_sSCi0"
  },
  {
    "albumName": "Land of Fans and Music 5",
    "artist": [
      "Gryotharian"
    ],
    "name": "Shattered Spacetime",
    "tags": [
      "Lord English"
    ],
    "youtubeId": "XdH7O_xjCWU"
  },
  {
    "albumName": "Land of Fans and Music 3",
    "artist": [
      "Colin Stanfill"
    ],
    "name": "Planet Cracker",
    "tags": [
      "Jane",
      "Roxy",
      "Dirk",
      "Jake",
      "Prospit",
      "Derse"
    ],
    "youtubeId": "2RXMevfxCPg"
  },
  {
    "albumName": "Land of Fans and Music 2",
    "artist": [
      "Dante Basco"
    ],
    "name": "Gangsta",
    "tags": [
      "Rose",
      "Jade",
      "Rufioh"
    ],
    "youtubeId": "YR3O-23PD3o"
  },
  {
    "albumName": "Land of Fans and Music 3",
    "artist": [
      "Ashley Jones"
    ],
    "name": "Maidswap",
    "tags": [
      "The Handmaid",
      "The Condesce",
      "Alternia"
    ],
    "youtubeId": "DzUKjwDTtrs"
  },
  {
    "albumName": "The Baby is You",
    "artist": [
      "Toby Fox"
    ],
    "name": "a baby is born",
    "tags": [
      "John",
      "Karkat",
      "Dave"
    ],
    "youtubeId": "jCfE_6pTN8A"
  },
  {
    "albumName": "Land of Fans and Music 3",
    "artist": [
      "Willow Ascenzo"
    ],
    "name": "Pirates of Alternia: Dead Troll's Chest",
    "tags": [
      "Mindfang"
    ],
    "youtubeId": "z2TOLOWdlGU"
  },
  {
    "albumName": "Land of Fans and Music 4",
    "artist": [
      "Kris Flacke"
    ],
    "name": "Skaian Rebirth",
    "tags": [
      "John",
      "Rose",
      "Dave",
      "Jade",
      "Horrorterrors",
      "LoWaS",
      "LoLaR",
      "LoHaC",
      "LoFaF"
    ],
    "youtubeId": "7Uf-CCixByk"
  },
  {
    "albumName": "Land of Fans and Music 4",
    "artist": [
      "SplitSuns"
    ],
    "name": "Climbing to Infinity",
    "tags": [
      "LoLaR"
    ],
    "youtubeId": "RZmbjMySkJY"
  },
  {
    "albumName": "Land of Fans and Music 4",
    "artist": [
      "Willow Ascenzo"
    ],
    "name": "Crystalline Repose",
    "tags": [
      "Aradia"
    ],
    "youtubeId": "-3Cld2xx2gI"
  },
  {
    "albumName": "Land of Fans and Music 4",
    "artist": [
      "Jamie Paige Stanley"
    ],
    "name": "Crystalendofgames",
    "tags": [
      "WV",
      "PM",
      "Skaia",
      "LoFaF"
    ],
    "youtubeId": "Hm1ev7TKmSA"
  },
  {
    "albumName": "Land of Fans and Music",
    "artist": [
      "tynic"
    ],
    "name": "SadoMasoPedoRoboNecroBestiality",
    "tags": [
      "Aradia",
      "Equius"
    ],
    "youtubeId": "Vx-DcwcETag"
  },
  {
    "albumName": "Land of Fans and Music 4",
    "artist": [
      "Hadron"
    ],
    "name": "hors",
    "tags": [
      "John",
      "Dave",
      "Arquiusprite",
      "Maplehoof",
      "Horses"
    ],
    "youtubeId": "1Vsulg1rHyg"
  },
  {
    "albumName": "Land of Fans and Music",
    "artist": [
      "Elaine Wang"
    ],
    "name": "Meltwater",
    "tags": [
      "Jade",
      "LoFaF"
    ],
    "youtubeId": "zJ7zBZ9ARGI"
  },
  {
    "albumName": "Land of Fans and Music 3",
    "artist": [
      "Willow Ascenzo"
    ],
    "name": "Sign of the Scorpion",
    "tags": [
      "Vriska"
    ],
    "youtubeId": "8dT6s4MJf9Y"
  },
  {
    "albumName": "Land of Fans and Music 3",
    "artist": [
      "horizon"
    ],
    "name": "confinis",
    "tags": [
      "The Disciple",
      "The Signless"
    ],
    "youtubeId": "-3k6uySX2X4"
  },
  {
    "albumName": "Land of Fans and Music 3",
    "artist": [
      "ndividedbyzero"
    ],
    "name": "Waveform",
    "tags": [
      "Squarewave",
      "Sawtooth"
    ],
    "youtubeId": "ygw3O4dmRYI"
  },
  {
    "albumName": "Land of Fans and Music 3",
    "artist": [
      "David Ellis"
    ],
    "name": "An Uncertain Venture",
    "tags": [
      "Jake",
      "Dirk",
      "Sawtooth",
      "Squarewave",
      "LoMaX"
    ],
    "youtubeId": "oWyVIEsRvas"
  },
  {
    "albumName": "Land of Fans and Music 5",
    "artist": [
      "Cement City"
    ],
    "name": "Pumpkin Party in Sea Hitler's Water Apocalypse (Cement City Remix)",
    "tags": [
      "The Condesce"
    ],
    "youtubeId": "OxqRv_qtEbI"
  },
  {
    "albumName": "Land of Fans and Music 4",
    "artist": [
      "Thomas Ferkol"
    ],
    "name": "Highland Thunder",
    "tags": [
      "LoMaX"
    ],
    "youtubeId": "2sXRcKDYKZM"
  },
  {
    "albumName": "Land of Fans and Music",
    "artist": [
      "Trent West"
    ],
    "name": "Shame and Doubt",
    "tags": [
      "Dave",
      "Terezi"
    ],
    "youtubeId": "ar5r6MQJJMc"
  },
  {
    "albumName": "Land of Fans and Music 4",
    "artist": [
      "SerialSymphony"
    ],
    "name": "Prospit Towers",
    "tags": [
      "Jade",
      "Prospit"
    ],
    "youtubeId": "nw0YXJ0fKkU"
  },
  {
    "albumName": "Land of Fans and Music 4",
    "artist": [
      "electric lantern"
    ],
    "name": "Daydreamer",
    "tags": [
      "Joey"
    ],
    "youtubeId": "2wqnd4OaYvk"
  },
  {
    "albumName": "Land of Fans and Music 2",
    "artist": [
      "Lark Mordancy"
    ],
    "name": "Sburban Genesis",
    "tags": [
      "Genesis Frog"
    ],
    "youtubeId": "DPX6j-JM7Bw"
  },
  {
    "albumName": "Land of Fans and Music 2",
    "artist": [
      "Trent West"
    ],
    "name": "Corkscrew Sundown",
    "tags": [
      "Doc Scratch",
      "Green Sun"
    ],
    "youtubeId": "sJVzHZJBAhA"
  },
  {
    "albumName": "Land of Fans and Music 4",
    "artist": [
      "nights"
    ],
    "name": "Lightfall",
    "tags": [
      "Rose",
      "LoLaR"
    ],
    "youtubeId": "Wmop6vIepPs"
  },
  {
    "albumName": "Land of Fans and Music 3",
    "artist": [
      "Shandy"
    ],
    "name": "timaeusAmplified",
    "tags": [
      "Dirk"
    ],
    "youtubeId": "nIPk7669z88"
  },
  {
    "albumName": "Land of Fans and Music 2",
    "artist": [
      "Thomas Ibarra"
    ],
    "name": "Irradiated",
    "tags": [
      "John",
      "Jack Noir"
    ],
    "youtubeId": "M_lXyhRBOhk"
  },
  {
    "albumName": "Land of Fans and Music 5",
    "artist": [
      "Kusoro"
    ],
    "name": "Sunriser",
    "tags": [
      "Earth"
    ],
    "youtubeId": "1yl-7sOPoiU"
  },
  {
    "albumName": "Land of Fans and Music",
    "artist": [
      "Marcy Nabors"
    ],
    "name": "Nakkadile",
    "tags": [
      "Consorts"
    ],
    "youtubeId": "MQhf1bWXnlU"
  },
  {
    "albumName": "Land of Fans and Music",
    "artist": [
      "Ralen Gainsborough"
    ],
    "name": "Sburban Rush",
    "tags": [
      "John"
    ],
    "youtubeId": "LiewiuFbFJo"
  },
  {
    "albumName": "Land of Fans and Music 3",
    "artist": [
      "Cullen Lawson",
      "Joe Ouellet"
    ],
    "name": "Party of Three",
    "tags": [
      "DD"
    ],
    "youtubeId": "FuR-7wg4l-s"
  },
  {
    "albumName": "Land of Fans and Music 3",
    "artist": [
      "Kevin Grant"
    ],
    "name": "Sketches in Black",
    "tags": [
      "Jack Noir",
      "Derse"
    ],
    "youtubeId": "V83XZ-cyxuM"
  },
  {
    "albumName": "Land of Fans and Music 4",
    "artist": [
      "Mathias Ramalho"
    ],
    "name": "Robert Jailbreak",
    "tags": [
      "LoFaM"
    ],
    "youtubeId": "BaiE1IJwV_E"
  },
  {
    "albumName": "Land of Fans and Music 5",
    "artist": [
      "WHATISLOSTINTHEMINES"
    ],
    "name": "Sburban Fall",
    "tags": [
      "John"
    ],
    "youtubeId": "NVv0CPbArG8"
  },
  {
    "albumName": "Land of Fans and Music",
    "artist": [
      "Alex Rosetti"
    ],
    "name": "Dance of the Wayward Vagabond",
    "tags": [
      "WV",
      "Serenity",
      "Ruined Earth",
      "Can Town"
    ],
    "youtubeId": "y41tmpZvU5Y"
  },
  {
    "albumName": "Land of Fans and Music 5",
    "artist": [
      "repeatedScales"
    ],
    "name": "Gold Mage [Playtime is Over Mix]",
    "tags": [
      "Sollux"
    ],
    "youtubeId": "j-tGgV4IQjI"
  },
  {
    "albumName": "Land of Fans and Music 4",
    "artist": [
      "Mmetafour"
    ],
    "name": "Prospitian Crescent",
    "tags": [
      "Jane",
      "Jake",
      "Jack Noir",
      "Prospit"
    ],
    "youtubeId": "Z1wJl53DjKA"
  },
  {
    "albumName": "Land of Fans and Music 5",
    "artist": [
      "Monckat"
    ],
    "name": "Black Hole's Dozen",
    "tags": [
      "Alternate Calliope",
      "Green Sun"
    ],
    "youtubeId": "jRzCCnQMy0A"
  },
  {
    "albumName": "Land of Fans and Music 3",
    "artist": [
      "Max Wright"
    ],
    "name": "Maid in Spades",
    "tags": [
      "The Handmaid"
    ],
    "youtubeId": "AxhjCMJTGl8"
  },
  {
    "albumName": "Land of Fans and Music 3",
    "artist": [
      "Catboss"
    ],
    "name": "Theme of the Slam Jam",
    "tags": [
      "Roxy",
      "Dirk"
    ],
    "youtubeId": "ESHQd9oIr3Q"
  },
  {
    "albumName": "Land of Fans and Music 4",
    "artist": [
      "Whimsy"
    ],
    "name": "Radness from Concentrate",
    "tags": [
      "Dave"
    ],
    "youtubeId": "ruD5hcRqhIs"
  },
  {
    "albumName": "Land of Fans and Music 2",
    "artist": [
      "Thomas Ferkol"
    ],
    "name": "Cuddlefish",
    "tags": [
      "Feferi",
      "Dream Bubbles"
    ],
    "youtubeId": "KN0VCAGHVxY"
  },
  {
    "albumName": "Land of Fans and Music 5",
    "artist": [
      "cookiefonster"
    ],
    "name": "Aggrieve (Knightcore Mix)",
    "tags": [
      "Rose",
      "Doc Scratch",
      "Kanaya"
    ],
    "youtubeId": "oRAxcEUV2CY"
  },
  {
    "albumName": "Land of Fans and Music 4",
    "artist": [
      "Lambda Bledsoe",
      "ndividedbyzero"
    ],
    "name": "~~DISC 1~~",
    "tags": [
      "LoFaM"
    ],
    "youtubeId": "vKih1dFsXZU"
  },
  {
    "albumName": "Land of Fans and Music",
    "artist": [
      "Erik Scheele"
    ],
    "name": "Under the Hat",
    "tags": [
      "John"
    ],
    "youtubeId": "wNZlrF3JkIE"
  },
  {
    "albumName": "Land of Fans and Music 3",
    "artist": [
      "Robert J! Lake"
    ],
    "name": "JOHN DO THE WINDY THING",
    "tags": [
      "John"
    ],
    "youtubeId": "BkS6EQjFJJM"
  },
  {
    "albumName": "Land of Fans and Music",
    "artist": [
      "Solatrus"
    ],
    "name": "Doctor (Deep Breeze Mix)",
    "tags": [
      "John"
    ],
    "youtubeId": "L25OBcThKc8"
  },
  {
    "albumName": "Land of Fans and Music 2",
    "artist": [
      "Artisticpolo"
    ],
    "name": "In The Shadow of a Fractured Sun",
    "tags": [
      "Aradia",
      "Sollux",
      "Green Sun"
    ],
    "youtubeId": "Mu93RembYqM"
  },
  {
    "albumName": "Land of Fans and Music 3",
    "artist": [
      "Veritas Unae",
      "David Ellis"
    ],
    "name": "Garden of Eden (Part 2)",
    "tags": [
      "LoFaM"
    ],
    "youtubeId": "S9qynPjh44o"
  },
  {
    "albumName": "Land of Fans and Music 2",
    "artist": [
      "David Dycus"
    ],
    "name": "Prophecy",
    "tags": [
      "Rose",
      "Horrorterrors"
    ],
    "youtubeId": "uFGakc3gyDM"
  },
  {
    "albumName": "Land of Fans and Music 4",
    "artist": [
      "Aris Martinian"
    ],
    "name": "Horizontal Headshot",
    "tags": [
      "Dirk",
      "Jane",
      "Roxy",
      "Jake",
      "Meenah"
    ],
    "youtubeId": "XfBz4kvoU7Q"
  },
  {
    "albumName": "Land of Fans and Music 5",
    "artist": [
      "Pascal van den Bos"
    ],
    "name": "Sun and Setter",
    "tags": [
      "Dave",
      "Davesprite",
      "Earth"
    ],
    "youtubeId": "hGloMT4eTVc"
  },
  {
    "albumName": "Land of Fans and Music 2",
    "artist": [
      "Jamie Paige Stanley"
    ],
    "name": "Sports",
    "tags": [
      "SBaHJ"
    ],
    "youtubeId": "Hai8r6SGKV4"
  },
  {
    "albumName": "Land of Fans and Music 4",
    "artist": [
      "TirantBacon"
    ],
    "name": "Midnight",
    "tags": [
      "Jack Noir",
      "LoTaK"
    ],
    "youtubeId": "VYG6ppBirVs"
  },
  {
    "albumName": "The Baby is You",
    "artist": [
      "Toby Fox"
    ],
    "name": "vs bros",
    "tags": [
      "John",
      "Karkat",
      "Dave"
    ],
    "youtubeId": "ustNt1bH9JQ"
  },
  {
    "albumName": "Land of Fans and Music 2",
    "artist": [
      "Xauric"
    ],
    "name": "Alphamatic Replacement",
    "tags": [
      "Jane",
      "Roxy",
      "Dirk",
      "Jake"
    ],
    "youtubeId": "IEI_jZ4jiOU"
  },
  {
    "albumName": "Land of Fans and Music 4",
    "artist": [
      "Joe Ouellet"
    ],
    "name": "Everlasting",
    "tags": [
      "Roxy",
      "Dream Bubbles"
    ],
    "youtubeId": "oDwdsffF2yk"
  },
  {
    "albumName": "Land of Fans and Music 3",
    "artist": [
      "ndividedbyzero"
    ],
    "name": "Explore [Keygen Mix]",
    "tags": [
      "WV",
      "PM",
      "AR",
      "Serenity",
      "Ruined Earth"
    ],
    "youtubeId": "LIbHdn-j898"
  },
  {
    "albumName": "Land of Fans and Music 4",
    "artist": [
      "Shandy"
    ],
    "name": "My Lady Greensleeves",
    "tags": [
      "Rose",
      "Kanaya",
      "Meteor"
    ],
    "youtubeId": "0lfeOeIA4is"
  },
  {
    "albumName": "Land of Fans and Music 2",
    "artist": [
      "Eston Schweickart"
    ],
    "name": "Squiddles In Sunglasses",
    "tags": [
      "Squiddles"
    ],
    "youtubeId": "Cf9oKcZ2nPA"
  },
  {
    "albumName": "Land of Fans and Music 2",
    "artist": [
      "Rachel Rose Mitchell"
    ],
    "name": "Blood Knight",
    "tags": [
      "Karkat"
    ],
    "youtubeId": "kmnBn-BKAzg"
  },
  {
    "albumName": "Homestuck for the Holidays",
    "artist": [
      "Erik Scheele"
    ],
    "name": "Carolmanthetime",
    "tags": [
      "John",
      "Rose",
      "Dave",
      "Jade",
      "Squiddles",
      "Birds",
      "LoFaF"
    ],
    "youtubeId": "pCWVB-AjnXk"
  },
  {
    "albumName": "Land of Fans and Music 5",
    "artist": [
      "WarxTron",
      "David Ellis (Takeover / Havoc organ solo)",
      "Fabricio Podest\u00e1 (Takeover / Havoc guitar solo)"
    ],
    "name": "Ophiuchus (Full Suite)",
    "tags": [
      "Caliborn",
      "Calliope"
    ],
    "youtubeId": "cmD50Nc54j8"
  },
  {
    "albumName": "Land of Fans and Music 5",
    "artist": [
      "Circlejourney"
    ],
    "name": "She's Out There (In The Sea Of Stars)",
    "tags": [
      "Kanaya",
      "Rose",
      "The Theseus"
    ],
    "youtubeId": "7Ur5B1q_ALI"
  },
  {
    "albumName": "Land of Fans and Music 2",
    "artist": [
      "Joe Ouellet"
    ],
    "name": "Satellite Physician",
    "tags": [
      "LoWaS"
    ],
    "youtubeId": "RCPevVDN7xE"
  },
  {
    "albumName": "Land of Fans and Music 4",
    "artist": [
      "Grace Medley"
    ],
    "name": "Bonehead Destiny (LoFaM Cut)",
    "tags": [
      "LoFaM"
    ],
    "youtubeId": "wmJt4OJoI38"
  },
  {
    "albumName": "Land of Fans and Music 3",
    "artist": [
      "Team Dogfight"
    ],
    "name": "Sord.....",
    "tags": [
      "SBaHJ"
    ],
    "youtubeId": "gZuY-y9LF6A"
  },
  {
    "albumName": "The Baby is You",
    "artist": [
      "Toby Fox"
    ],
    "name": "The Baby is You",
    "tags": [
      "John",
      "Karkat",
      "Dave"
    ],
    "youtubeId": "BtCsf7tFBIQ"
  },
  {
    "albumName": "Land of Fans and Music 3",
    "artist": [
      "Veritas Unae"
    ],
    "name": "Sgrubian Wilderness",
    "tags": [
      "Karkat"
    ],
    "youtubeId": "hVVbfM8aSgU"
  },
  {
    "albumName": "Land of Fans and Music 5",
    "artist": [
      "cookiefonster"
    ],
    "name": "Madame Controversielle 2",
    "tags": [
      "Vriska",
      "Karkat",
      "Terezi"
    ],
    "youtubeId": "ZemSjnaNP7E"
  },
  {
    "albumName": "Land of Fans and Music 3",
    "artist": [
      "Thomas Ibarra"
    ],
    "name": "Electric Fireflies",
    "tags": [
      "Calliope",
      "John",
      "Rose",
      "Dave",
      "Jade",
      "Jane",
      "Roxy",
      "Dirk",
      "Jake",
      "Serenity"
    ],
    "youtubeId": "pR_doGsiHL0"
  },
  {
    "albumName": "Land of Fans and Music 4",
    "artist": [
      "Kevin Grant"
    ],
    "name": "Hiatus",
    "tags": [
      "Gamzee",
      "Terezi"
    ],
    "youtubeId": "kOKKXW4NsCs"
  },
  {
    "albumName": "Land of Fans and Music 2",
    "artist": [
      "Plumegeist"
    ],
    "name": "Showtime (End Strife Remix)",
    "tags": [
      "John"
    ],
    "youtubeId": "Ru9a5pAXfw0"
  },
  {
    "albumName": "Land of Fans and Music 2",
    "artist": [
      "Catboss"
    ],
    "name": "Dance-Stab-Dance",
    "tags": [
      "Jack Noir"
    ],
    "youtubeId": "13RA9z5S-MA"
  },
  {
    "albumName": "Homestuck for the Holidays",
    "artist": [
      "Erik Scheele"
    ],
    "name": "Billy the Bellsuit Diver Has Something to Say",
    "tags": [
      "John",
      "Rose",
      "Dave",
      "Jade",
      "Squiddles",
      "Birds",
      "LoFaF"
    ],
    "youtubeId": "cRxCQv2nutw"
  },
  {
    "albumName": "Land of Fans and Music 4",
    "artist": [
      "Veritas Unae"
    ],
    "name": "Glide",
    "tags": [
      "Alternate Calliope",
      "Green Sun"
    ],
    "youtubeId": "vV7wSEH_lh0"
  },
  {
    "albumName": "Land of Fans and Music",
    "artist": [
      "The Lovable Tramp"
    ],
    "name": "Salamander Fiesta",
    "tags": [
      "Consorts",
      "LoWaS"
    ],
    "youtubeId": "Thl5GVkYm3w"
  },
  {
    "albumName": "Land of Fans and Music",
    "artist": [
      "Eston Schweickart"
    ],
    "name": "Emissary of Wind",
    "tags": [
      "John",
      "LoWaS"
    ],
    "youtubeId": "oXlCAWBo668"
  },
  {
    "albumName": "Land of Fans and Music 3",
    "artist": [
      "Veritas Unae",
      "David Ellis"
    ],
    "name": "The Demon and the Alien",
    "tags": [
      "Aradia",
      "Jack Noir"
    ],
    "youtubeId": "S2-FuyiekQQ"
  },
  {
    "albumName": "Land of Fans and Music 2",
    "artist": [
      "Samm Neiland"
    ],
    "name": "The Four Thrones",
    "tags": [
      "John",
      "Rose",
      "Dave",
      "Jade",
      "Skaia"
    ],
    "youtubeId": "gjRlg2sGgN0"
  },
  {
    "albumName": "Land of Fans and Music 3",
    "artist": [
      "Veritas Unae",
      "David Ellis"
    ],
    "name": "Garden of Eden (Part 1)",
    "tags": [
      "John",
      "Jane",
      "John's dad"
    ],
    "youtubeId": "om3RdlsEvJ4"
  },
  {
    "albumName": "Land of Fans and Music 5",
    "artist": [
      "Gryotharian"
    ],
    "name": "Swan Song/Until Next Time",
    "tags": [
      "LoFaM"
    ],
    "youtubeId": "34fs7ne4xIs"
  },
  {
    "albumName": "Land of Fans and Music 4",
    "artist": [
      "Whimsy"
    ],
    "name": "Altitude",
    "tags": [
      "John",
      "Roxy",
      "Serenity",
      "LoWaS"
    ],
    "youtubeId": "awL_UjIeyo0"
  },
  {
    "albumName": "Land of Fans and Music 5",
    "artist": [
      "Grace Medley"
    ],
    "name": "Event Horizon/Barium Starlight",
    "tags": [
      "Rose"
    ],
    "youtubeId": "Ius7ZXFrfw8"
  },
  {
    "albumName": "Land of Fans and Music 4",
    "artist": [
      "Hadron"
    ],
    "name": "Raise of the Conductor's Baton",
    "tags": [
      "Alternate Calliope",
      "Green Sun"
    ],
    "youtubeId": "8NgabIloI9U"
  },
  {
    "albumName": "Land of Fans and Music 2",
    "artist": [
      "Catboss"
    ],
    "name": "Guns Blazing",
    "tags": [
      "Jake"
    ],
    "youtubeId": "nzXoB2hXYCw"
  },
  {
    "albumName": "Land of Fans and Music 5",
    "artist": [
      "Scorpyus"
    ],
    "name": "Dreamers",
    "tags": [
      "Dave",
      "Derse"
    ],
    "youtubeId": "Y-7ltO31Jmk"
  },
  {
    "albumName": "Land of Fans and Music 4",
    "artist": [
      "Interrobang"
    ],
    "name": "Ultimate Alchemy",
    "tags": [
      "Skaia",
      "LoTaK"
    ],
    "youtubeId": "0mYCTAeiHGw"
  },
  {
    "albumName": "Land of Fans and Music 2",
    "artist": [
      "Land of Ships and Music"
    ],
    "name": "We Won't Wander Alone",
    "tags": [
      "WV",
      "PM",
      "Serenity"
    ],
    "youtubeId": "xFXXrQoAZs4"
  },
  {
    "albumName": "Land of Fans and Music 5",
    "artist": [
      "Spad3s"
    ],
    "name": "Black (Electro Swing Remix)",
    "tags": [
      "Jack Noir"
    ],
    "youtubeId": "KvCTWeq-gS0"
  },
  {
    "albumName": "Land of Fans and Music 4",
    "artist": [
      "Rhyselinn"
    ],
    "name": "The End of Something Really Excellent",
    "tags": [
      "Green Sun"
    ],
    "youtubeId": "0XRCD67voTo"
  },
  {
    "albumName": "Land of Fans and Music 5",
    "artist": [
      "Shwan"
    ],
    "name": "Viridescent Flare",
    "tags": [
      "Green Sun"
    ],
    "youtubeId": "X3yZsftMe4o"
  },
  {
    "albumName": "Land of Fans and Music 4",
    "artist": [
      "SplitSuns"
    ],
    "name": "Stubborn Throes",
    "tags": [
      "Rose",
      "Horrorterrors"
    ],
    "youtubeId": "AVy88ePWytE"
  },
  {
    "albumName": "Land of Fans and Music 4",
    "artist": [
      "Shandy"
    ],
    "name": "Reconciled",
    "tags": [
      "Dave",
      "Dirk",
      "Sawtooth",
      "Squarewave",
      "Lil Cal",
      "SBaHJ",
      "LoTaK"
    ],
    "youtubeId": "oTRDtWd5JBM"
  },
  {
    "albumName": "Land of Fans and Music 3",
    "artist": [
      "Eston Schweickart"
    ],
    "name": "Slick Snow",
    "tags": [
      "Snowman",
      "Jack Noir"
    ],
    "youtubeId": "lChTGSEh4Iw"
  },
  {
    "albumName": "Land of Fans and Music 5",
    "artist": [
      "Interrobang"
    ],
    "name": "Consonant Conquest",
    "tags": [
      "Caliborn"
    ],
    "youtubeId": "oIDaGuA9wrU"
  },
  {
    "albumName": "Land of Fans and Music 3",
    "artist": [
      "Thomas Ferkol"
    ],
    "name": "Dominion",
    "tags": [
      "Dirk",
      "Dave's bro",
      "LoTaK"
    ],
    "youtubeId": "TKHT08gIilQ"
  },
  {
    "albumName": "Land of Fans and Music 3",
    "artist": [
      "Astro Kid"
    ],
    "name": "RetroBution (ohgodwhy Remix)",
    "tags": [
      "Dirk",
      "Aranea",
      "Jake",
      "LoFaF"
    ],
    "youtubeId": "gVTIRFO-4n0"
  },
  {
    "albumName": "Land of Fans and Music 5",
    "artist": [
      "Astro Kid"
    ],
    "name": "Firefly Storm",
    "tags": [
      "John",
      "LoWaS"
    ],
    "youtubeId": "pwgTe6OiQ6w"
  },
  {
    "albumName": "Land of Fans and Music 4",
    "artist": [
      "Seijen"
    ],
    "name": "Plethoric Euphoria",
    "tags": [
      "Gamzee"
    ],
    "youtubeId": "zthLAMi3bac"
  },
  {
    "albumName": "Land of Fans and Music 2",
    "artist": [
      "Clark Powell"
    ],
    "name": "Ye Flarey Gentlemen",
    "tags": [
      "Aradia",
      "Sollux",
      "Green Sun"
    ],
    "youtubeId": "AWWt9nhx6t0"
  },
  {
    "albumName": "Land of Fans and Music 5",
    "artist": [
      "Scorpyus"
    ],
    "name": "Blood Rite",
    "tags": [
      "The Condesce",
      "The Handmaid"
    ],
    "youtubeId": "P_Znys9mqnI"
  },
  {
    "albumName": "Land of Fans and Music",
    "artist": [
      "D. Crystal"
    ],
    "name": "Sunshaker",
    "tags": [
      "Doc Scratch"
    ],
    "youtubeId": "_YhSVxovTO8"
  },
  {
    "albumName": "Land of Fans and Music 4",
    "artist": [
      "Kusoro"
    ],
    "name": "Sugar Cubes",
    "tags": [
      "Cats",
      "LoLCaT"
    ],
    "youtubeId": "DZd5TAXggSA"
  },
  {
    "albumName": "Land of Fans and Music",
    "artist": [
      "James Roach"
    ],
    "name": "Atomik Meltdown",
    "tags": [
      "Dave"
    ],
    "youtubeId": "W4EdCoD_dIQ"
  },
  {
    "albumName": "Land of Fans and Music 3",
    "artist": [
      "Yan Rodriguez"
    ],
    "name": "Scabbard",
    "tags": [
      "The Condesce"
    ],
    "youtubeId": "tvpvjxoJr-Y"
  },
  {
    "albumName": "Land of Fans and Music 4",
    "artist": [
      "Aris Martinian"
    ],
    "name": "-Omega- Timeline",
    "tags": [
      "Terezi",
      "LoPaN"
    ],
    "youtubeId": "qAG2vYH3gzA"
  },
  {
    "albumName": "The Baby is You",
    "artist": [
      "Toby Fox"
    ],
    "name": "A History of Babies",
    "tags": [
      "John",
      "Karkat",
      "Dave"
    ],
    "youtubeId": "5Z5XKg37ZGc"
  },
  {
    "albumName": "Land of Fans and Music 4",
    "artist": [
      "Lark Mordancy"
    ],
    "name": "Fond Regard",
    "tags": [
      "Gate",
      "Lotus"
    ],
    "youtubeId": "_C0iTqg5JFo"
  },
  {
    "albumName": "Land of Fans and Music 2",
    "artist": [
      "Catboss"
    ],
    "name": "Good Dog (Best Friend)",
    "tags": [
      "Jade",
      "Becquerel",
      "Earth"
    ],
    "youtubeId": "pYYBRLIKRQ8"
  },
  {
    "albumName": "Land of Fans and Music 3",
    "artist": [
      "Catboss"
    ],
    "name": "Emissary of Dance",
    "tags": [
      "John",
      "LoWaS"
    ],
    "youtubeId": "G4qa1mTNYrc"
  },
  {
    "albumName": "Land of Fans and Music 3",
    "artist": [
      "Princey The Kid"
    ],
    "name": "Insomniac",
    "tags": [
      "Caliborn",
      "Calliope"
    ],
    "youtubeId": "CfgQ7QS1JHA"
  },
  {
    "albumName": "Land of Fans and Music",
    "artist": [
      "Eligecos"
    ],
    "name": "Joker Strife",
    "tags": [
      "Gamzee"
    ],
    "youtubeId": "EpifLARQWZk"
  },
  {
    "albumName": "Land of Fans and Music 4",
    "artist": [
      "Noisemaker"
    ],
    "name": "Schr\u00f6dinger's Harbinger",
    "tags": [
      "Rose",
      "Jasprose",
      "Roxy",
      "Cats"
    ],
    "youtubeId": "n39rrqi8XPM"
  },
  {
    "albumName": "Land of Fans and Music 3",
    "artist": [
      "Colin Stanfill"
    ],
    "name": "Sleepy Eyes of Derse",
    "tags": [
      "Roxy",
      "Derse"
    ],
    "youtubeId": "JQ9uU7w6gxA"
  },
  {
    "albumName": "Land of Fans and Music 2",
    "artist": [
      "David Dycus"
    ],
    "name": "Jane's Theme",
    "tags": [
      "Jane",
      "Rabbit",
      "LoCaH"
    ],
    "youtubeId": "J-Ff0WoEiS8"
  },
  {
    "albumName": "Land of Fans and Music 4",
    "artist": [
      "Kurtis Burton"
    ],
    "name": "Blind Pilot",
    "tags": [
      "The Psiioniic",
      "Alternia"
    ],
    "youtubeId": "D_CyxkI8ffw"
  },
  {
    "albumName": "Land of Fans and Music 3",
    "artist": [
      "Joe Ouellet"
    ],
    "name": "Together (At Last)",
    "tags": [
      "John",
      "Rose",
      "Dave",
      "Jade"
    ],
    "youtubeId": "jOa8YAWe-bY"
  },
  {
    "albumName": "Homestuck for the Holidays",
    "artist": [
      "Mark Hadley"
    ],
    "name": "Let it Snow",
    "tags": [
      "John",
      "Rose",
      "Dave",
      "Jade",
      "Squiddles",
      "Birds",
      "LoFaF"
    ],
    "youtubeId": "BaNH2BKfRKk"
  },
  {
    "albumName": "Land of Fans and Music",
    "artist": [
      "EnnuiKing"
    ],
    "name": "The Hymn of Making Babies",
    "tags": [
      "John"
    ],
    "youtubeId": "UJG_HuK0gAE"
  },
  {
    "albumName": "Land of Fans and Music 3",
    "artist": [
      "Tamara Fritz"
    ],
    "name": "Hymn of the Horrorterrors",
    "tags": [
      "Rose",
      "Horrorterrors"
    ],
    "youtubeId": "KUd1l0NnSrU"
  },
  {
    "albumName": "Land of Fans and Music 4",
    "artist": [
      "ndividedbyzero"
    ],
    "name": "Retconjuration",
    "tags": [
      "John",
      "Roxy",
      "LoWaS",
      "Skaia"
    ],
    "youtubeId": "OaT69NSo3Ds"
  },
  {
    "albumName": "Land of Fans and Music 5",
    "artist": [
      "Levc"
    ],
    "name": "Hippocampus Sea",
    "tags": [
      "Eridan's lusus",
      "Alternia"
    ],
    "youtubeId": "BpJ_Sz45gr4"
  },
  {
    "albumName": "Land of Fans and Music",
    "artist": [
      "EnnuiKing"
    ],
    "name": "Corpse Casanova",
    "tags": [
      "Karkat",
      "Kanaya"
    ],
    "youtubeId": "SRfuIqOk4ng"
  },
  {
    "albumName": "Land of Fans and Music 2",
    "artist": [
      "Veritas Unae"
    ],
    "name": "\u2022~DISK 1~\u2022",
    "tags": [
      "LoFaM"
    ],
    "youtubeId": "x_pSKzA1_Eg"
  },
  {
    "albumName": "Land of Fans and Music",
    "artist": [
      "Max Wright"
    ],
    "name": "House of Lalonde",
    "tags": [
      "Rose's mom",
      "Earth"
    ],
    "youtubeId": "olGAOvLH2AY"
  },
  {
    "albumName": "Land of Fans and Music 4",
    "artist": [
      "ndividedbyzero"
    ],
    "name": "Juju Breaker",
    "tags": [
      "The Felt"
    ],
    "youtubeId": "W46m0Qyes5g"
  },
  {
    "albumName": "Land of Fans and Music 2",
    "artist": [
      "Domble"
    ],
    "name": "The Dance of Oblivion",
    "tags": [
      "Rose"
    ],
    "youtubeId": "dw9fX9Z6jK4"
  },
  {
    "albumName": "Homestuck for the Holidays",
    "artist": [
      "Robert J! Lake"
    ],
    "name": "Pachelbel's Gardener",
    "tags": [
      "John",
      "Rose",
      "Dave",
      "Jade",
      "Squiddles",
      "Birds",
      "LoFaF"
    ],
    "youtubeId": "O0Pnv0AKM2U"
  },
  {
    "albumName": "Land of Fans and Music 4",
    "artist": [
      "David Dycus"
    ],
    "name": "Requited (Dirtiest Remix)",
    "tags": [
      "Nepeta",
      "Meulin",
      "The Disciple"
    ],
    "youtubeId": "CK3XrBtxc4w"
  },
  {
    "albumName": "Land of Fans and Music",
    "artist": [
      "Robert J! Lake"
    ],
    "name": "Cutscene at the End of the Hallway",
    "tags": [
      "Gate"
    ],
    "youtubeId": "ZSWphWAVpfs"
  },
  {
    "albumName": "Land of Fans and Music 5",
    "artist": [
      "Gryotharian"
    ],
    "name": "Dance of Entropy",
    "tags": [
      "Snowman",
      "Jack Noir"
    ],
    "youtubeId": "OQ1eE_m1jtU"
  },
  {
    "albumName": "Land of Fans and Music 5",
    "artist": [
      "salex_r3kt"
    ],
    "name": "Crystallized Flowers DX",
    "tags": [
      "Jade",
      "LoFaF"
    ],
    "youtubeId": "oIH6rZMqBI0"
  },
  {
    "albumName": "Homestuck for the Holidays",
    "artist": [
      "Robert J! Lake"
    ],
    "name": "Oh God, Christmas!",
    "tags": [
      "John",
      "Rose",
      "Dave",
      "Jade",
      "Squiddles",
      "Birds",
      "LoFaF"
    ],
    "youtubeId": "wN6DYiH6aws"
  },
  {
    "albumName": "Land of Fans and Music",
    "artist": [
      "Thomas Ibarra"
    ],
    "name": "Downtime",
    "tags": [
      "Felt Manor"
    ],
    "youtubeId": "IKnJNJHty2U"
  },
  {
    "albumName": "Land of Fans and Music",
    "artist": [
      "Thomas Ferkol"
    ],
    "name": "Moody Mister Gemini",
    "tags": [
      "Sollux"
    ],
    "youtubeId": "vySAcabFPvk"
  },
  {
    "albumName": "Land of Fans and Music 2",
    "artist": [
      "Brenden Cameron"
    ],
    "name": "Till The Last Breath",
    "tags": [
      "John"
    ],
    "youtubeId": "LKua4UM8qZU"
  },
  {
    "albumName": "Land of Fans and Music 3",
    "artist": [
      "David Ellis"
    ],
    "name": "INFIDELS!",
    "tags": [
      "The Signless",
      "The Psiioniic",
      "The Disciple",
      "The Dolorosa",
      "Alternia"
    ],
    "youtubeId": "VWPc1T6BZIA"
  },
  {
    "albumName": "Land of Fans and Music 4",
    "artist": [
      "Grace Medley"
    ],
    "name": "Emerald Cinders",
    "tags": [
      "Snowman"
    ],
    "youtubeId": "XpIc_vizYRw"
  },
  {
    "albumName": "Land of Fans and Music 4",
    "artist": [
      "Marcy Nabors",
      "Jamie Paige Stanley"
    ],
    "name": "Pseudumbra Phauxtasm",
    "tags": [
      "John",
      "Alternate Calliope",
      "Skaia",
      "Green Sun"
    ],
    "youtubeId": "zzq3u6unwRg"
  },
  {
    "albumName": "Land of Fans and Music 5",
    "artist": [
      "Shwan"
    ],
    "name": "Wayward",
    "tags": [
      "WV",
      "Ruined Earth"
    ],
    "youtubeId": "mbq4si7qGnE"
  },
  {
    "albumName": "Land of Fans and Music 2",
    "artist": [
      "Domble"
    ],
    "name": "Rise of the Denizens",
    "tags": [
      "Jade",
      "John",
      "Davesprite",
      "Denizens",
      "LoFaF",
      "LoLaR",
      "LoHaC",
      "LoWaS"
    ],
    "youtubeId": "C0WiWG6BVbw"
  },
  {
    "albumName": "Land of Fans and Music 4",
    "artist": [
      "Thomas Ferkol"
    ],
    "name": "Tragic Heights",
    "tags": [
      "Dirk",
      "LoTaK"
    ],
    "youtubeId": "9_IU46cLUq4"
  },
  {
    "albumName": "Land of Fans and Music 5",
    "artist": [
      "Noisemaker"
    ],
    "name": "Infinite Zest",
    "tags": [
      "Jade",
      "WV",
      "Earth",
      "Green Sun",
      "Horrorterrors"
    ],
    "youtubeId": "X5CnrosloJI"
  },
  {
    "albumName": "Land of Fans and Music 2",
    "artist": [
      "Rachel Rose Mitchell"
    ],
    "name": "CR1M1N4L, CR1M1N4L",
    "tags": [
      "Scalemates"
    ],
    "youtubeId": "V3C1H4l9eIU"
  },
  {
    "albumName": "Land of Fans and Music 3",
    "artist": [
      "Nick Smalley",
      "Solatrus"
    ],
    "name": "Cold Steel",
    "tags": [
      "Jade",
      "Frogs",
      "Denizens"
    ],
    "youtubeId": "ySBgn7fy-VU"
  },
  {
    "albumName": "Land of Fans and Music 3",
    "artist": [
      "Catboss"
    ],
    "name": "Oh Hi There, Lil Cal",
    "tags": [
      "Lil Cal"
    ],
    "youtubeId": "BidbwQ8gRQk"
  },
  {
    "albumName": "Land of Fans and Music 5",
    "artist": [
      "Gryotharian"
    ],
    "name": "Revelations of Self Importance",
    "tags": [
      "Dirk",
      "Dave"
    ],
    "youtubeId": "UQFQwZCFb-E"
  },
  {
    "albumName": "Land of Fans and Music 3",
    "artist": [
      "Ashley Jones"
    ],
    "name": "Sunsoaked Heavenscape",
    "tags": [
      "PM",
      "Ruined Earth"
    ],
    "youtubeId": "jQHjxDkfjKg"
  },
  {
    "albumName": "Land of Fans and Music 3",
    "artist": [
      "Veritas Unae"
    ],
    "name": "\u02da Disk 1 \u02da",
    "tags": [
      "LoFaM"
    ],
    "youtubeId": "o3cpnDkAhFY"
  },
  {
    "albumName": "Land of Fans and Music 3",
    "artist": [
      "Nitrosparxx"
    ],
    "name": "Courtroom Shuffle",
    "tags": [
      "Terezi",
      "Karkat",
      "Tavros",
      "Nepeta",
      "Vriska",
      "Gamzee",
      "Eridan"
    ],
    "youtubeId": "Ue2n6q90Yko"
  },
  {
    "albumName": "Land of Fans and Music 2",
    "artist": [
      "Malcolm Brown"
    ],
    "name": "Joy And Dreams Eternal",
    "tags": [
      "Jade",
      "Prospit",
      "Skaia"
    ],
    "youtubeId": "KlKvnXvJ5a8"
  },
  {
    "albumName": "Land of Fans and Music 3",
    "artist": [
      "Joe Ouellet"
    ],
    "name": "Fortune Healer",
    "tags": [
      "Aranea"
    ],
    "youtubeId": "xN72OzBjXK4"
  },
  {
    "albumName": "Land of Fans and Music",
    "artist": [
      "Erik Scheele"
    ],
    "name": "SWEET BRO AND HELLA JEFF SHOW",
    "tags": [
      "SBaHJ"
    ],
    "youtubeId": "pEvOb04ySb4"
  },
  {
    "albumName": "Land of Fans and Music 5",
    "artist": [
      "Shwan"
    ],
    "name": "Saccharine Slumber, Strider",
    "tags": [
      "Dirk",
      "Dave",
      "Horses",
      "SBaHJ"
    ],
    "youtubeId": "115zhNX61vY"
  },
  {
    "albumName": "Homestuck for the Holidays",
    "artist": [
      "Robert J! Lake"
    ],
    "name": "A Very Special Time",
    "tags": [
      "John",
      "Rose",
      "Dave",
      "Jade",
      "Squiddles",
      "Birds",
      "LoFaF"
    ],
    "youtubeId": "apVVewzERd0"
  },
  {
    "albumName": "Land of Fans and Music 3",
    "artist": [
      "Kevin Grant"
    ],
    "name": "Ohgodboss",
    "tags": [
      "Dave",
      "Jade",
      "Jack Noir",
      "LoFaF"
    ],
    "youtubeId": "3YW7xoPYruo"
  },
  {
    "albumName": "Land of Fans and Music 3",
    "artist": [
      "Marcy Nabors"
    ],
    "name": "Contention",
    "tags": [
      "Dirk",
      "Sawtooth",
      "Imperial Drones",
      "Earth"
    ],
    "youtubeId": "M9EAfYlHxzA"
  },
  {
    "albumName": "Land of Fans and Music 4",
    "artist": [
      "Cryptanark"
    ],
    "name": "Ringleader",
    "tags": [
      "The Felt",
      "Felt Manor"
    ],
    "youtubeId": "pF3yZSQqCFY"
  },
  {
    "albumName": "Homestuck for the Holidays",
    "artist": [
      "Robert J! Lake"
    ],
    "name": "Shit, Let's Be Santa",
    "tags": [
      "John",
      "Rose",
      "Dave",
      "Jade",
      "Squiddles",
      "Birds",
      "LoFaF"
    ],
    "youtubeId": "GOMGiD6mWJM"
  },
  {
    "albumName": "Land of Fans and Music 5",
    "artist": [
      "Erik Scheele"
    ],
    "name": "Joey: Play Haunting Melody",
    "tags": [
      "Joey"
    ],
    "youtubeId": "g7uFahy6jCU"
  },
  {
    "albumName": "Land of Fans and Music 3",
    "artist": [
      "Dakota Medina"
    ],
    "name": "Warweary Villein",
    "tags": [
      "WV",
      "Ruined Earth"
    ],
    "youtubeId": "gt6NZuHiomw"
  },
  {
    "albumName": "Land of Fans and Music 4",
    "artist": [
      "Lambda Bledsoe",
      "ndividedbyzero"
    ],
    "name": "~~DISC 2~~",
    "tags": [
      "LoFaM"
    ],
    "youtubeId": "eHrtKUpIbWM"
  },
  {
    "albumName": "Land of Fans and Music",
    "artist": [
      "Yan Rodriguez"
    ],
    "name": "Midnight Spider",
    "tags": [
      "Vriska",
      "Terezi",
      "Equius",
      "Aradia"
    ],
    "youtubeId": "DqaBuZMPees"
  },
  {
    "albumName": "Land of Fans and Music 5",
    "artist": [
      "FrostyMac"
    ],
    "name": "24/7 lo fi anime beats to achieve your destiny to",
    "tags": [
      "John",
      "Cats"
    ],
    "youtubeId": "Hi3uTwAT2-8"
  },
  {
    "albumName": "Land of Fans and Music 4",
    "artist": [
      "Cement City"
    ],
    "name": "Sunslammer (Cement City Remix)",
    "tags": [
      "Jade",
      "Green Sun"
    ],
    "youtubeId": "fL9YfUCMuBs"
  },
  {
    "albumName": "Land of Fans and Music 5",
    "artist": [
      "Mathias Ramalho"
    ],
    "name": "She's a D8ddy L8ng L8gs",
    "tags": [
      "Vriska",
      "Jack Noir",
      "DD",
      "CD",
      "HB",
      "Felt Manor"
    ],
    "youtubeId": "Ty9aULG-1Ak"
  },
  {
    "albumName": "Land of Fans and Music 2",
    "artist": [
      "Willow Ascenzo"
    ],
    "name": "Finale Strife",
    "tags": [
      "Jake",
      "Lord English"
    ],
    "youtubeId": "THrQgH-xopg"
  },
  {
    "albumName": "Land of Fans and Music 3",
    "artist": [
      "Fuzz"
    ],
    "name": "Hope Remains",
    "tags": [
      "Jake"
    ],
    "youtubeId": "fJUZqA5_jYw"
  },
  {
    "albumName": "Land of Fans and Music 2",
    "artist": [
      "Veritas Unae"
    ],
    "name": "I'm The Doctor",
    "tags": [
      "Doc Scratch",
      "Rose",
      "Horrorterrors"
    ],
    "youtubeId": "XehoWGG3SFo"
  },
  {
    "albumName": "Land of Fans and Music 2",
    "artist": [
      "Jamie Paige Stanley"
    ],
    "name": "Prospitian Lullaby",
    "tags": [
      "Jane",
      "Jake",
      "Prospit"
    ],
    "youtubeId": "yLL4-WYFO7Q"
  },
  {
    "albumName": "Land of Fans and Music",
    "artist": [
      "Miss Prince"
    ],
    "name": "Jack and Black Queen",
    "tags": [
      "Jack Noir",
      "BQ"
    ],
    "youtubeId": "Sto3hlSPJ0Y"
  },
  {
    "albumName": "Land of Fans and Music 4",
    "artist": [
      "Kurtis Burton"
    ],
    "name": "Wicker Kingdom",
    "tags": [
      "WQ",
      "WK",
      "Gate",
      "Prospit",
      "Skaia"
    ],
    "youtubeId": "ZawHf_JbItw"
  },
  {
    "albumName": "Land of Fans and Music 4",
    "artist": [
      "Willow Ascenzo"
    ],
    "name": "Emerald Waltz",
    "tags": [
      "Kanaya"
    ],
    "youtubeId": "cH0MZZTmDpY"
  },
  {
    "albumName": "Land of Fans and Music 4",
    "artist": [
      "Musical Panini"
    ],
    "name": "Strife Mayhem",
    "tags": [
      "John",
      "Rose",
      "Dave",
      "Jade",
      "Jane",
      "Roxy",
      "Dirk",
      "Jake",
      "Karkat",
      "Aradia",
      "Tavros",
      "Sollux",
      "Nepeta",
      "Kanaya",
      "Terezi",
      "Vriska",
      "Equius",
      "Gamzee",
      "Eridan",
      "Feferi",
      "WV",
      "PM",
      "AR",
      "WQ",
      "Jack Noir",
      "DD",
      "CD",
      "HB",
      "Davepeta",
      "John's nanna",
      "Davesprite",
      "The Condesce",
      "Lord English",
      "The Handmaid",
      "Snowman",
      "Jack Noir",
      "Underlings",
      "The Felt",
      "AH",
      "LoWaS",
      "LoLaR",
      "LoHaC",
      "LoFaF",
      "LoCaH",
      "LoMaX",
      "LoPaN",
      "LoTaK",
      "Prospit",
      "Derse",
      "Skaia",
      "Green Sun"
    ],
    "youtubeId": "dZ7CuLWkK74"
  },
  {
    "albumName": "Land of Fans and Music 4",
    "artist": [
      "David Ellis"
    ],
    "name": "Homestuck Sonata",
    "tags": [
      "John"
    ],
    "youtubeId": "XSVpRK_L7mg"
  },
  {
    "albumName": "Land of Fans and Music",
    "artist": [
      "Joe Griffith"
    ],
    "name": "~ Heir - Seer - Knight - Witch ~",
    "tags": [
      "John",
      "Rose",
      "Dave",
      "Jade"
    ],
    "youtubeId": "YT2a2jI8_Yo"
  },
  {
    "albumName": "Land of Fans and Music 4",
    "artist": [
      "Helena Ruth"
    ],
    "name": "Scales",
    "tags": [
      "Terezi",
      "Pyralspite",
      "Scalemates",
      "Alternia",
      "Meteor"
    ],
    "youtubeId": "Joq_AQZzbVE"
  },
  {
    "albumName": "Land of Fans and Music 2",
    "artist": [
      "Kevin Grant"
    ],
    "name": "Dark Funk",
    "tags": [
      "Jack Noir",
      "DD",
      "CD",
      "HB"
    ],
    "youtubeId": "7m3FoAy7An4"
  },
  {
    "albumName": "Homestuck for the Holidays",
    "artist": [
      "Mark Hadley"
    ],
    "name": "A Skaian Christmas",
    "tags": [
      "John",
      "Rose",
      "Dave",
      "Jade",
      "Squiddles",
      "Birds",
      "LoFaF"
    ],
    "youtubeId": "VPB_PlYW89M"
  },
  {
    "albumName": "Land of Fans and Music 4",
    "artist": [
      "Kouta",
      "polysaw"
    ],
    "name": "Merge",
    "tags": [
      "Davepeta"
    ],
    "youtubeId": "UZW48Yk3GB4"
  },
  {
    "albumName": "Land of Fans and Music 2",
    "artist": [
      "Astro Kid"
    ],
    "name": "JackBot",
    "tags": [
      "Sawtooth",
      "Squarewave",
      "Roxy",
      "Underlings"
    ],
    "youtubeId": "9GFVQ6ZalCc"
  },
  {
    "albumName": "Land of Fans and Music",
    "artist": [
      "Yan Rodriguez"
    ],
    "name": "A Fashionable Escape",
    "tags": [
      "Kanaya"
    ],
    "youtubeId": "WEdZedw0Fno"
  },
  {
    "albumName": "Land of Fans and Music 5",
    "artist": [
      "Tee-vee"
    ],
    "name": "Death Minute in Duodecimal ~ Regicide Demonica",
    "tags": [
      "BK"
    ],
    "youtubeId": "Wc1noeRG2HA"
  },
  {
    "albumName": "Land of Fans and Music 4",
    "artist": [
      "Willow Ascenzo"
    ],
    "name": "Grimbark",
    "tags": [
      "Jade"
    ],
    "youtubeId": "IqzKEk9s5Lg"
  },
  {
    "albumName": "Land of Fans and Music 3",
    "artist": [
      "Joe Ouellet"
    ],
    "name": "Gears and Chandeliers",
    "tags": [
      "LoQaM"
    ],
    "youtubeId": "JIJIE-wQOq0"
  },
  {
    "albumName": "Land of Fans and Music 2",
    "artist": [
      "Max Wright"
    ],
    "name": "The Note Desolation Plays",
    "tags": [
      "John",
      "Earth"
    ],
    "youtubeId": "-NOJYYn-V2Q"
  },
  {
    "albumName": "Land of Fans and Music 5",
    "artist": [
      "Artisticpolo"
    ],
    "name": "Time Running By",
    "tags": [
      "Dave"
    ],
    "youtubeId": "I8njHUPcoRg"
  }
]