export const easyModeSongs = [
   {
      "name":"Killed by BR8K Spider!!!!!!!!",
      "artist":[
         "Toby Fox"
      ],
      "albumName":"AlterniaBound",
      "tags":[
         "Vriska",
         "LoMaT"
      ],
      "youtubeId":"KpnY41JNVbo"
   },
   {
      "name":"Do The Windy Thing",
      "artist":[
         "Brian Schaefer"
      ],
      "albumName":"coloUrs and mayhem: Universe B",
      "tags":[
         "John"
      ],
      "youtubeId":"T-CliOV5-sg"
   },
   {
      "name":"Showtime (Imp Strife Mix)",
      "artist":[
         "Buzinkai",
         "Curt Blakeslee"
      ],
      "albumName":"Homestuck Vol. 1",
      "tags":[
         "Underlings",
         "Rabbit"
      ],
      "youtubeId":"WQyRS_czmgc"
   },
   {
      "name":"Doctor Remix",
      "artist":[
         "Gabe Nezovic"
      ],
      "albumName":"Homestuck Vol. 5",
      "tags":[
         "John",
         "Horrorterrors",
         "LoWaS"
      ],
      "youtubeId":"EhVwra6X4B0"
   },
   {
      "name":"Unite Synchronization",
      "artist":[
         "Malcolm Brown"
      ],
      "albumName":"Homestuck Vol. 8",
      "tags":[
         "Dave",
         "John",
         "Rose",
         "Jade"
      ],
      "youtubeId":"uHmXe2eY6Kg"
   },
   {
      "name":"Tall, Dark and Loathsome",
      "artist":[
         "Clark Powell"
      ],
      "albumName":"Midnight Crew: Drawing Dead",
      "tags":[
         "Jack Noir"
      ],
      "youtubeId":"DxsUKjGirKc"
   },
   {
      "name":"Tribal Ebonpyre",
      "artist":[
         "Erik Scheele"
      ],
      "albumName":"Homestuck Vol. 6: Heir Transparent",
      "tags":[
         "Dave",
         "Consorts",
         "LoHaC"
      ],
      "youtubeId":"xStVhvhg1i4"
   },
   {
      "name":"You Won A Combat",
      "artist":[
         "Toby Fox"
      ],
      "albumName":"AlterniaBound",
      "tags":[
         "Karkat"
      ],
      "youtubeId":"heNdjTRVf5U"
   },
   {
      "name":"Fly",
      "artist":[
         "Michael Guy Bowman"
      ],
      "albumName":"Mobius Trip and Hadron Kaleido",
      "tags":[
         "Hadron Kaleido"
      ],
      "youtubeId":"CUDkBTYWtGk"
   },
   {
      "name":"Trial and Execution",
      "artist":[
         "Malcolm Brown"
      ],
      "albumName":"Homestuck Vol. 7: At the Price of Oblivion",
      "tags":[
         "Terezi",
         "Vriska",
         "Equius",
         "Nepeta",
         "Eridan",
         "Feferi",
         "Gamzee",
         "Tavros",
         "Meteor"
      ],
      "youtubeId":"uxWIej66zwc"
   },
   {
      "name":"Spider8reath",
      "artist":[
         "Tensei"
      ],
      "albumName":"Homestuck Vol. 7: At the Price of Oblivion",
      "tags":[
         "Vriska"
      ],
      "youtubeId":"83B083Lsbfc"
   },
   {
      "name":"III - Serenade",
      "artist":[
         "Clark Powell"
      ],
      "albumName":"Symphony Impossible to Play",
      "tags":[
         "No tags"
      ],
      "youtubeId":"wjvxf4QiHfU"
   },
   {
      "name":"Jane Dargason",
      "artist":[
         "Clark Powell",
         "Robert J! Lake"
      ],
      "albumName":"Homestuck Vol. 9",
      "tags":[
         "Jane",
         "LoCaH",
         "Rabbit"
      ],
      "youtubeId":"jSczYjYAaUY"
   },
   {
      "name":"Plumbthroat Gives Chase",
      "artist":[
         "Alex Rosetti"
      ],
      "albumName":"Squiddles!",
      "tags":[
         "No tags"
      ],
      "youtubeId":"4PfZu1AWTWI"
   },
   {
      "name":"Omelette Sandwich",
      "artist":[
         "Mark Hadley"
      ],
      "albumName":"The Felt",
      "tags":[
         "The Felt",
         "Felt Manor"
      ],
      "youtubeId":"a1FivIzXv48"
   },
   {
      "name":"Ruins (With Strings)",
      "artist":[
         "Erik Scheele",
         "Michael Guy Bowman"
      ],
      "albumName":"Homestuck Vol. 5",
      "tags":[
         "Jade",
         "Lotus",
         "Frog Temple"
      ],
      "youtubeId":"j0vdgsVJGHQ"
   },
   {
      "name":"Knives and Ivory",
      "artist":[
         "Kevin Regamey"
      ],
      "albumName":"Midnight Crew: Drawing Dead",
      "tags":[
         "Jack Noir",
         "Felt Manor"
      ],
      "youtubeId":"D1C7y1u2K5M"
   },
   {
      "name":"Rust Maid",
      "artist":[
         "Plumegeist"
      ],
      "albumName":"coloUrs and mayhem: Universe A",
      "tags":[
         "Aradia"
      ],
      "youtubeId":"ekWCjAjetZA"
   },
   {
      "name":"Science Seahorse",
      "artist":[
         "Toby Fox"
      ],
      "albumName":"AlterniaBound",
      "tags":[
         "Eridan",
         "Meteor"
      ],
      "youtubeId":"9FsGWsS91r8"
   },
   {
      "name":"Pyrocumulus (Kickstart)",
      "artist":[
         "Robert J! Lake"
      ],
      "albumName":"Homestuck Vol. 5",
      "tags":[
         "John"
      ],
      "youtubeId":"KYc4reXiKfE"
   },
   {
      "name":"Hearts Flush",
      "artist":[
         "Mark Hadley"
      ],
      "albumName":"Midnight Crew: Drawing Dead",
      "tags":[
         "CD",
         "The Felt",
         "Felt Manor"
      ],
      "youtubeId":"nb7PoT9w6Tc"
   },
   {
      "name":"Candles and Clockwork (Alpha Version)",
      "artist":[
         "James Dever"
      ],
      "albumName":"Homestuck Vol. 9",
      "tags":[
         "Dave",
         "LoHaC"
      ],
      "youtubeId":"0aPJVZMW4xI"
   },
   {
      "name":"Song of Life",
      "artist":[
         "Steve Everson"
      ],
      "albumName":"Homestuck Vol. 5",
      "tags":[
         "Jade"
      ],
      "youtubeId":"L6IjePPeB0E"
   },
   {
      "name":"Squiddle Samba",
      "artist":[
         "Michael Guy Bowman"
      ],
      "albumName":"Squiddles!",
      "tags":[
         "No tags"
      ],
      "youtubeId":"U1H0Q6FjGyc"
   },
   {
      "name":"II - Sarabande",
      "artist":[
         "Clark Powell"
      ],
      "albumName":"Symphony Impossible to Play",
      "tags":[
         "No tags"
      ],
      "youtubeId":"iADicSzT0VE"
   },
   {
      "name":"Heir of Grief",
      "artist":[
         "Tensei"
      ],
      "albumName":"[S] Collide.",
      "tags":[
         "Dave",
         "Dirk",
         "Terezi",
         "Jack Noir",
         "LoTaK"
      ],
      "youtubeId":"Es5mh7pBeec"
   },
   {
      "name":"Skaia (Incipisphere Mix)",
      "artist":[
         "Solatrus"
      ],
      "albumName":"Homestuck Vol. 5",
      "tags":[
         "Skaia",
         "LoWaS",
         "LoLaR",
         "Prospit",
         "Derse",
         "Veil"
      ],
      "youtubeId":"IaT2a68dyJo"
   },
   {
      "name":"Potential Verdancy",
      "artist":[
         "Robert J! Lake"
      ],
      "albumName":"Homestuck Vol. 1-4",
      "tags":[
         "No tags"
      ],
      "youtubeId":"2W9Dd4RAiHo"
   },
   {
      "name":"Princess of Helium",
      "artist":[
         "Clark Powell"
      ],
      "albumName":"Homestuck Vol. 9",
      "tags":[
         "Jane",
         "LoCaH"
      ],
      "youtubeId":"mAgr3UH7ywo"
   },
   {
      "name":"Hollow Suit",
      "artist":[
         "Alex Rosetti"
      ],
      "albumName":"Midnight Crew: Drawing Dead",
      "tags":[
         "The Felt",
         "Felt Manor"
      ],
      "youtubeId":"k7El193pVkM"
   },
   {
      "name":"Raggy Looking Dance",
      "artist":[
         "Alex Rosetti"
      ],
      "albumName":"The Wanderers",
      "tags":[
         "WV",
         "PM"
      ],
      "youtubeId":"kHSVHbxBEHU"
   },
   {
      "name":"Hussie Hunt",
      "artist":[
         "Malcolm Brown"
      ],
      "albumName":"Homestuck Vol. 8",
      "tags":[
         "AH",
         "Doc Scratch"
      ],
      "youtubeId":"oz4UOqdFQEA"
   },
   {
      "name":"Beatup",
      "artist":[
         "Clark Powell",
         "Astro Kid"
      ],
      "albumName":"Homestuck Vol. 10",
      "tags":[
         "Dave",
         "Dirk",
         "SBaHJ",
         "Earth"
      ],
      "youtubeId":"vHClZp44AQg"
   },
   {
      "name":"The Vast Glub",
      "artist":[
         "Kris Flacke"
      ],
      "albumName":"coloUrs and mayhem: Universe B",
      "tags":[
         "Feferi",
         "Horrorterrors"
      ],
      "youtubeId":"wq3tRD7hBH0"
   },
   {
      "name":"Alternia",
      "artist":[
         "Seth Peelle"
      ],
      "albumName":"AlterniaBound",
      "tags":[
         "Matriorb",
         "Alternia"
      ],
      "youtubeId":"ouwick_Ii2c"
   },
   {
      "name":"Flying Car",
      "artist":[
         "Erik Scheele"
      ],
      "albumName":"One Year Older",
      "tags":[
         "Battlefield"
      ],
      "youtubeId":"lKH9ByKgnfs"
   },
   {
      "name":"Requiem of Sunshine and Rainbows",
      "artist":[
         "Paige Turner"
      ],
      "albumName":"AlterniaBound",
      "tags":[
         "Kanaya"
      ],
      "youtubeId":"erj2UQjMlhs"
   },
   {
      "name":"Three in the Morning",
      "artist":[
         "Clark Powell"
      ],
      "albumName":"Midnight Crew: Drawing Dead",
      "tags":[
         "Snowman"
      ],
      "youtubeId":"zSg6talwzbU"
   },
   {
      "name":"Renewed Return",
      "artist":[
         "Marcy Nabors"
      ],
      "albumName":"Homestuck Vol. 10",
      "tags":[
         "Jane"
      ],
      "youtubeId":"qqZO_D5CO28"
   },
   {
      "name":"Amphibious Subterrain",
      "artist":[
         "Toby Fox"
      ],
      "albumName":"Homestuck Vol. 5",
      "tags":[
         "Consorts",
         "LoWaS"
      ],
      "youtubeId":"b1fKvjP5ego"
   },
   {
      "name":"Terezi Owns",
      "artist":[
         "Toby Fox"
      ],
      "albumName":"Homestuck Vol. 7: At the Price of Oblivion",
      "tags":[
         "Terezi",
         "Pyralspite"
      ],
      "youtubeId":"2pfcakhXhE8"
   },
   {
      "name":"Warhammer of Zillyhoo",
      "artist":[
         "Michael Guy Bowman"
      ],
      "albumName":"Homestuck Vol. 7: At the Price of Oblivion",
      "tags":[
         "John"
      ],
      "youtubeId":"0EVrOapzDvg"
   },
   {
      "name":"Scratch",
      "artist":[
         "David Ko"
      ],
      "albumName":"The Felt",
      "tags":[
         "Doc Scratch",
         "Felt Manor"
      ],
      "youtubeId":"3OVpWgisy0o"
   },
   {
      "name":"Firefly Cloud",
      "artist":[
         "Erik Scheele"
      ],
      "albumName":"One Year Older",
      "tags":[
         "John",
         "LoWaS"
      ],
      "youtubeId":"Y3u8E918EwM"
   },
   {
      "name":"Happy Cat Song!",
      "artist":[
         "Michael Guy Bowman",
         "Toby Fox"
      ],
      "albumName":"Homestuck Vol. 5",
      "tags":[
         "Rose",
         "Jaspers",
         "Earth"
      ],
      "youtubeId":"oLxeExkmGWs"
   },
   {
      "name":"Play The Wind",
      "artist":[
         "Kalibration"
      ],
      "albumName":"Homestuck Vol. 7: At the Price of Oblivion",
      "tags":[
         "John",
         "Jack Noir",
         "Battlefield"
      ],
      "youtubeId":"wR-NTkyJ0eg"
   },
   {
      "name":"Dissension (Original)",
      "artist":[
         "David Ko"
      ],
      "albumName":"Homestuck Vol. 3",
      "tags":[
         "Jade",
         "Jade\'s grandpa"
      ],
      "youtubeId":"az71j7AvBuQ"
   },
   {
      "name":"Cumulating Dreams",
      "artist":[
         "Solatrus"
      ],
      "albumName":"Homestuck Vol. 9",
      "tags":[
         "Jane",
         "Roxy",
         "Prospit"
      ],
      "youtubeId":"80ZicKue9yM"
   },
   {
      "name":"Mother",
      "artist":[
         "Erik Scheele"
      ],
      "albumName":"One Year Older",
      "tags":[
         "John",
         "Rose",
         "Dave",
         "Jade",
         "Battlefield",
         "LoWaS",
         "LoLaR",
         "LoHaC"
      ],
      "youtubeId":"jw2hUg_32PQ"
   },
   {
      "name":"Ante Matter",
      "artist":[
         "Mark Hadley"
      ],
      "albumName":"Midnight Crew: Drawing Dead",
      "tags":[
         "The Felt"
      ],
      "youtubeId":"2uYFtCSxoPk"
   },
   {
      "name":"Rest A While",
      "artist":[
         "Toby Fox"
      ],
      "albumName":"AlterniaBound",
      "tags":[
         "Karkat"
      ],
      "youtubeId":"Iq7clpsMTmA"
   },
   {
      "name":"Frost",
      "artist":[
         "Clark Powell"
      ],
      "albumName":"Medium",
      "tags":[
         "No tags"
      ],
      "youtubeId":"1Puozp2uaaM"
   },
   {
      "name":"Frogs",
      "artist":[
         "Clark Powell"
      ],
      "albumName":"Medium",
      "tags":[
         "No tags"
      ],
      "youtubeId":"PI785pDsR88"
   },
   {
      "name":"Consorts\' Intermezzo",
      "artist":[
         "Alex Rosetti"
      ],
      "albumName":"Genesis Frog",
      "tags":[
         "Consorts",
         "Frogs",
         "Genesis Frog"
      ],
      "youtubeId":"kV5rl3XTgAE"
   },
   {
      "name":"Harlequin",
      "artist":[
         "Mark Hadley"
      ],
      "albumName":"Homestuck Vol. 1",
      "tags":[
         "No tags"
      ],
      "youtubeId":"Y6LcnuowRc0"
   },
   {
      "name":"Catapult Capuchin",
      "artist":[
         "Toby Fox"
      ],
      "albumName":"AlterniaBound",
      "tags":[
         "Nepeta"
      ],
      "youtubeId":"zOLsLiqSAEo"
   },
   {
      "name":"Constant Conquest",
      "artist":[
         "Michael Guy Bowman"
      ],
      "albumName":"Cherubim",
      "tags":[
         "Caliborn",
         "Lil Cal"
      ],
      "youtubeId":"5WB4iUNkWQc"
   },
   {
      "name":"Bronze Rebel",
      "artist":[
         "Yan Rodriguez"
      ],
      "albumName":"coloUrs and mayhem: Universe A",
      "tags":[
         "The Summoner",
         "Tavros"
      ],
      "youtubeId":"5z4ctxDKCVM"
   },
   {
      "name":"Chain Of Prospit",
      "artist":[
         "Michael Guy Bowman"
      ],
      "albumName":"Mobius Trip and Hadron Kaleido",
      "tags":[
         "Mobius Trip",
         "Hadron Kaleido"
      ],
      "youtubeId":"c9XA_P5g_4w"
   },
   {
      "name":"Busting Makes Me Feel Good",
      "artist":[
         "Michael Guy Bowman"
      ],
      "albumName":"Homestuck Vol. 9",
      "tags":[
         "John",
         "Jade",
         "Davesprite"
      ],
      "youtubeId":"ObxMP2v6ke8"
   },
   {
      "name":"Lifdoff",
      "artist":[
         "Malcolm Brown"
      ],
      "albumName":"Homestuck Vol. 7: At the Price of Oblivion",
      "tags":[
         "John",
         "Skaia"
      ],
      "youtubeId":"8NvMi8GJKUM"
   },
   {
      "name":"Cascade",
      "artist":[
         "Toby Fox",
         "Tensei",
         "Clark Powell",
         "Malcolm Brown"
      ],
      "albumName":"Homestuck Vol. 8",
      "tags":[
         "No tags"
      ],
      "youtubeId":"Ckr6KyZoS58"
   },
   {
      "name":"FantasyP",
      "artist":[
         "Erik Scheele"
      ],
      "albumName":"One Year Older",
      "tags":[
         "John",
         "LoWaS"
      ],
      "youtubeId":"4Px7Yn9oIoM"
   },
   {
      "name":"GameBro (Original 1990 Mix)",
      "artist":[
         "Erik Scheele"
      ],
      "albumName":"Homestuck Vol. 6: Heir Transparent",
      "tags":[
         "No tags"
      ],
      "youtubeId":"k_-LPPdHEyA"
   },
   {
      "name":"Another Chance",
      "artist":[
         "Eston Schweickart"
      ],
      "albumName":"One Year Older",
      "tags":[
         "John"
      ],
      "youtubeId":"gfw3ALlYnmY"
   },
   {
      "name":"Austin, Atlantis",
      "artist":[
         "Clark Powell"
      ],
      "albumName":"Homestuck Vol. 9",
      "tags":[
         "Earth"
      ],
      "youtubeId":"wPfUy4j9V7c"
   },
   {
      "name":"Infinity Mechanism",
      "artist":[
         "Thomas Ferkol"
      ],
      "albumName":"Homestuck Vol. 8",
      "tags":[
         "LoHaC"
      ],
      "youtubeId":"I3S8LNG4aNo"
   },
   {
      "name":"Negative Aperture",
      "artist":[
         "Svix"
      ],
      "albumName":"Homestuck Vol. 9",
      "tags":[
         "Sawtooth",
         "Earth"
      ],
      "youtubeId":"x15fE5XGk1g"
   },
   {
      "name":"Gardener",
      "artist":[
         "Steve Everson"
      ],
      "albumName":"Homestuck Vol. 1-4",
      "tags":[
         "Jade"
      ],
      "youtubeId":"11XHicUomB8"
   },
   {
      "name":"Forever",
      "artist":[
         "Michael Guy Bowman"
      ],
      "albumName":"Mobius Trip and Hadron Kaleido",
      "tags":[
         "Mobius Trip"
      ],
      "youtubeId":"O9Q5JvfBMYc"
   },
   {
      "name":"Phaze and Blood",
      "artist":[
         "Toby Fox"
      ],
      "albumName":"Alternia",
      "tags":[
         "Karkat",
         "LoPaH"
      ],
      "youtubeId":"fNsqqoGNLpE"
   },
   {
      "name":"Underworld",
      "artist":[
         "Steve Everson"
      ],
      "albumName":"Homestuck Vol. 5",
      "tags":[
         "John",
         "Tumor"
      ],
      "youtubeId":"TzVrxUlBYOA"
   },
   {
      "name":"Variations",
      "artist":[
         "Robert J! Lake"
      ],
      "albumName":"The Felt",
      "tags":[
         "HB",
         "The Felt",
         "Felt Manor"
      ],
      "youtubeId":"M966V66lLuE"
   },
   {
      "name":"Pondsquatter (Live Chamber Version)",
      "artist":[
         "Alex Rosetti"
      ],
      "albumName":"Genesis Frog",
      "tags":[
         "No tags"
      ],
      "youtubeId":"l07w1i-C7hs"
   },
   {
      "name":"Cupcake Girl",
      "artist":[
         "Liam O\'Donnell"
      ],
      "albumName":"coloUrs and mayhem: Universe B",
      "tags":[
         "Jane"
      ],
      "youtubeId":"zvtTVc1s70A"
   },
   {
      "name":"Explore",
      "artist":[
         "Buzinkai",
         "Michael Guy Bowman"
      ],
      "albumName":"Homestuck Vol. 2",
      "tags":[
         "Ruined Earth"
      ],
      "youtubeId":"l03ijKa3NlA"
   },
   {
      "name":"Of Gods and Witches",
      "artist":[
         "Tensei"
      ],
      "albumName":"Homestuck Vol. 10",
      "tags":[
         "Jade",
         "Jadesprite",
         "Jack Noir",
         "Birds",
         "LoWaS",
         "LoLaR",
         "LoHaC",
         "LoFaF",
         "Battlefield",
         "Green Sun"
      ],
      "youtubeId":"a7qyOw3LuLE"
   },
   {
      "name":"Baroqueback Bowtier (Scratch\'s Lament)",
      "artist":[
         "Clark Powell"
      ],
      "albumName":"The Felt",
      "tags":[
         "The Felt",
         "Felt Manor"
      ],
      "youtubeId":"leIKuIH2FIg"
   },
   {
      "name":"Scourge Sisters",
      "artist":[
         "Erik Scheele"
      ],
      "albumName":"Homestuck Vol. 8",
      "tags":[
         "Terezi",
         "Vriska"
      ],
      "youtubeId":"srAkO5O1QaM"
   },
   {
      "name":"WSW-Beatdown",
      "artist":[
         "Erik Scheele"
      ],
      "albumName":"Homestuck Vol. 7: At the Price of Oblivion",
      "tags":[
         "Karkat",
         "Underlings",
         "LoPaH"
      ],
      "youtubeId":"-dusgXh-taQ"
   },
   {
      "name":"Requited",
      "artist":[
         "Clark Powell"
      ],
      "albumName":"Homestuck Vol. 9",
      "tags":[
         "The Disciple",
         "The Signless"
      ],
      "youtubeId":"k3QzERACr-Q"
   },
   {
      "name":"~~SIDE 1~~",
      "artist":[
         "Toby Fox"
      ],
      "albumName":"coloUrs and mayhem: Universe A",
      "tags":[
         "No tags"
      ],
      "youtubeId":"l9hqTE7FCu8"
   },
   {
      "name":"Sburban Jungle",
      "artist":[
         "Michael Guy Bowman"
      ],
      "albumName":"Homestuck Vol. 4",
      "tags":[
         "Rose",
         "Earth"
      ],
      "youtubeId":"gOSnen64a7M"
   },
   {
      "name":"Teal Seer",
      "artist":[
         "Kera Jones"
      ],
      "albumName":"coloUrs and mayhem: Universe A",
      "tags":[
         "Terezi",
         "Pyralspite",
         "Scalemates",
         "Alternia"
      ],
      "youtubeId":"Dwcn4q4X0OQ"
   },
   {
      "name":"Ecstasy",
      "artist":[
         "Steve Everson"
      ],
      "albumName":"Homestuck Vol. 5",
      "tags":[
         "John",
         "Rose",
         "Dave",
         "Jade",
         "Cats",
         "LoFaF",
         "LoLaR",
         "LoHaC",
         "LoWaS"
      ],
      "youtubeId":"JKBuMqKSu68"
   },
   {
      "name":"Center of Brilliance",
      "artist":[
         "Solatrus"
      ],
      "albumName":"Prospit & Derse",
      "tags":[
         "John",
         "Jade",
         "Prospit"
      ],
      "youtubeId":"514f8SzU62M"
   },
   {
      "name":"Softly",
      "artist":[
         "Robert J! Lake"
      ],
      "albumName":"Homestuck Vol. 5",
      "tags":[
         "Jade",
         "Becquerel",
         "Earth",
         "Frog Temple"
      ],
      "youtubeId":"h8AOosBD-fA"
   },
   {
      "name":"Verdancy (Bassline)",
      "artist":[
         "Kalibration"
      ],
      "albumName":"Homestuck Vol. 2",
      "tags":[
         "No tags"
      ],
      "youtubeId":"UudwG8_AoOQ"
   },
   {
      "name":"The Lyrist",
      "artist":[
         "Thomas Ferkol"
      ],
      "albumName":"Cherubim",
      "tags":[
         "Calliope"
      ],
      "youtubeId":"Tybj0RZoGks"
   },
   {
      "name":"Prospit Dreamers",
      "artist":[
         "Solatrus"
      ],
      "albumName":"Prospit & Derse",
      "tags":[
         "John",
         "Jade",
         "Prospit"
      ],
      "youtubeId":"S70lGd8zpRM"
   },
   {
      "name":"Pyrocumulus (Sicknasty)",
      "artist":[
         "Robert J! Lake"
      ],
      "albumName":"Homestuck Vol. 8",
      "tags":[
         "John",
         "Battlefield"
      ],
      "youtubeId":"4BqRC-ZPovM"
   },
   {
      "name":"Airtime",
      "artist":[
         "Robert J! Lake"
      ],
      "albumName":"Homestuck Vol. 8",
      "tags":[
         "No tags"
      ],
      "youtubeId":"HXPviQw2n7Q"
   },
   {
      "name":"White",
      "artist":[
         "Toby Fox"
      ],
      "albumName":"Homestuck Vol. 5",
      "tags":[
         "PM"
      ],
      "youtubeId":"AmsR9n_5xKk"
   },
   {
      "name":"Stride",
      "artist":[
         "Kalibration"
      ],
      "albumName":"Homestuck Vol. 10",
      "tags":[
         "Dave",
         "Dirk",
         "Karkat",
         "Meteor"
      ],
      "youtubeId":"h3n1ToY-UvA"
   },
   {
      "name":"Core of Darkness",
      "artist":[
         "Solatrus"
      ],
      "albumName":"Prospit & Derse",
      "tags":[
         "Rose",
         "Dave",
         "Derse"
      ],
      "youtubeId":"VEJ3hfCnW_w"
   },
   {
      "name":"Endless Climbing",
      "artist":[
         "Perry Sullivan"
      ],
      "albumName":"Homestuck Vol. 5",
      "tags":[
         "Rose",
         "LoLaR"
      ],
      "youtubeId":"zBh8V5EonSs"
   },
   {
      "name":"Conclude",
      "artist":[
         "Seth Peelle"
      ],
      "albumName":"Homestuck Vol. 10",
      "tags":[
         "John",
         "Earth"
      ],
      "youtubeId":"5Dq01x-oxWM"
   },
   {
      "name":"Breeding Duties",
      "artist":[
         "Alex Rosetti"
      ],
      "albumName":"Genesis Frog",
      "tags":[
         "Jade",
         "Frogs",
         "LoFaF"
      ],
      "youtubeId":"-BPCS9eHWFg"
   },
   {
      "name":"Cancerous Core",
      "artist":[
         "Erik Scheele"
      ],
      "albumName":"One Year Older",
      "tags":[
         "John",
         "Battlefield"
      ],
      "youtubeId":"hQcYa03LOkQ"
   },
   {
      "name":"Thip of the Tongue",
      "artist":[
         "Alex Rosetti"
      ],
      "albumName":"Genesis Frog",
      "tags":[
         "Consorts",
         "Birds",
         "LoFaF"
      ],
      "youtubeId":"AFPBfMdqKEU"
   },
   {
      "name":"Dogfight",
      "artist":[
         "Team Dogfight"
      ],
      "albumName":"Homestuck Vol. 9",
      "tags":[
         "PM"
      ],
      "youtubeId":"t7jg87kn5ps"
   },
   {
      "name":"Orchid Horror",
      "artist":[
         "David DeCou"
      ],
      "albumName":"coloUrs and mayhem: Universe B",
      "tags":[
         "Rose"
      ],
      "youtubeId":"ZOVhMJgdIX0"
   },
   {
      "name":"Flare",
      "artist":[
         "Clark Powell"
      ],
      "albumName":"Homestuck Vol. 8",
      "tags":[
         "Green Sun"
      ],
      "youtubeId":"ktuDYYtbs2s"
   },
   {
      "name":"Trollcops (Radio Play)",
      "artist":[
         "Erik Scheele"
      ],
      "albumName":"AlterniaBound",
      "tags":[
         "Aradia",
         "Alternia"
      ],
      "youtubeId":"fGQY8jLZa5w"
   },
   {
      "name":"Boy Skylark (Brief)",
      "artist":[
         "Robert J! Lake"
      ],
      "albumName":"Homestuck Vol. 6: Heir Transparent",
      "tags":[
         "John"
      ],
      "youtubeId":"p2cEangTsWY"
   },
   {
      "name":"Gold Mage",
      "artist":[
         "repeatedScales"
      ],
      "albumName":"coloUrs and mayhem: Universe A",
      "tags":[
         "Sollux"
      ],
      "youtubeId":"IY9mF06ZVFI"
   },
   {
      "name":"Time Paradox",
      "artist":[
         "Thomas Ferkol"
      ],
      "albumName":"The Felt",
      "tags":[
         "The Felt",
         "Felt Manor"
      ],
      "youtubeId":"96HRN6bFYqU"
   },
   {
      "name":"Ectobiology",
      "artist":[
         "David Ko"
      ],
      "albumName":"Homestuck Vol. 5",
      "tags":[
         "John",
         "Rose",
         "Jade",
         "Dave",
         "John\'s dad",
         "Cats",
         "Squiddles",
         "Rabbit",
         "Lil Cal"
      ],
      "youtubeId":"3dS73kM5EIE"
   },
   {
      "name":"Trollcops",
      "artist":[
         "Erik Scheele"
      ],
      "albumName":"AlterniaBound",
      "tags":[
         "Sollux",
         "Terezi",
         "Scalemates"
      ],
      "youtubeId":"agUl2xmrBuA"
   },
   {
      "name":"Before the Beginning and After the End",
      "artist":[
         "Robert J! Lake"
      ],
      "albumName":"Homestuck Vol. 9",
      "tags":[
         "Jane",
         "Roxy",
         "Dirk",
         "Jake"
      ],
      "youtubeId":"Nab_SoEy8rk"
   },
   {
      "name":"October",
      "artist":[
         "Erik Scheele"
      ],
      "albumName":"One Year Older",
      "tags":[
         "John",
         "Rose",
         "Dave",
         "Jade"
      ],
      "youtubeId":"wh0vuVouIG4"
   },
   {
      "name":"Galactic Cancer",
      "artist":[
         "Thomas Ferkol"
      ],
      "albumName":"Homestuck Vol. 8",
      "tags":[
         "Karkat",
         "John",
         "Rose",
         "Dave",
         "Jade",
         "Genesis Frog"
      ],
      "youtubeId":"ByBpRkrg9SA"
   },
   {
      "name":"Jade Dragon",
      "artist":[
         "Robert J! Lake"
      ],
      "albumName":"The Felt",
      "tags":[
         "Snowman",
         "Felt Manor"
      ],
      "youtubeId":"et0432j4c5s"
   },
   {
      "name":"Orange Hat",
      "artist":[
         "Plumegeist"
      ],
      "albumName":"coloUrs and mayhem: Universe B",
      "tags":[
         "Dirk"
      ],
      "youtubeId":"sMkNFjy8xcU"
   },
   {
      "name":"Our Glorious Speaker",
      "artist":[
         "Alex Rosetti"
      ],
      "albumName":"Genesis Frog",
      "tags":[
         "Carapacians",
         "Genesis Frog",
         "Green Sun"
      ],
      "youtubeId":"Xqf92y6W48M"
   },
   {
      "name":"Aggrieve (Violin Refrain)",
      "artist":[
         "Andrew Huo"
      ],
      "albumName":"Homestuck Vol. 1",
      "tags":[
         "Rose"
      ],
      "youtubeId":"h46wSFVeWAs"
   },
   {
      "name":"Kingside Castle",
      "artist":[
         "Clark Powell"
      ],
      "albumName":"Homestuck Vol. 8",
      "tags":[
         "Battlefield"
      ],
      "youtubeId":"qYKxzWbrZ0c"
   },
   {
      "name":"Homestuck",
      "artist":[
         "Mark Hadley"
      ],
      "albumName":"Homestuck Vol. 5",
      "tags":[
         "John",
         "Rose",
         "Dave",
         "Jade",
         "Squiddles",
         "Earth"
      ],
      "youtubeId":"FUwG73ATI7k"
   },
   {
      "name":"Squiddle Parade",
      "artist":[
         "Ian Taylor"
      ],
      "albumName":"Squiddles!",
      "tags":[
         "No tags"
      ],
      "youtubeId":"gqd8XTRwzoI"
   },
   {
      "name":"Penumbra Phantasm",
      "artist":[
         "Toby Fox"
      ],
      "albumName":"Homestuck Vol. 0",
      "tags":[
         "Gaster", "Frog Switch"
      ],
      "youtubeId":"sIgtSvgERB4"
   },
   {
      "name":"Harlequin (Rock Version)",
      "artist":[
         "Michael Guy Bowman"
      ],
      "albumName":"Homestuck Vol. 2",
      "tags":[
         "Underlings"
      ],
      "youtubeId":"g5TDTcMEL_c"
   },
   {
      "name":"Showtime (Original Mix)",
      "artist":[
         "Malcolm Brown"
      ],
      "albumName":"Homestuck Vol. 1",
      "tags":[
         "John\'s dad"
      ],
      "youtubeId":"RKURlfskGsE"
   },
   {
      "name":"Harleboss",
      "artist":[
         "Malcolm Brown"
      ],
      "albumName":"Homestuck Vol. 3",
      "tags":[
         "John"
      ],
      "youtubeId":"51vrPLt_LG8"
   },
   {
      "name":"Pink Shells",
      "artist":[
         "Alex Rosetti"
      ],
      "albumName":"Genesis Frog",
      "tags":[
         "Consorts",
         "LoLaR"
      ],
      "youtubeId":"CReFCSa8SFc"
   },
   {
      "name":"Crustacean",
      "artist":[
         "Toby Fox"
      ],
      "albumName":"Alternia",
      "tags":[
         "Karkat",
         "Alternia"
      ],
      "youtubeId":"U5CGEwHZoss"
   },
   {
      "name":"Galaxy Hearts",
      "artist":[
         "Mark Hadley"
      ],
      "albumName":"Homestuck Vol. 8",
      "tags":[
         "Terezi"
      ],
      "youtubeId":"YQX0TWA_x6Q"
   },
   {
      "name":"Lotus Land Story",
      "artist":[
         "Toby Fox"
      ],
      "albumName":"Homestuck Vol. 5",
      "tags":[
         "Jade",
         "Lotus"
      ],
      "youtubeId":"O7qf5KhmM-I"
   },
   {
      "name":"Lilith in Starlight",
      "artist":[
         "Malcolm Brown"
      ],
      "albumName":"Homestuck Vol. 10",
      "tags":[
         "Rose",
         "Kanaya",
         "Meteor"
      ],
      "youtubeId":"9ZRH3pMAsEw"
   },
   {
      "name":"Get Up",
      "artist":[
         "Toby Fox"
      ],
      "albumName":"Homestuck Vol. 5",
      "tags":[
         "John"
      ],
      "youtubeId":"RG-gwMm7vcg"
   },
   {
      "name":"Green Lolly",
      "artist":[
         "Robert J! Lake"
      ],
      "albumName":"Cherubim",
      "tags":[
         "Calliope"
      ],
      "youtubeId":"oWC2oXU_hP4"
   },
   {
      "name":"Cyan Beast",
      "artist":[
         "David Ellis"
      ],
      "albumName":"coloUrs and mayhem: Universe B",
      "tags":[
         "Jane",
         "God Cat",
         "Rabbit"
      ],
      "youtubeId":"qhP_QTvAeQg"
   },
   {
      "name":"Endless Expanse",
      "artist":[
         "Erik Scheele"
      ],
      "albumName":"The Wanderers",
      "tags":[
         "WV",
         "PM",
         "AR",
         "Ruined Earth"
      ],
      "youtubeId":"TktueKyYBKs"
   },
   {
      "name":"Light",
      "artist":[
         "Clark Powell"
      ],
      "albumName":"Medium",
      "tags":[
         "No tags"
      ],
      "youtubeId":"WEVS8j6bcvI"
   },
   {
      "name":"Made of Time",
      "artist":[
         "James Roach"
      ],
      "albumName":"coloUrs and mayhem: Universe A",
      "tags":[
         "Aradia",
         "Jack Noir",
         "Green Sun"
      ],
      "youtubeId":"MMwFJp_e3BQ"
   },
   {
      "name":"Theme",
      "artist":[
         "Toby Fox"
      ],
      "albumName":"Alternia",
      "tags":[
         "Aradia",
         "Alternia"
      ],
      "youtubeId":"S2Pr-_j0qcc"
   },
   {
      "name":"Dance of Thorns",
      "artist":[
         "Tensei"
      ],
      "albumName":"Strife!",
      "tags":[
         "Rose"
      ],
      "youtubeId":"3dKRKDn9ZJk"
   },
   {
      "name":"~~ADDITIONAL MAYHEM~~",
      "artist":[
         "Toby Fox"
      ],
      "albumName":"coloUrs and mayhem: Universe A",
      "tags":[
         "No tags"
      ],
      "youtubeId":"_cVhbp4Z2sI"
   },
   {
      "name":"Chorale for War",
      "artist":[
         "Steve Everson"
      ],
      "albumName":"Homestuck Vol. 5",
      "tags":[
         "Dave",
         "Jade",
         "LoFaF"
      ],
      "youtubeId":"5nzzYzdm8sI"
   },
   {
      "name":"You Killed My Father (Prepare To Die)",
      "artist":[
         "Team Dogfight"
      ],
      "albumName":"Homestuck Vol. 10",
      "tags":[
         "John",
         "Jack Noir",
         "Dream Bubbles"
      ],
      "youtubeId":"jJ7XGZ04Fgc"
   },
   {
      "name":"Frogs\' Intermezzo",
      "artist":[
         "Alex Rosetti"
      ],
      "albumName":"Genesis Frog",
      "tags":[
         "Consorts",
         "Frogs",
         "Genesis Frog"
      ],
      "youtubeId":"lDGQ_3qgByU"
   },
   {
      "name":"Jade Mother",
      "artist":[
         "Nathan H."
      ],
      "albumName":"coloUrs and mayhem: Universe A",
      "tags":[
         "The Dolorosa",
         "The Signless"
      ],
      "youtubeId":"OKQqQ3152QQ"
   },
   {
      "name":"Prospitian Folklore",
      "artist":[
         "Alex Rosetti"
      ],
      "albumName":"Genesis Frog",
      "tags":[
         "Carapacians",
         "Battlefield",
         "Prospit",
         "Frogs"
      ],
      "youtubeId":"QLEJynNmhpM"
   },
   {
      "name":"Hauntjelly",
      "artist":[
         "Andrew Huo",
         "Ian Taylor"
      ],
      "albumName":"Midnight Crew: Drawing Dead",
      "tags":[
         "The Felt",
         "Felt Manor"
      ],
      "youtubeId":"MccD13jZSTU"
   },
   {
      "name":"Litrichean Rioghail",
      "artist":[
         "Malcolm Brown"
      ],
      "albumName":"The Wanderers",
      "tags":[
         "PM"
      ],
      "youtubeId":"RtzNs4pH11Y"
   },
   {
      "name":"Cascade (Beta)",
      "artist":[
         "Tensei"
      ],
      "albumName":"Homestuck Vol. 8",
      "tags":[
         "John",
         "Dave",
         "Vriska",
         "Battlefield"
      ],
      "youtubeId":"Svy9DBBZKpk"
   },
   {
      "name":"Violet Prince",
      "artist":[
         "DJ\u6700\u30c6\u30fc"
      ],
      "albumName":"coloUrs and mayhem: Universe A",
      "tags":[
         "Eridan",
         "Angels",
         "LoWaA"
      ],
      "youtubeId":"o0W_22WQjZ0"
   },
   {
      "name":"Despot",
      "artist":[
         "Erik Scheele"
      ],
      "albumName":"Homestuck Vol. 9",
      "tags":[
         "Dirk",
         "Sawtooth",
         "Imperial Drones",
         "Earth"
      ],
      "youtubeId":"O7CI8okTWSg"
   },
   {
      "name":"Livin\' It Up",
      "artist":[
         "Gabe Nezovic"
      ],
      "albumName":"Midnight Crew: Drawing Dead",
      "tags":[
         "The Felt"
      ],
      "youtubeId":"HPad4cPmEVI"
   },
   {
      "name":"Frogsong",
      "artist":[
         "Alex Rosetti"
      ],
      "albumName":"Genesis Frog",
      "tags":[
         "No tags"
      ],
      "youtubeId":"xINZv3-S6xU"
   },
   {
      "name":"Great LOFAF Expedition of 2009",
      "artist":[
         "Alex Rosetti"
      ],
      "albumName":"Genesis Frog",
      "tags":[
         "Jade",
         "Frogs",
         "LoFaF"
      ],
      "youtubeId":"J161hcxSTkg"
   },
   {
      "name":"I - Overture",
      "artist":[
         "Clark Powell"
      ],
      "albumName":"Symphony Impossible to Play",
      "tags":[
         "No tags"
      ],
      "youtubeId":"Fi311fNF09w"
   },
   {
      "name":"Endless Climb",
      "artist":[
         "Buzinkai"
      ],
      "albumName":"Homestuck Vol. 4",
      "tags":[
         "Rose",
         "LoLaR"
      ],
      "youtubeId":"_cCc7xv6uCk"
   },
   {
      "name":"Rumble at the Rink",
      "artist":[
         "Toby Fox"
      ],
      "albumName":"Homestuck Vol. 7: At the Price of Oblivion",
      "tags":[
         "Eridan",
         "Gamzee",
         "Equius"
      ],
      "youtubeId":"Mpyny6XTDY8"
   },
   {
      "name":"Upward Movement (Dave Owns)",
      "artist":[
         "Kalibration"
      ],
      "albumName":"Homestuck Vol. 2",
      "tags":[
         "Dave",
         "Lil Cal"
      ],
      "youtubeId":"j6UiUu_7LVc"
   },
   {
      "name":"Pendulum",
      "artist":[
         "Shandy"
      ],
      "albumName":"coloUrs and mayhem: Universe B",
      "tags":[
         "Dave"
      ],
      "youtubeId":"Mv0zF9BRfOI"
   },
   {
      "name":"Serenade",
      "artist":[
         "Clark Powell"
      ],
      "albumName":"Homestuck Vol. 8",
      "tags":[
         "Nepeta",
         "Karkat",
         "Meteor"
      ],
      "youtubeId":"iQyr0eYN7hI"
   },
   {
      "name":"Chartreuse Rewind",
      "artist":[
         "Solatrus"
      ],
      "albumName":"The Felt",
      "tags":[
         "Jack Noir",
         "The Felt",
         "Felt Manor"
      ],
      "youtubeId":"TBsDTu-NOkA"
   },
   {
      "name":"Vertical Motion",
      "artist":[
         "Toby Fox"
      ],
      "albumName":"Homestuck Vol. 5",
      "tags":[
         "Rose",
         "Gate",
         "LoLaR"
      ],
      "youtubeId":"RCMpwY8pirw"
   },
   {
      "name":"Skaian Happy Flight",
      "artist":[
         "Seth Peelle"
      ],
      "albumName":"Homestuck Vol. 10",
      "tags":[
         "John",
         "AH",
         "Battlefield"
      ],
      "youtubeId":"rozLPCPXopw"
   },
   {
      "name":"Squiddles the Movie Trailer - The Day the Unicorns Couldn\'t Play",
      "artist":[
         "Toby Fox"
      ],
      "albumName":"Squiddles!",
      "tags":[
         "No tags"
      ],
      "youtubeId":"9lWRGynBNVs"
   },
   {
      "name":"Beatdown (Strider Style)",
      "artist":[
         "Curt Blakeslee"
      ],
      "albumName":"Homestuck Vol. 3",
      "tags":[
         "Dave",
         "Lil Cal",
         "Earth"
      ],
      "youtubeId":"laPOcRplQrw"
   },
   {
      "name":"IV - Anthem",
      "artist":[
         "Clark Powell"
      ],
      "albumName":"Symphony Impossible to Play",
      "tags":[
         "No tags"
      ],
      "youtubeId":"FaT8T3030mA"
   },
   {
      "name":"Coolkid",
      "artist":[
         "Haunter"
      ],
      "albumName":"coloUrs and mayhem: Universe B",
      "tags":[
         "Dave"
      ],
      "youtubeId":"2el6ZUkK6YE"
   },
   {
      "name":"Darling Kanaya",
      "artist":[
         "Toby Fox"
      ],
      "albumName":"AlterniaBound",
      "tags":[
         "Kanaya"
      ],
      "youtubeId":"54YThzEi4bk"
   },
   {
      "name":"Bed of Rose\'s / Dreams of Derse",
      "artist":[
         "Mark Hadley"
      ],
      "albumName":"Homestuck Vol. 5",
      "tags":[
         "Rose",
         "Horrorterrors"
      ],
      "youtubeId":"D5_LohYeVjk"
   },
   {
      "name":"The Beginning of Something Really Excellent",
      "artist":[
         "Robert J! Lake"
      ],
      "albumName":"Homestuck Vol. 5",
      "tags":[
         "Jade",
         "Prospit"
      ],
      "youtubeId":"h23WFhv2Sms"
   },
   {
      "name":"Moonshine",
      "artist":[
         "Alex Rosetti"
      ],
      "albumName":"Midnight Crew: Drawing Dead",
      "tags":[
         "Felt Manor"
      ],
      "youtubeId":"W5p7sEFMYHE"
   },
   {
      "name":"Showtime Remix",
      "artist":[
         "Gabe Nezovic"
      ],
      "albumName":"Homestuck Vol. 2",
      "tags":[
         "No tags"
      ],
      "youtubeId":"VliU0X6XVpA"
   },
   {
      "name":"BL1ND JUST1C3 : 1NV3ST1G4T1ON !!",
      "artist":[
         "Malcolm Brown"
      ],
      "albumName":"AlterniaBound",
      "tags":[
         "Terezi",
         "Vriska"
      ],
      "youtubeId":"yg41u9AejSs"
   },
   {
      "name":"Skies of Skaia",
      "artist":[
         "Mark Hadley"
      ],
      "albumName":"Homestuck Vol. 1",
      "tags":[
         "Skaia"
      ],
      "youtubeId":"vwzfyi4OdkU"
   },
   {
      "name":"White Host, Green Room",
      "artist":[
         "Tensei"
      ],
      "albumName":"Homestuck Vol. 7: At the Price of Oblivion",
      "tags":[
         "Doc Scratch"
      ],
      "youtubeId":"oL3r83dPcMs"
   },
   {
      "name":"Even in Death",
      "artist":[
         "Clark Powell"
      ],
      "albumName":"Homestuck Vol. 7: At the Price of Oblivion",
      "tags":[
         "Rose",
         "LoLaR"
      ],
      "youtubeId":"T86uquWB9Mw"
   },
   {
      "name":"The Deeper You Go",
      "artist":[
         "Michael Guy Bowman"
      ],
      "albumName":"Mobius Trip and Hadron Kaleido",
      "tags":[
         "Mobius Trip"
      ],
      "youtubeId":"IMpM4xKg9vU"
   },
   {
      "name":"Hallowed Halls",
      "artist":[
         "Solatrus"
      ],
      "albumName":"Prospit & Derse",
      "tags":[
         "Prospit"
      ],
      "youtubeId":"ImNmotu8-pU"
   },
   {
      "name":"Bargaining with the Beast",
      "artist":[
         "Thomas Ferkol"
      ],
      "albumName":"Homestuck Vol. 8",
      "tags":[
         "Jade",
         "Denizens",
         "LoFaF"
      ],
      "youtubeId":"2HT5FcjHt1s"
   },
   {
      "name":"Even in Death (T\'Morra\'s Belly Mix)",
      "artist":[
         "Clark Powell",
         "Tavia Morra"
      ],
      "albumName":"Homestuck Vol. 8",
      "tags":[
         "No tags"
      ],
      "youtubeId":"9MdltybiL00"
   },
   {
      "name":"Carapacian Dominion",
      "artist":[
         "Seth Peelle"
      ],
      "albumName":"The Wanderers",
      "tags":[
         "WV",
         "PM",
         "AR",
         "WQ",
         "Serenity"
      ],
      "youtubeId":"bhG6zJpC60c"
   },
   {
      "name":"What a Daring Dream",
      "artist":[
         "Alex Rosetti"
      ],
      "albumName":"The Wanderers",
      "tags":[
         "WV",
         "Serenity"
      ],
      "youtubeId":"qtt0o-v2yco"
   },
   {
      "name":"Aggrieve (Violin Redux)",
      "artist":[
         "Andrew Huo"
      ],
      "albumName":"Homestuck Vol. 1",
      "tags":[
         "Rose"
      ],
      "youtubeId":"K0gpSYelM_k"
   },
   {
      "name":"Light",
      "artist":[
         "Erik Scheele"
      ],
      "albumName":"Homestuck Vol. 5",
      "tags":[
         "John",
         "Rose",
         "Dave",
         "Jade",
         "Earth"
      ],
      "youtubeId":"dmJDWAQSQBs"
   },
   {
      "name":"Dawn of Man",
      "artist":[
         "Michael Guy Bowman"
      ],
      "albumName":"Mobius Trip and Hadron Kaleido",
      "tags":[
         "No tags"
      ],
      "youtubeId":"Fyib6VCDA2s"
   },
   {
      "name":"Aggrievocation",
      "artist":[
         "Mark Hadley"
      ],
      "albumName":"Homestuck Vol. 10",
      "tags":[
         "Rose"
      ],
      "youtubeId":"JB3kKp7jRag"
   },
   {
      "name":"Heat",
      "artist":[
         "Clark Powell"
      ],
      "albumName":"Medium",
      "tags":[
         "No tags"
      ],
      "youtubeId":"l_ITpDhYvaI"
   },
   {
      "name":"Sunrise",
      "artist":[
         "Erik Scheele"
      ],
      "albumName":"One Year Older",
      "tags":[
         "No tags"
      ],
      "youtubeId":"AmW_GOMIimo"
   },
   {
      "name":"Nautical Nightmare",
      "artist":[
         "Toby Fox"
      ],
      "albumName":"AlterniaBound",
      "tags":[
         "Sollux",
         "Eridan"
      ],
      "youtubeId":"K5aZ0PdrT3U"
   },
   {
      "name":"Skaian Flight",
      "artist":[
         "Kalibration"
      ],
      "albumName":"Homestuck Vol. 5",
      "tags":[
         "John",
         "Skaia",
         "Gate"
      ],
      "youtubeId":"1pXdGOSIvEs"
   },
   {
      "name":"Iron Infidel",
      "artist":[
         "Samm Neiland"
      ],
      "albumName":"coloUrs and mayhem: Universe A",
      "tags":[
         "The Signless",
         "Alternia"
      ],
      "youtubeId":"pIplxQdLgA4"
   },
   {
      "name":"Skaian Ride",
      "artist":[
         "Seth Peelle",
         "Andrew Huo"
      ],
      "albumName":"Homestuck Vol. 5",
      "tags":[
         "Jane",
         "Jake",
         "Roxy",
         "Dirk",
         "Prospit",
         "Skaia",
         "Earth"
      ],
      "youtubeId":"tD_AfVeEyu4"
   },
   {
      "name":"Keepers",
      "artist":[
         "Toby Fox"
      ],
      "albumName":"Alternia",
      "tags":[
         "Eridan",
         "Feferi",
         "Feferi\'s lusus",
         "Alternia"
      ],
      "youtubeId":"Sy3Au5UsykI"
   },
   {
      "name":"Vagabounce",
      "artist":[
         "David Ko"
      ],
      "albumName":"Homestuck Vol. 2",
      "tags":[
         "WV"
      ],
      "youtubeId":"GFYnHrqW6LM"
   },
   {
      "name":"Train",
      "artist":[
         "Buzinkai"
      ],
      "albumName":"Homestuck Vol. 10",
      "tags":[
         "John",
         "Roxy",
         "Terezi"
      ],
      "youtubeId":"vhzgtJcLouU"
   },
   {
      "name":"Carbon Nadsat / Cuestick Genius",
      "artist":[
         "Robert J! Lake"
      ],
      "albumName":"Midnight Crew: Drawing Dead",
      "tags":[
         "DD"
      ],
      "youtubeId":"g5jyNbOePJ8"
   },
   {
      "name":"The Changing Game",
      "artist":[
         "Thomas Ferkol"
      ],
      "albumName":"Homestuck Vol. 9",
      "tags":[
         "Earth"
      ],
      "youtubeId":"a7UeOYYKrxg"
   },
   {
      "name":"Clockwork Melody",
      "artist":[
         "Clark Powell"
      ],
      "albumName":"Homestuck Vol. 5",
      "tags":[
         "Dave",
         "Lil Cal"
      ],
      "youtubeId":"0s4JjBz-pr4"
   },
   {
      "name":"Entrance of the Salamanders",
      "artist":[
         "Alex Rosetti"
      ],
      "albumName":"Genesis Frog",
      "tags":[
         "Consorts",
         "LoWaS"
      ],
      "youtubeId":"kU55FMWbmaQ"
   },
   {
      "name":"Dead Shuffle",
      "artist":[
         "Mark Hadley"
      ],
      "albumName":"Midnight Crew: Drawing Dead",
      "tags":[
         "Felt Manor"
      ],
      "youtubeId":"jTIQhTdy4ZU"
   },
   {
      "name":"Mister Bowman Tells You About the Squiddles",
      "artist":[
         "Michael Guy Bowman"
      ],
      "albumName":"Squiddles!",
      "tags":[
         "No tags"
      ],
      "youtubeId":"09eVHqwHWYM"
   },
   {
      "name":"I\'m a Member of the Midnight Crew (Acapella)",
      "artist":[
         "David Ko"
      ],
      "albumName":"Homestuck Vol. 8",
      "tags":[
         "Jack Noir",
         "DD",
         "CD",
         "HB"
      ],
      "youtubeId":"Br294PLbHBA"
   },
   {
      "name":"Biophosphoradelecrystalluminescence",
      "artist":[
         "Robert J! Lake"
      ],
      "albumName":"Homestuck Vol. 5",
      "tags":[
         "Jade"
      ],
      "youtubeId":"R6H0bLgRNpo"
   },
   {
      "name":"Squidissension",
      "artist":[
         "Mark Hadley"
      ],
      "albumName":"Homestuck Vol. 6: Heir Transparent",
      "tags":[
         "Jade",
         "Squiddles",
         "Dream Bubbles"
      ],
      "youtubeId":"zJ7y3sWT7l0"
   },
   {
      "name":"Voidlight",
      "artist":[
         "Thomas Ferkol"
      ],
      "albumName":"Homestuck Vol. 10",
      "tags":[
         "Green Sun"
      ],
      "youtubeId":"FOG0NbZnKzU"
   },
   {
      "name":"Descend",
      "artist":[
         "Toby Fox"
      ],
      "albumName":"Homestuck Vol. 5",
      "tags":[
         "John",
         "Jade",
         "Jack Noir",
         "Skaia"
      ],
      "youtubeId":"HE1JZZYUZYM"
   },
   {
      "name":"Lotus",
      "artist":[
         "Seth Peelle"
      ],
      "albumName":"Homestuck Vol. 5",
      "tags":[
         "Carapacians",
         "Lotus"
      ],
      "youtubeId":"yc32rRerfgc"
   },
   {
      "name":"Creata",
      "artist":[
         "Seth Peelle"
      ],
      "albumName":"Homestuck Vol. 10",
      "tags":[
         "Genesis Frog",
         "John",
         "Rose",
         "Dave",
         "Jade",
         "Dream Bubbles"
      ],
      "youtubeId":"lXmiKt-rBjo"
   },
   {
      "name":"I Don\'t Want to Miss a Thing",
      "artist":[
         "Michael Guy Bowman"
      ],
      "albumName":"Homestuck Vol. 6: Heir Transparent",
      "tags":[
         "John"
      ],
      "youtubeId":"383DEqnj71w"
   },
   {
      "name":"Trails",
      "artist":[
         "Mark Hadley"
      ],
      "albumName":"The Felt",
      "tags":[
         "Felt Manor"
      ],
      "youtubeId":"rzL0-oyDy7s"
   },
   {
      "name":"Nic Cage Song",
      "artist":[
         "Toby Fox"
      ],
      "albumName":"Homestuck Vol. 6: Heir Transparent",
      "tags":[
         "Vriska"
      ],
      "youtubeId":"dxVXLeX9FXY"
   },
   {
      "name":"Castle",
      "artist":[
         "Buzinkai"
      ],
      "albumName":"Homestuck Vol. 10",
      "tags":[
         "Karkat",
         "Terezi",
         "Vriska",
         "WV",
         "Dream Bubbles"
      ],
      "youtubeId":"DMWGKi9FqhA"
   },
   {
      "name":"Squiddles!",
      "artist":[
         "Alex Rosetti"
      ],
      "albumName":"Squiddles!",
      "tags":[
         "No tags"
      ],
      "youtubeId":"fXo6q-jENfE"
   },
   {
      "name":"Carefree Victory",
      "artist":[
         "Andrew Huo",
         "Toby Fox"
      ],
      "albumName":"Homestuck Vol. 4",
      "tags":[
         "Jade",
         "Becquerel",
         "Frog Temple",
         "Earth"
      ],
      "youtubeId":"2z1Rp2UynQE"
   },
   {
      "name":"Hardlyquin",
      "artist":[
         "Mark Hadley"
      ],
      "albumName":"Homestuck Vol. 4",
      "tags":[
         "John"
      ],
      "youtubeId":"CN04ruWv30g"
   },
   {
      "name":"Lancer",
      "artist":[
         "Clark Powell"
      ],
      "albumName":"Homestuck Vol. 9",
      "tags":[
         "DD"
      ],
      "youtubeId":"C1L6GAcqAVE"
   },
   {
      "name":"The Obsidian Towers",
      "artist":[
         "Solatrus"
      ],
      "albumName":"Prospit & Derse",
      "tags":[
         "Rose",
         "Dave",
         "Derse"
      ],
      "youtubeId":"IdaYlKyxfx4"
   },
   {
      "name":"Elevatorstuck",
      "artist":[
         "Tensei"
      ],
      "albumName":"Homestuck Vol. 6: Heir Transparent",
      "tags":[
         "Dave",
         "LoHaC"
      ],
      "youtubeId":"9namZe_rorQ"
   },
   {
      "name":"~~SIDE 2~~",
      "artist":[
         "Toby Fox"
      ],
      "albumName":"coloUrs and mayhem: Universe A",
      "tags":[
         "No tags"
      ],
      "youtubeId":"4yYe9Y0zJwE"
   },
   {
      "name":"Elephant Gun",
      "artist":[
         "Michael Guy Bowman"
      ],
      "albumName":"Homestuck Vol. 9",
      "tags":[
         "Jake",
         "Tavros\' lusus"
      ],
      "youtubeId":"60Qjl1iyZ4U"
   },
   {
      "name":"Unsheath\'d",
      "artist":[
         "Alex Rosetti"
      ],
      "albumName":"Homestuck Vol. 5",
      "tags":[
         "Dave",
         "Davesprite",
         "LoHaC"
      ],
      "youtubeId":"GBlD4tBCGmE"
   },
   {
      "name":"Gilded Sands",
      "artist":[
         "Nick Smalley",
         "Solatrus"
      ],
      "albumName":"The Wanderers",
      "tags":[
         "WV",
         "Serenity",
         "Ruined Earth"
      ],
      "youtubeId":"i5gsRSo3sMo"
   },
   {
      "name":"Thanks for Playing",
      "artist":[
         "Max Wright"
      ],
      "albumName":"Homestuck Vol. 10",
      "tags":[
         "John",
         "Rose",
         "Dave",
         "Jade",
         "Roxy",
         "Karkat",
         "Kanaya",
         "Terezi",
         "Calliope",
         "Earth"
      ],
      "youtubeId":"XsCBaAv59cc"
   },
   {
      "name":"Sun-Speckled Squiddly Afternoon",
      "artist":[
         "Alex Rosetti"
      ],
      "albumName":"Squiddles!",
      "tags":[
         "No tags"
      ],
      "youtubeId":"faRVzG1TZus"
   },
   {
      "name":"Carefree Action",
      "artist":[
         "Mark Hadley"
      ],
      "albumName":"Homestuck Vol. 8",
      "tags":[
         "Dave",
         "Jade",
         "Frogs",
         "LoFaF"
      ],
      "youtubeId":"saFRtTDSVKQ"
   },
   {
      "name":"Arcade Thunder",
      "artist":[
         "Robert J! Lake"
      ],
      "albumName":"Homestuck Vol. 8",
      "tags":[
         "John",
         "Rose",
         "Dave",
         "Jade",
         "Karkat",
         "Tavros",
         "Sollux",
         "Nepeta",
         "Terezi",
         "Vriska",
         "Jack Noir",
         "Consorts"
      ],
      "youtubeId":"2JJJTSs_JeA"
   },
   {
      "name":"Red Miles",
      "artist":[
         "James Dever"
      ],
      "albumName":"Homestuck Vol. 9",
      "tags":[
         "Earth"
      ],
      "youtubeId":"_7ulwTrNnTE"
   },
   {
      "name":"Breeze",
      "artist":[
         "Erik Scheele"
      ],
      "albumName":"Homestuck Vol. 10",
      "tags":[
         "John"
      ],
      "youtubeId":"h9E2e6JgIp0"
   },
   {
      "name":"Aimless Morning Gold",
      "artist":[
         "Michael Guy Bowman"
      ],
      "albumName":"The Wanderers",
      "tags":[
         "AR",
         "Serenity"
      ],
      "youtubeId":"KrimTgZi5uk"
   },
   {
      "name":"Black",
      "artist":[
         "Toby Fox"
      ],
      "albumName":"Homestuck Vol. 4",
      "tags":[
         "Jack Noir"
      ],
      "youtubeId":"5NACWZBDtN8"
   },
   {
      "name":"Doctor",
      "artist":[
         "Buzinkai",
         "Clark Powell"
      ],
      "albumName":"Homestuck Vol. 4",
      "tags":[
         "LoWaS"
      ],
      "youtubeId":"ve7TB0FggaY"
   },
   {
      "name":"Plague Doctor",
      "artist":[
         "Steve Everson"
      ],
      "albumName":"Homestuck Vol. 5",
      "tags":[
         "John",
         "Tumor",
         "Prospit",
         "Derse"
      ],
      "youtubeId":"SnVdIDSx5-I"
   },
   {
      "name":"The Thirteenth Hour",
      "artist":[
         "Toby Fox"
      ],
      "albumName":"Alternia",
      "tags":[
         "No tags"
      ],
      "youtubeId":"SixKHx18sqk"
   },
   {
      "name":"Dreamers and The Dead",
      "artist":[
         "Thomas Ferkol"
      ],
      "albumName":"AlterniaBound",
      "tags":[
         "Aradia",
         "Dream Bubbles"
      ],
      "youtubeId":"9PlP0RUzacQ"
   },
   {
      "name":"Ruins Rising",
      "artist":[
         "Michael Guy Bowman"
      ],
      "albumName":"The Wanderers",
      "tags":[
         "WV",
         "Jack Noir"
      ],
      "youtubeId":"pDYCL68d3wI"
   },
   {
      "name":"Three in the Morning (Aftermath)",
      "artist":[
         "Erik Scheele"
      ],
      "albumName":"Homestuck Vol. 9",
      "tags":[
         "Roxy",
         "Earth"
      ],
      "youtubeId":"nkRwwNcIkEw"
   },
   {
      "name":"Three\'s a Crowd",
      "artist":[
         "Robert J! Lake",
         "Thomas Ferkol"
      ],
      "albumName":"Homestuck Vol. 9",
      "tags":[
         "DD",
         "The Condesce"
      ],
      "youtubeId":"hBnCIpVQTls"
   },
   {
      "name":"Welcome to the New Extreme",
      "artist":[
         "Robert J! Lake"
      ],
      "albumName":"Homestuck Vol. 5",
      "tags":[
         "Rose",
         "Dave",
         "Lil Cal",
         "Birds",
         "Gate",
         "Derse"
      ],
      "youtubeId":"kWVQH0Z5u08"
   },
   {
      "name":"Planet Healer",
      "artist":[
         "Seth Peelle"
      ],
      "albumName":"Homestuck Vol. 5",
      "tags":[
         "John",
         "LoWaS"
      ],
      "youtubeId":"VQjHlYSM_jo"
   },
   {
      "name":"Superego",
      "artist":[
         "Yan Rodriguez"
      ],
      "albumName":"coloUrs and mayhem: Universe A",
      "tags":[
         "Vriska"
      ],
      "youtubeId":"fjmiQ3wujn4"
   },
   {
      "name":"Squiddle March",
      "artist":[
         "Erik Scheele"
      ],
      "albumName":"Squiddles!",
      "tags":[
         "No tags"
      ],
      "youtubeId":"6l7shCvDXUU"
   },
   {
      "name":"The La2t Frontiier",
      "artist":[
         "Toby Fox"
      ],
      "albumName":"Alternia",
      "tags":[
         "Sollux"
      ],
      "youtubeId":"aPd9mP7ZrlY"
   },
   {
      "name":"Skaian Summoning",
      "artist":[
         "Toby Fox"
      ],
      "albumName":"Alternia",
      "tags":[
         "Alternia"
      ],
      "youtubeId":"gWBlVTuyp0s"
   },
   {
      "name":"Judgment Day",
      "artist":[
         "Toby Fox"
      ],
      "albumName":"Homestuck Vol. 8",
      "tags":[
         "John"
      ],
      "youtubeId":"MD2TLTtyfDE"
   },
   {
      "name":"Beta Version",
      "artist":[
         "Michael Guy Bowman"
      ],
      "albumName":"Mobius Trip and Hadron Kaleido",
      "tags":[
         "Hadron Kaleido"
      ],
      "youtubeId":"m6Km35fIHiw"
   },
   {
      "name":"Liquid Negrocity",
      "artist":[
         "Toby Fox"
      ],
      "albumName":"Midnight Crew: Drawing Dead",
      "tags":[
         "Jack Noir",
         "DD",
         "CD",
         "HB"
      ],
      "youtubeId":"r8sBtl3WYZo"
   },
   {
      "name":"Spider\'s Claw",
      "artist":[
         "Toby Fox"
      ],
      "albumName":"Alternia",
      "tags":[
         "Vriska"
      ],
      "youtubeId":"ZA9M-ZpdlC8"
   },
   {
      "name":"Encore",
      "artist":[
         "Malcolm Brown"
      ],
      "albumName":"Homestuck Vol. 9",
      "tags":[
         "Jake",
         "Earth"
      ],
      "youtubeId":"d8R1N5h3Mzc"
   },
   {
      "name":"Walls Covered in Blood",
      "artist":[
         "Toby Fox"
      ],
      "albumName":"Alternia",
      "tags":[
         "Nepeta",
         "Nepeta\'s lusus",
         "Alternia"
      ],
      "youtubeId":"REM1E8wC3Gs"
   },
   {
      "name":"Terezi\'s Theme",
      "artist":[
         "Toby Fox"
      ],
      "albumName":"AlterniaBound",
      "tags":[
         "Terezi",
         "Vriska",
         "Scalemates"
      ],
      "youtubeId":"IIw4NDK7j4w"
   },
   {
      "name":"Your Universe",
      "artist":[
         "Marcus Carline"
      ],
      "albumName":"coloUrs and mayhem: Universe A",
      "tags":[
         "Snowman"
      ],
      "youtubeId":"iE1s52sqnpI"
   },
   {
      "name":"Frustracean",
      "artist":[
         "Toby Fox"
      ],
      "albumName":"Homestuck Vol. 9",
      "tags":[
         "No tags"
      ],
      "youtubeId":"om3VC6ru4yk"
   },
   {
      "name":"Trollian Standoff",
      "artist":[
         "Tensei"
      ],
      "albumName":"AlterniaBound",
      "tags":[
         "Vriska",
         "Eridan",
         "Gamzee",
         "Lil Cal"
      ],
      "youtubeId":"wbYnGg_QKt4"
   },
   {
      "name":"Portrait",
      "artist":[
         "Solatrus"
      ],
      "albumName":"Homestuck Vol. 9",
      "tags":[
         "Roxy"
      ],
      "youtubeId":"qkF5HxMd1LA"
   },
   {
      "name":"Beatdown Round 2",
      "artist":[
         "Curt Blakeslee"
      ],
      "albumName":"Homestuck Vol. 3",
      "tags":[
         "Dave",
         "Dave\'s bro"
      ],
      "youtubeId":"CuQpu45cmtw"
   },
   {
      "name":"Rust Servant",
      "artist":[
         "Willow Ascenzo"
      ],
      "albumName":"coloUrs and mayhem: Universe A",
      "tags":[
         "The Handmaid"
      ],
      "youtubeId":"ZbVCaNBeEpA"
   },
   {
      "name":"Respit",
      "artist":[
         "Erik Scheele"
      ],
      "albumName":"One Year Older",
      "tags":[
         "John",
         "Vriska",
         "Dave",
         "Jade",
         "Rose",
         "Karkat",
         "Dream Bubbles"
      ],
      "youtubeId":"9eSnvqGpzj8"
   },
   {
      "name":"Crystalanthemums",
      "artist":[
         "Alex Rosetti"
      ],
      "albumName":"Homestuck Vol. 5",
      "tags":[
         "Jade",
         "LoFaF"
      ],
      "youtubeId":"gNuqEgNlqnM"
   },
   {
      "name":"Negastrife",
      "artist":[
         "Erik Scheele"
      ],
      "albumName":"One Year Older",
      "tags":[
         "Jack Noir",
         "Lord English",
         "Green Sun"
      ],
      "youtubeId":"e5LP87QF9p0"
   },
   {
      "name":"Pondsquatter",
      "artist":[
         "Alex Rosetti"
      ],
      "albumName":"Genesis Frog",
      "tags":[
         "Genesis Frog",
         "Frogs",
         "Lotus",
         "LoWaS",
         "LoLaR",
         "LoHaC",
         "LoFaF",
         "Battlefield"
      ],
      "youtubeId":"tt9EOkbyv64"
   },
   {
      "name":"Dord Waltz",
      "artist":[
         "Elaine Wang"
      ],
      "albumName":"coloUrs and mayhem: Universe B",
      "tags":[
         "Roxy",
         "Dream Bubbles"
      ],
      "youtubeId":"e-fb2fUGNIE"
   },
   {
      "name":"Chaotic Strength",
      "artist":[
         "Thomas Ferkol"
      ],
      "albumName":"AlterniaBound",
      "tags":[
         "Equius",
         "Underlings"
      ],
      "youtubeId":"GUMeMEbJB0M"
   },
   {
      "name":"Squiddles in Paradise",
      "artist":[
         "Solatrus"
      ],
      "albumName":"Squiddles!",
      "tags":[
         "No tags"
      ],
      "youtubeId":"2YG_6ke8X0o"
   },
   {
      "name":"Fuchsia Ruler",
      "artist":[
         "Kezinox"
      ],
      "albumName":"coloUrs and mayhem: Universe A",
      "tags":[
         "The Condesce"
      ],
      "youtubeId":"AgOGQVszHbY"
   },
   {
      "name":"Mother (Piano)",
      "artist":[
         "Erik Scheele"
      ],
      "albumName":"One Year Older",
      "tags":[
         "No tags"
      ],
      "youtubeId":"utXsOEmFVds"
   },
   {
      "name":"Under The Hat",
      "artist":[
         "Erik Scheele"
      ],
      "albumName":"One Year Older",
      "tags":[
         "No tags"
      ],
      "youtubeId":"tBfUV056rjo"
   },
   {
      "name":"Tomahawk Head",
      "artist":[
         "Toby Fox",
         "Tensei"
      ],
      "albumName":"The Wanderers",
      "tags":[
         "AR",
         "Birds"
      ],
      "youtubeId":"vqs4yf_Fs4I"
   },
   {
      "name":"Havoc",
      "artist":[
         "Svix"
      ],
      "albumName":"Homestuck Vol. 8",
      "tags":[
         "The Handmaid",
         "Doc Scratch"
      ],
      "youtubeId":"Wq0SjupReyA"
   },
   {
      "name":"Eridan\'s Theme",
      "artist":[
         "Toby Fox"
      ],
      "albumName":"AlterniaBound",
      "tags":[
         "Eridan"
      ],
      "youtubeId":"mFLsoipuscM"
   },
   {
      "name":"Valhalla",
      "artist":[
         "Kalibration"
      ],
      "albumName":"Homestuck Vol. 5",
      "tags":[
         "Jack Noir",
         "The Felt",
         "Felt Manor"
      ],
      "youtubeId":"fPKk7ZAvbbk"
   },
   {
      "name":"Bridge of Stars",
      "artist":[
         "Thomas Ferkol"
      ],
      "albumName":"Homestuck Vol. 9",
      "tags":[
         "John",
         "Jade",
         "Davesprite"
      ],
      "youtubeId":"yQSb3LMPk0Q"
   },
   {
      "name":"Sburban Countdown",
      "artist":[
         "Mark Hadley"
      ],
      "albumName":"Homestuck Vol. 1",
      "tags":[
         "Earth"
      ],
      "youtubeId":"C3cvihYafCs"
   },
   {
      "name":"Pony Chorale",
      "artist":[
         "Michael Guy Bowman"
      ],
      "albumName":"Homestuck Vol. 3",
      "tags":[
         "John"
      ],
      "youtubeId":"MHsZHFb5FqU"
   },
   {
      "name":"Three in the Morning (RJ\'s I Can Barely Sleep In This Casino Remix)",
      "artist":[
         "Robert J! Lake"
      ],
      "albumName":"Homestuck Vol. 4",
      "tags":[
         "No tags"
      ],
      "youtubeId":"GACczxr7464"
   },
   {
      "name":"Make a Wish",
      "artist":[
         "Tensei"
      ],
      "albumName":"Strife!",
      "tags":[
         "No tags"
      ],
      "youtubeId":"P0nogEQf3BI"
   },
   {
      "name":"Savior of the Waking World",
      "artist":[
         "Toby Fox"
      ],
      "albumName":"Homestuck Vol. 5",
      "tags":[
         "John",
         "Gate"
      ],
      "youtubeId":"juQcAEkvzdQ"
   },
   {
      "name":"Crystalmethequins",
      "artist":[
         "Clark Powell",
         "Astro Kid"
      ],
      "albumName":"Homestuck Vol. 9",
      "tags":[
         "Aradia",
         "Vriska"
      ],
      "youtubeId":"r8rWfPPrKJ0"
   },
   {
      "name":"Jade Sylph",
      "artist":[
         "Frank Haught"
      ],
      "albumName":"coloUrs and mayhem: Universe A",
      "tags":[
         "Kanaya"
      ],
      "youtubeId":"oT-iHcyEjGQ"
   },
   {
      "name":"Carne Vale",
      "artist":[
         "Malcolm Brown"
      ],
      "albumName":"Cherubim",
      "tags":[
         "Caliborn",
         "Jane",
         "Roxy",
         "Dirk",
         "Jake"
      ],
      "youtubeId":"AdPU0emEeUg"
   },
   {
      "name":"Squiddles Happytime Fun Go!",
      "artist":[
         "Robert J! Lake"
      ],
      "albumName":"Squiddles!",
      "tags":[
         "No tags"
      ],
      "youtubeId":"uXBAl4pd7gA"
   },
   {
      "name":"Death of the Lusii",
      "artist":[
         "Toby Fox"
      ],
      "albumName":"Alternia",
      "tags":[
         "Gamzee",
         "Gamzee\'s lusus",
         "Alternia"
      ],
      "youtubeId":"mrflmgFRrYo"
   },
   {
      "name":"Ballad of Awakening",
      "artist":[
         "Malcolm Brown"
      ],
      "albumName":"Homestuck Vol. 4",
      "tags":[
         "Skaia",
         "Earth",
         "Prospit"
      ],
      "youtubeId":"ZmJ3Ex2jguE"
   },
   {
      "name":"Hate You",
      "artist":[
         "Toby Fox"
      ],
      "albumName":"Homestuck Vol. 9",
      "tags":[
         "Meenah"
      ],
      "youtubeId":"2gU_54ibyKM"
   },
   {
      "name":"Emerald Terror",
      "artist":[
         "Elisa McCabe"
      ],
      "albumName":"coloUrs and mayhem: Universe B",
      "tags":[
         "Jake",
         "Earth"
      ],
      "youtubeId":"CWDbNoGgGyQ"
   },
   {
      "name":"Joker\'s Wild",
      "artist":[
         "Mark Hadley"
      ],
      "albumName":"Midnight Crew: Drawing Dead",
      "tags":[
         "The Felt"
      ],
      "youtubeId":"MDaWJUfxFCM"
   },
   {
      "name":"Virgin Orb",
      "artist":[
         "Toby Fox"
      ],
      "albumName":"Alternia",
      "tags":[
         "Kanaya",
         "Matriorb"
      ],
      "youtubeId":"xHuyPJpvo4Y"
   },
   {
      "name":"Pumpkin Party in Sea Hitler\'s Water Apocalypse",
      "artist":[
         "Malcolm Brown"
      ],
      "albumName":"Homestuck Vol. 9",
      "tags":[
         "Roxy",
         "Carapacians",
         "Cats"
      ],
      "youtubeId":"LgG8lAVh-EA"
   },
   {
      "name":"Tentacles",
      "artist":[
         "Robert J! Lake"
      ],
      "albumName":"Squiddles!",
      "tags":[
         "No tags"
      ],
      "youtubeId":"LMfO0Z_E878"
   },
   {
      "name":"Awakening",
      "artist":[
         "Erik Scheele"
      ],
      "albumName":"Homestuck Vol. 7: At the Price of Oblivion",
      "tags":[
         "John",
         "Consorts",
         "LoHaC"
      ],
      "youtubeId":"ss0NXbwO_Xs"
   },
   {
      "name":"MeGaLoVania",
      "artist":[
         "Toby Fox"
      ],
      "albumName":"Homestuck Vol. 6: Heir Transparent",
      "tags":[
         "Vriska"
      ],
      "youtubeId":"xbUTyGfolX0"
   },
   {
      "name":"Octoroon Rangoon",
      "artist":[
         "Toby Fox"
      ],
      "albumName":"Homestuck Vol. 5",
      "tags":[
         "Jack Noir",
         "Horrorterrors"
      ],
      "youtubeId":"mkCprHAqw8w"
   },
   {
      "name":"Everything is Something to Somebody",
      "artist":[
         "Robert J! Lake"
      ],
      "albumName":"Homestuck Vol. 9",
      "tags":[
         "Roxy",
         "Carapacians",
         "Earth"
      ],
      "youtubeId":"reSPRXEtVmg"
   },
   {
      "name":"Gold Pilot",
      "artist":[
         "First Turn Fold"
      ],
      "albumName":"coloUrs and mayhem: Universe A",
      "tags":[
         "The Psiioniic",
         "The Condesce"
      ],
      "youtubeId":"QZqYLuCH62Q"
   },
   {
      "name":"Stress",
      "artist":[
         "Buzinkai",
         "Toby Fox"
      ],
      "albumName":"Homestuck Vol. 9",
      "tags":[
         "Dirk",
         "Imperial Drones",
         "SBaHJ"
      ],
      "youtubeId":"RZGdkT96JDs"
   },
   {
      "name":"Hauntjam",
      "artist":[
         "Andrew Huo",
         "Michael Guy Bowman"
      ],
      "albumName":"Midnight Crew: Drawing Dead",
      "tags":[
         "DD",
         "The Felt",
         "Felt Manor"
      ],
      "youtubeId":"K9s0vr15Gvw"
   },
   {
      "name":"Underfoot",
      "artist":[
         "Erik Scheele"
      ],
      "albumName":"One Year Older",
      "tags":[
         "Jade",
         "Battlefield"
      ],
      "youtubeId":"FO_x-VgcYAc"
   },
   {
      "name":"Aggrieve",
      "artist":[
         "Mark Hadley"
      ],
      "albumName":"Homestuck Vol. 1",
      "tags":[
         "Rose",
         "Maplehoof"
      ],
      "youtubeId":"kgZNDUF64L4"
   },
   {
      "name":"Showdown",
      "artist":[
         "Toby Fox"
      ],
      "albumName":"Alternia",
      "tags":[
         "Karkat"
      ],
      "youtubeId":"5mdkmTXDG2I"
   },
   {
      "name":"Enlightenment",
      "artist":[
         "Steve Everson"
      ],
      "albumName":"Homestuck Vol. 5",
      "tags":[
         "John",
         "Gate",
         "LoLaR"
      ],
      "youtubeId":"h0UVwLDYneI"
   },
   {
      "name":"Rediscover Fusion",
      "artist":[
         "BurnedKirby"
      ],
      "albumName":"Homestuck Vol. 3",
      "tags":[
         "No tags"
      ],
      "youtubeId":"5j7mzL-ctNo"
   },
   {
      "name":"Hardchorale",
      "artist":[
         "Alex Rosetti",
         "Toby Fox"
      ],
      "albumName":"Homestuck Vol. 5",
      "tags":[
         "Jaspers",
         "Cats"
      ],
      "youtubeId":"ldhG6Rx8OOE"
   },
   {
      "name":"Rhapsody in Green",
      "artist":[
         "Clark Powell"
      ],
      "albumName":"The Felt",
      "tags":[
         "The Felt",
         "Felt Manor"
      ],
      "youtubeId":"O4x3DKbvi1Y"
   },
   {
      "name":"Clockwork Sorrow",
      "artist":[
         "David Ko"
      ],
      "albumName":"Homestuck Vol. 5",
      "tags":[
         "Dave"
      ],
      "youtubeId":"fMn4Iehh2A4"
   },
   {
      "name":"Wacky Antics",
      "artist":[
         "David Ko",
         "Toby Fox"
      ],
      "albumName":"Homestuck Vol. 6: Heir Transparent",
      "tags":[
         "WV",
         "AR",
         "Serenity",
         "Ruined Earth"
      ],
      "youtubeId":"X7x3cKse9YI"
   },
   {
      "name":"We Walk",
      "artist":[
         "Steve Everson"
      ],
      "albumName":"The Wanderers",
      "tags":[
         "WV",
         "PM",
         "AR",
         "WQ",
         "Serenity",
         "Ruined Earth"
      ],
      "youtubeId":"0DILZ3Qi_Wg"
   },
   {
      "name":"Battle Brotocol",
      "artist":[
         "Elliot Colp"
      ],
      "albumName":"coloUrs and mayhem: Universe B",
      "tags":[
         "Dirk",
         "Squarewave",
         "Sawtooth"
      ],
      "youtubeId":"XeHvUfWSYS4"
   },
   {
      "name":"Friendship is Paramount",
      "artist":[
         "Seth Peelle"
      ],
      "albumName":"Squiddles!",
      "tags":[
         "No tags"
      ],
      "youtubeId":"X0DmGl7AJIU"
   },
   {
      "name":"Iron Knight",
      "artist":[
         "DJ\u6700\u30c6\u30fc"
      ],
      "albumName":"coloUrs and mayhem: Universe A",
      "tags":[
         "Karkat"
      ],
      "youtubeId":"Nv6hGW8cou4"
   },
   {
      "name":"Bilious",
      "artist":[
         "Alex Rosetti"
      ],
      "albumName":"Genesis Frog",
      "tags":[
         "Jack Noir",
         "Genesis Frog",
         "Green Sun"
      ],
      "youtubeId":"wyh3pBZZsaA"
   },
   {
      "name":"Squiddidle!",
      "artist":[
         "Malcolm Brown"
      ],
      "albumName":"Squiddles!",
      "tags":[
         "No tags"
      ],
      "youtubeId":"gyqSztKs_6o"
   },
   {
      "name":"Horschestra",
      "artist":[
         "Alex Rosetti"
      ],
      "albumName":"Homestuck Vol. 6: Heir Transparent",
      "tags":[
         "HB",
         "Horses"
      ],
      "youtubeId":"rm8exhakcIY"
   },
   {
      "name":"Battle Against an Unfathomable Enemy",
      "artist":[
         "Kalibration"
      ],
      "albumName":"Homestuck Vol. 9",
      "tags":[
         "Horrorterrors"
      ],
      "youtubeId":"xtCo--GAAnA"
   },
   {
      "name":"Expedition",
      "artist":[
         "Mark Hadley"
      ],
      "albumName":"Homestuck Vol. 9",
      "tags":[
         "Jake",
         "Earth"
      ],
      "youtubeId":"UjUpiHOtjWs"
   },
   {
      "name":"Phantasmagoric Waltz",
      "artist":[
         "Alex Rosetti"
      ],
      "albumName":"Homestuck Vol. 5",
      "tags":[
         "John",
         "Rose",
         "Dave",
         "Jade",
         "Prospit",
         "Derse"
      ],
      "youtubeId":"WnqZR9rzEJA"
   },
   {
      "name":"Nannaquin",
      "artist":[
         "Mark Hadley"
      ],
      "albumName":"Homestuck Vol. 1",
      "tags":[
         "John\'s nanna"
      ],
      "youtubeId":"Pf5YpbDgCSU"
   },
   {
      "name":"Ocean Stars",
      "artist":[
         "Mark Hadley"
      ],
      "albumName":"Squiddles!",
      "tags":[
         "No tags"
      ],
      "youtubeId":"gFWWC9vmSzQ"
   },
   {
      "name":"Heir Transparent",
      "artist":[
         "Robert J! Lake"
      ],
      "albumName":"Homestuck Vol. 6: Heir Transparent",
      "tags":[
         "John",
         "Skaia"
      ],
      "youtubeId":"bcUrTlpTKPU"
   },
   {
      "name":"Heirfare",
      "artist":[
         "Alex Rosetti"
      ],
      "albumName":"Homestuck Vol. 5",
      "tags":[
         "John",
         "Rose",
         "Davesprite",
         "Jadesprite",
         "Battlefield"
      ],
      "youtubeId":"P1_QCWUi2wQ"
   },
   {
      "name":"Umbral Ultimatum",
      "artist":[
         "Toby Fox"
      ],
      "albumName":"Homestuck Vol. 6: Heir Transparent",
      "tags":[
         "Jade"
      ],
      "youtubeId":"OEB1uh0T9UA"
   },
   {
      "name":"Noirscape",
      "artist":[
         "David Ellis"
      ],
      "albumName":"Homestuck Vol. 9",
      "tags":[
         "Jack Noir"
      ],
      "youtubeId":"dG5BjK4194A"
   },
   {
      "name":"Lunar Eclipse",
      "artist":[
         "Michael Guy Bowman",
         "Fenris"
      ],
      "albumName":"Midnight Crew: Drawing Dead",
      "tags":[
         "CD",
         "The Felt",
         "Felt Manor"
      ],
      "youtubeId":"06SVUSwu8Xk"
   },
   {
      "name":"Love You (Feferi\'s Theme)",
      "artist":[
         "Toby Fox"
      ],
      "albumName":"Homestuck Vol. 8",
      "tags":[
         "Feferi"
      ],
      "youtubeId":"cmXzwEixV4s"
   },
   {
      "name":"She\'s a Sp8der",
      "artist":[
         "Tensei"
      ],
      "albumName":"AlterniaBound",
      "tags":[
         "Mindfang"
      ],
      "youtubeId":"lqMGkbnWOQU"
   },
   {
      "name":"Freefall",
      "artist":[
         "Robert J! Lake"
      ],
      "albumName":"Homestuck Vol. 10",
      "tags":[
         "John",
         "Rose",
         "Dave",
         "Jade",
         "Jack Noir",
         "AR",
         "Snowman",
         "Genesis Frog",
         "Skaia",
         "Green Sun",
         "Ruined Earth"
      ],
      "youtubeId":"4pZP1Fkt9Mc"
   },
   {
      "name":"Drift into the Sun",
      "artist":[
         "Thomas Ferkol"
      ],
      "albumName":"Homestuck Vol. 8",
      "tags":[
         "Rose",
         "Dave",
         "Derse",
         "Horrorterrors"
      ],
      "youtubeId":"f5BP2W9a93U"
   },
   {
      "name":"Phantasmic Youngsters",
      "artist":[
         "Tristan Scroggins"
      ],
      "albumName":"coloUrs and mayhem: Universe B",
      "tags":[
         "Rose",
         "Jade"
      ],
      "youtubeId":"q4VTyykeKFw"
   },
   {
      "name":"Fuchsia Witch",
      "artist":[
         "David Dycus"
      ],
      "albumName":"coloUrs and mayhem: Universe A",
      "tags":[
         "Feferi"
      ],
      "youtubeId":"FApClQH9MrE"
   },
   {
      "name":"Davesprite",
      "artist":[
         "Toby Fox"
      ],
      "albumName":"Homestuck Vol. 8",
      "tags":[
         "Davesprite"
      ],
      "youtubeId":"Iy5fKp-aV4o"
   },
   {
      "name":"Sunslammer",
      "artist":[
         "Seth Peelle"
      ],
      "albumName":"Homestuck Vol. 5",
      "tags":[
         "Jade",
         "Underlings",
         "LoFaF",
         "LoHaC",
         "LoWaS"
      ],
      "youtubeId":"DJ5crJDYtDg"
   },
   {
      "name":"THIS Pumpkin",
      "artist":[
         "Alex Rosetti"
      ],
      "albumName":"Homestuck Vol. 10",
      "tags":[
         "No tags"
      ],
      "youtubeId":"ekWbliUNqfM"
   },
   {
      "name":"Skaian Shrapnel",
      "artist":[
         "Erik Scheele"
      ],
      "albumName":"One Year Older",
      "tags":[
         "Jack Noir",
         "Green Sun"
      ],
      "youtubeId":"NbWTEFgpxRg"
   },
   {
      "name":"Bec Noir",
      "artist":[
         "Will Kommor"
      ],
      "albumName":"coloUrs and mayhem: Universe B",
      "tags":[
         "Jack Noir"
      ],
      "youtubeId":"5V-RpYzKfx8"
   },
   {
      "name":"Skaian Skuffle",
      "artist":[
         "Andrew Huo"
      ],
      "albumName":"Homestuck Vol. 5",
      "tags":[
         "WV",
         "Jack Noir",
         "Carapacians",
         "Battlefield"
      ],
      "youtubeId":"P0Es-6hSihA"
   },
   {
      "name":"Apocryphal Antithesis",
      "artist":[
         "Clark Powell"
      ],
      "albumName":"The Felt",
      "tags":[
         "The Felt",
         "Felt Manor"
      ],
      "youtubeId":"PTd8rhHHyEs"
   },
   {
      "name":"How Do I Live (D8 Night Version)",
      "artist":[
         "Michael Guy Bowman"
      ],
      "albumName":"Homestuck Vol. 8",
      "tags":[
         "John",
         "LoWaS"
      ],
      "youtubeId":"Svy9DBBZKpk"
   },
   {
      "name":"Miasmajesty",
      "artist":[
         "Solatrus",
         "Nick Smalley"
      ],
      "albumName":"Homestuck Vol. 9",
      "tags":[
         "Dirk"
      ],
      "youtubeId":"cfzEta7PCkk"
   },
   {
      "name":"Pumpkin Cravings",
      "artist":[
         "Seth Peelle"
      ],
      "albumName":"Homestuck Vol. 5",
      "tags":[
         "John",
         "Rose",
         "Dave",
         "Jade"
      ],
      "youtubeId":"jnq_L-yTdxs"
   },
   {
      "name":"Prelude",
      "artist":[
         "Alex Rosetti"
      ],
      "albumName":"Genesis Frog",
      "tags":[
         "Consorts",
         "Frogs",
         "Genesis Frog"
      ],
      "youtubeId":"MEFq6t5hpxY"
   },
   {
      "name":"Song of Skaia",
      "artist":[
         "Mark Hadley"
      ],
      "albumName":"Song of Skaia",
      "tags":[
         "No tags"
      ],
      "youtubeId":"731LkYOajeA"
   },
   {
      "name":"Heir Conditioning",
      "artist":[
         "Tensei"
      ],
      "albumName":"Strife!",
      "tags":[
         "John"
      ],
      "youtubeId":"vwUc_apgdo0"
   },
   {
      "name":"Stellarum Salve",
      "artist":[
         "Robert J! Lake"
      ],
      "albumName":"Cherubim",
      "tags":[
         "Calliope",
         "Jane",
         "Roxy",
         "Dirk",
         "Jake"
      ],
      "youtubeId":"J2Vc2JIWPaY"
   },
   {
      "name":"At The Price of Oblivion",
      "artist":[
         "Malcolm Brown"
      ],
      "albumName":"Homestuck Vol. 7: At the Price of Oblivion",
      "tags":[
         "Rose"
      ],
      "youtubeId":"u--wSgeJUOs"
   },
   {
      "name":"Aggrievance",
      "artist":[
         "Mark Hadley"
      ],
      "albumName":"Homestuck Vol. 5",
      "tags":[
         "Rose",
         "Underlings",
         "LoLaR"
      ],
      "youtubeId":"RcI76YVobAI"
   },
   {
      "name":"Eternity, Served Cold (Canon Edit)",
      "artist":[
         "Malcolm Brown"
      ],
      "albumName":"[S] Collide.",
      "tags":[
         "Lord English",
         "Aradia",
         "Kanaya",
         "Eridan",
         "Equius",
         "Rufioh",
         "Mituna",
         "Kankri",
         "Latula",
         "Horuss",
         "Cronus"
      ],
      "youtubeId":"Ow8PeoCrfcg"
   },
   {
      "name":"Pale Rapture",
      "artist":[
         "The Black Curtain"
      ],
      "albumName":"coloUrs and mayhem: Universe B",
      "tags":[
         "John",
         "Skaia"
      ],
      "youtubeId":"iWnZhSjbPrs"
   },
   {
      "name":"Ohgodwhat Remix",
      "artist":[
         "Michael Guy Bowman"
      ],
      "albumName":"Homestuck Vol. 3",
      "tags":[
         "No tags"
      ],
      "youtubeId":"ZuaMHCPnqOc"
   },
   {
      "name":"Homefree",
      "artist":[
         "Hilary Troiano"
      ],
      "albumName":"Homestuck Vol. 8",
      "tags":[
         "John",
         "Skaia"
      ],
      "youtubeId":"7aYso7j8wsw"
   },
   {
      "name":"Eternity Served Cold",
      "artist":[
         "Malcolm Brown"
      ],
      "albumName":"Cherubim",
      "tags":[
         "Caliborn"
      ],
      "youtubeId":"wg9jows9Yfo"
   },
   {
      "name":"Homestuck Anthem",
      "artist":[
         "Clark Powell"
      ],
      "albumName":"Homestuck Vol. 5",
      "tags":[
         "John",
         "Rose",
         "Dave",
         "Jade"
      ],
      "youtubeId":"C-tawUqS2rc"
   },
   {
      "name":"The Vast Croak",
      "artist":[
         "Alex Rosetti"
      ],
      "albumName":"Genesis Frog",
      "tags":[
         "Genesis Frog",
         "Green Sun",
         "Skaia",
         "Prospit",
         "Derse"
      ],
      "youtubeId":"gW0CGMh6CNg"
   },
   {
      "name":"A Tender Moment",
      "artist":[
         "Toby Fox"
      ],
      "albumName":"Homestuck Vol. 6: Heir Transparent",
      "tags":[
         "Jade"
      ],
      "youtubeId":"hyP0FFPcL0c"
   },
   {
      "name":"Black Rose / Green Sun",
      "artist":[
         "Malcolm Brown"
      ],
      "albumName":"Homestuck Vol. 7: At the Price of Oblivion",
      "tags":[
         "Rose",
         "Horrorterrors",
         "Green Sun"
      ],
      "youtubeId":"XoFbJllomCo"
   },
   {
      "name":"Cobalt Corsair",
      "artist":[
         "Max Wright"
      ],
      "albumName":"coloUrs and mayhem: Universe A",
      "tags":[
         "Mindfang"
      ],
      "youtubeId":"FhFw9mhGf84"
   },
   {
      "name":"Sweet Dreams, Timaeus",
      "artist":[
         "Thomas Ferkol"
      ],
      "albumName":"Homestuck Vol. 9",
      "tags":[
         "Dirk"
      ],
      "youtubeId":"GhVCvz1sRdw"
   },
   {
      "name":"Pumpkin Tide",
      "artist":[
         "Michael Guy Bowman"
      ],
      "albumName":"Mobius Trip and Hadron Kaleido",
      "tags":[
         "Hadron Kaleido"
      ],
      "youtubeId":"lyxw3e3RvzE"
   },
   {
      "name":"Snow Pollen",
      "artist":[
         "Toby Fox"
      ],
      "albumName":"Homestuck Vol. 5",
      "tags":[
         "Jade",
         "LoFaF"
      ],
      "youtubeId":"xFTuVM3nACY"
   },
   {
      "name":"The Lost Child",
      "artist":[
         "Toby Fox"
      ],
      "albumName":"Homestuck Vol. 8",
      "tags":[
         "The Handmaid"
      ],
      "youtubeId":"-OFQHEy5TDw"
   },
   {
      "name":"Land of the Salamanders",
      "artist":[
         "David Ko"
      ],
      "albumName":"Homestuck Vol. 5",
      "tags":[
         "John",
         "Consorts",
         "LoWaS"
      ],
      "youtubeId":"bfKXw-kSnq8"
   },
   {
      "name":"Showtime (Piano Refrain)",
      "artist":[
         "Kevin Regamey"
      ],
      "albumName":"Homestuck Vol. 1",
      "tags":[
         "John"
      ],
      "youtubeId":"U5ggzIxfGDs"
   },
   {
      "name":"Firefly",
      "artist":[
         "Buzinkai"
      ],
      "albumName":"Homestuck Vol. 9",
      "tags":[
         "John",
         "LoWaS"
      ],
      "youtubeId":"KC9v0nOnDK8"
   },
   {
      "name":"Friendship Aneurysm",
      "artist":[
         "Alex Rosetti"
      ],
      "albumName":"Squiddles!",
      "tags":[
         "No tags"
      ],
      "youtubeId":"w6RNFIXfiyY"
   },
   {
      "name":"Olive Scribe",
      "artist":[
         "Bleed Binary"
      ],
      "albumName":"coloUrs and mayhem: Universe A",
      "tags":[
         "The Disciple"
      ],
      "youtubeId":"FpC98dlKX4s"
   },
   {
      "name":"Sarabande",
      "artist":[
         "Erik Scheele"
      ],
      "albumName":"Homestuck Vol. 5",
      "tags":[
         "Rose",
         "LoLaR"
      ],
      "youtubeId":"bkzYLa9iR6Q"
   },
   {
      "name":"Frost",
      "artist":[
         "Solatrus"
      ],
      "albumName":"Homestuck Vol. 6: Heir Transparent",
      "tags":[
         "Jade"
      ],
      "youtubeId":"57FwbcQ4LIA"
   },
   {
      "name":"I\'m a Member of the Midnight Crew (Post-Punk Version)",
      "artist":[
         "Michael Guy Bowman"
      ],
      "albumName":"Homestuck Vol. 9",
      "tags":[
         "Jack Noir",
         "DD",
         "CD",
         "HB"
      ],
      "youtubeId":"nU9YXh_0L4Y"
   },
   {
      "name":"Mayor Maynot",
      "artist":[
         "Toby Fox"
      ],
      "albumName":"The Wanderers",
      "tags":[
         "WV"
      ],
      "youtubeId":"d9KVN4WfHHc"
   },
   {
      "name":"Derse Dreamers",
      "artist":[
         "Solatrus"
      ],
      "albumName":"Prospit & Derse",
      "tags":[
         "Rose",
         "Dave",
         "Derse"
      ],
      "youtubeId":"HpmCCuSHmng"
   },
   {
      "name":"Nepeta\'s Theme",
      "artist":[
         "Toby Fox"
      ],
      "albumName":"AlterniaBound",
      "tags":[
         "Nepeta",
         "Aradia",
         "Equius",
         "Karkat",
         "Gamzee",
         "Nepeta\'s lusus",
         "Alternia"
      ],
      "youtubeId":"Q7ZUxyLyIao"
   },
   {
      "name":"The Ballad of Jack Noir",
      "artist":[
         "Toby Fox"
      ],
      "albumName":"Midnight Crew: Drawing Dead",
      "tags":[
         "Jack Noir",
         "Felt Manor"
      ],
      "youtubeId":"EAlt8IgOgPQ"
   },
   {
      "name":"Throwdown",
      "artist":[
         "Steve Everson"
      ],
      "albumName":"Homestuck Vol. 5",
      "tags":[
         "John",
         "Rose",
         "Dave",
         "Jade"
      ],
      "youtubeId":"pe-4Gsc6TMI"
   },
   {
      "name":"Speaker (Skaia\'s Reflection)",
      "artist":[
         "Alex Rosetti"
      ],
      "albumName":"Genesis Frog",
      "tags":[
         "Genesis Frog",
         "Battlefield",
         "LoWaS",
         "LoLaR",
         "LoHaC",
         "LoFaF"
      ],
      "youtubeId":"zKpqOvFTvLA"
   },
   {
      "name":"Versus",
      "artist":[
         "Toby Fox"
      ],
      "albumName":"Homestuck Vol. 5",
      "tags":[
         "Dave",
         "Dave\'s bro",
         "Lil Cal",
         "Birds",
         "Earth"
      ],
      "youtubeId":"d3JKaZpfJjE"
   },
   {
      "name":"Frog Hunt",
      "artist":[
         "Michael Guy Bowman"
      ],
      "albumName":"Homestuck Vol. 8",
      "tags":[
         "Dave",
         "Jade",
         "Frogs",
         "LoFaF"
      ],
      "youtubeId":"VkYB3Fw4bLw"
   },
   {
      "name":"A Fairy Battle",
      "artist":[
         "Toby Fox"
      ],
      "albumName":"AlterniaBound",
      "tags":[
         "Rose",
         "Kanaya",
         "Underlings",
         "Alternia"
      ],
      "youtubeId":"LHr417f4Y8U"
   },
   {
      "name":"Ohgodwhat",
      "artist":[
         "Nick Smalley"
      ],
      "albumName":"Homestuck Vol. 3",
      "tags":[
         "No tags"
      ],
      "youtubeId":"eX3QCmtmt4E"
   },
   {
      "name":"Greenhouse",
      "artist":[
         "Michael Guy Bowman"
      ],
      "albumName":"Homestuck Vol. 5",
      "tags":[
         "Jade",
         "Squiddles",
         "Earth"
      ],
      "youtubeId":"QZlsdtFRPWc"
   },
   {
      "name":"How Do I Live (Bunny Back in the Box Version)",
      "artist":[
         "Michael Guy Bowman"
      ],
      "albumName":"Homestuck Vol. 5",
      "tags":[
         "John",
         "Rose",
         "Jade",
         "Karkat",
         "Rabbit",
         "Consorts"
      ],
      "youtubeId":"gUHzNBepsJI"
   },
   {
      "name":"Catchyegrabber (Skipper Plumbthroat\'s Song)",
      "artist":[
         "Alex Rosetti"
      ],
      "albumName":"Squiddles!",
      "tags":[
         "No tags"
      ],
      "youtubeId":"S3bsomR8W_w"
   },
   {
      "name":"Upholding the Law",
      "artist":[
         "Alex Rosetti"
      ],
      "albumName":"Homestuck Vol. 5",
      "tags":[
         "AR",
         "Frog Temple",
         "Ruined Earth"
      ],
      "youtubeId":"xrKUOCR55sA"
   },
   {
      "name":"Minihoof\'s Adventure",
      "artist":[
         "Michael Guy Bowman"
      ],
      "albumName":"Homestuck Vol. 9",
      "tags":[
         "Maplehoof"
      ],
      "youtubeId":"KMyh7F9cyrE"
   },
   {
      "name":"Reverie",
      "artist":[
         "Alex Rosetti"
      ],
      "albumName":"Cherubim",
      "tags":[
         "Calliope",
         "Prospit",
         "Skaia"
      ],
      "youtubeId":"WkpYnDz-Y1w"
   },
   {
      "name":"Skaian Overdrive",
      "artist":[
         "Thomas Ferkol"
      ],
      "albumName":"Homestuck Vol. 10",
      "tags":[
         "Underlings",
         "BK",
         "Battlefield"
      ],
      "youtubeId":"_uhUEvkcTfY"
   },
   {
      "name":"Rain",
      "artist":[
         "Clark Powell"
      ],
      "albumName":"Medium",
      "tags":[
         "No tags"
      ],
      "youtubeId":"WWSphawicMY"
   },
   {
      "name":"Constant Confinement",
      "artist":[
         "Erik Scheele"
      ],
      "albumName":"Cherubim",
      "tags":[
         "Calliope"
      ],
      "youtubeId":"1pp7UpsuTa4"
   },
   {
      "name":"Another Jungle",
      "artist":[
         "Michael Guy Bowman"
      ],
      "albumName":"Homestuck Vol. 9",
      "tags":[
         "Roxy",
         "Dirk",
         "Earth"
      ],
      "youtubeId":"upRmDHMcREg"
   },
   {
      "name":"Coursing",
      "artist":[
         "Alex Rosetti"
      ],
      "albumName":"Homestuck Vol. 9",
      "tags":[
         "Becquerel",
         "God Cat",
         "Green Sun"
      ],
      "youtubeId":"yzfiSLL9vOo"
   },
   {
      "name":"GameGrl (Original 1993 Mix)",
      "artist":[
         "Michael Guy Bowman"
      ],
      "albumName":"Homestuck Vol. 9",
      "tags":[
         "No tags"
      ],
      "youtubeId":"4kdVpq3bnuY"
   },
   {
      "name":"Skaian Birth",
      "artist":[
         "Mark Hadley"
      ],
      "albumName":"Song of Skaia",
      "tags":[
         "No tags"
      ],
      "youtubeId":"pEtclU-Tdck"
   },
   {
      "name":"iRRRRRRRRECONCILA8LE",
      "artist":[
         "Michael Guy Bowman"
      ],
      "albumName":"Homestuck Vol. 9",
      "tags":[
         "Tavros",
         "Vriska"
      ],
      "youtubeId":"IyVUutdaP8E"
   },
   {
      "name":"The Broken Clock",
      "artist":[
         "Erik Scheele"
      ],
      "albumName":"The Felt",
      "tags":[
         "The Felt"
      ],
      "youtubeId":"Mmzhi4T6wtM"
   },
   {
      "name":"Arisen Anew",
      "artist":[
         "Tensei"
      ],
      "albumName":"AlterniaBound",
      "tags":[
         "Aradia",
         "Green Sun"
      ],
      "youtubeId":"C0BYVRNGoEo"
   },
   {
      "name":"Red Disc",
      "artist":[
         "Nathan H."
      ],
      "albumName":"coloUrs and mayhem: Universe B",
      "tags":[
         "Dave",
         "LoHaC"
      ],
      "youtubeId":"Y4lJcMpYGZk"
   },
   {
      "name":"Another Countdown",
      "artist":[
         "Michael Guy Bowman"
      ],
      "albumName":"Homestuck Vol. 9",
      "tags":[
         "Jane",
         "Earth"
      ],
      "youtubeId":"e71naCnOdwo"
   },
   {
      "name":"Purple Tyrant",
      "artist":[
         "Kevin Grant"
      ],
      "albumName":"coloUrs and mayhem: Universe A",
      "tags":[
         "The Grand Highblood"
      ],
      "youtubeId":"tcFOqqCWao8"
   },
   {
      "name":"Power Fantasy",
      "artist":[
         "Clark Powell"
      ],
      "albumName":"Cherubim",
      "tags":[
         "Caliborn",
         "Derse"
      ],
      "youtubeId":"5adGTghTECM"
   },
   {
      "name":"Ace of Trump",
      "artist":[
         "Hilary Troiano"
      ],
      "albumName":"Midnight Crew: Drawing Dead",
      "tags":[
         "The Felt"
      ],
      "youtubeId":"Leb48RHyrWs"
   },
   {
      "name":"The Golden Towers",
      "artist":[
         "Solatrus"
      ],
      "albumName":"Prospit & Derse",
      "tags":[
         "John",
         "Jade",
         "Prospit"
      ],
      "youtubeId":"wVFRxJpa2og"
   },
   {
      "name":"Sunsetter",
      "artist":[
         "Toby Fox"
      ],
      "albumName":"Homestuck Vol. 5",
      "tags":[
         "Dave",
         "Earth"
      ],
      "youtubeId":"DooIE-BKSGo"
   },
   {
      "name":"Havoc To Be Wrought",
      "artist":[
         "Thomas Ferkol"
      ],
      "albumName":"Homestuck Vol. 7: At the Price of Oblivion",
      "tags":[
         "Equius"
      ],
      "youtubeId":"E7pBfnN7b1w"
   },
   {
      "name":"Dupliblaze COMAGMA",
      "artist":[
         "Toby Fox"
      ],
      "albumName":"Homestuck Vol. 5",
      "tags":[
         "Dave",
         "LoHaC"
      ],
      "youtubeId":"YsG54QnsMLc"
   },
   {
      "name":"Vriska\'s Theme",
      "artist":[
         "Toby Fox"
      ],
      "albumName":"AlterniaBound",
      "tags":[
         "Vriska",
         "LoMaT"
      ],
      "youtubeId":"LkJHtDiNowk"
   },
   {
      "name":"Staring",
      "artist":[
         "Toby Fox"
      ],
      "albumName":"Alternia",
      "tags":[
         "Equius"
      ],
      "youtubeId":"4TUOfRlT1ks"
   },
   {
      "name":"No Release",
      "artist":[
         "Michael Guy Bowman"
      ],
      "albumName":"Mobius Trip and Hadron Kaleido",
      "tags":[
         "No tags"
      ],
      "youtubeId":"ZO_ile-Mdsw"
   },
   {
      "name":"Teal Hunter",
      "artist":[
         "Willow Ascenzo"
      ],
      "albumName":"coloUrs and mayhem: Universe A",
      "tags":[
         "Redglare"
      ],
      "youtubeId":"i_ehVzmj7dM"
   },
   {
      "name":"Darkened Streets",
      "artist":[
         "Solatrus"
      ],
      "albumName":"Prospit & Derse",
      "tags":[
         "Dave",
         "Rose",
         "Carapacians",
         "Derse"
      ],
      "youtubeId":"kb_v4KCml-s"
   },
   {
      "name":"Gaia Queen",
      "artist":[
         "Toby Fox"
      ],
      "albumName":"Homestuck Vol. 6: Heir Transparent",
      "tags":[
         "Jade",
         "Dave",
         "LoFaF"
      ],
      "youtubeId":"MU0eoyziRrU"
   },
   {
      "name":"English",
      "artist":[
         "Toby Fox"
      ],
      "albumName":"The Felt",
      "tags":[
         "DD",
         "The Felt",
         "Felt Manor"
      ],
      "youtubeId":"6zMnNCDaKuE"
   },
   {
      "name":"Aggrieve Remix",
      "artist":[
         "Gabe Nezovic"
      ],
      "albumName":"Homestuck Vol. 2",
      "tags":[
         "No tags"
      ],
      "youtubeId":"DpB5llhN2L0"
   },
   {
      "name":"Stormspirit",
      "artist":[
         "Tensei"
      ],
      "albumName":"Strife!",
      "tags":[
         "No tags"
      ],
      "youtubeId":"OxHcK4AJFmw"
   },
   {
      "name":"Terraform",
      "artist":[
         "Solatrus"
      ],
      "albumName":"Homestuck Vol. 8",
      "tags":[
         "Jade",
         "Dave",
         "Jack Noir",
         "Frogs",
         "LoFaF"
      ],
      "youtubeId":"bWp5uUxarAQ"
   },
   {
      "name":"Horschestra STRONG Version",
      "artist":[
         "Alex Rosetti"
      ],
      "albumName":"AlterniaBound",
      "tags":[
         "Equius"
      ],
      "youtubeId":"HHlaLTjv4So"
   },
   {
      "name":"The Temple\'s Withered Bloom",
      "artist":[
         "Alex Rosetti"
      ],
      "albumName":"Genesis Frog",
      "tags":[
         "WV",
         "PM",
         "AR",
         "WQ",
         "Lotus",
         "Frog Temple"
      ],
      "youtubeId":"wq6cPhclEsk"
   },
   {
      "name":"Candles and Clockwork",
      "artist":[
         "James Dever"
      ],
      "albumName":"Homestuck Vol. 5",
      "tags":[
         "Dave"
      ],
      "youtubeId":"gTNWsk3dKqc"
   },
   {
      "name":"Temporal Shenanigans",
      "artist":[
         "Rachel Rose Mitchell"
      ],
      "albumName":"coloUrs and mayhem: Universe A",
      "tags":[
         "Aradia"
      ],
      "youtubeId":"gO2SMV0lY3U"
   },
   {
      "name":"A Taste for Adventure",
      "artist":[
         "Seth Peelle"
      ],
      "albumName":"Homestuck Vol. 9",
      "tags":[
         "Jake",
         "Earth"
      ],
      "youtubeId":"S4kIfIUSV6o"
   },
   {
      "name":"Atomyk Ebonpyre",
      "artist":[
         "Toby Fox"
      ],
      "albumName":"Homestuck Vol. 4",
      "tags":[
         "Dave",
         "LoHaC"
      ],
      "youtubeId":"zECoOQ668nA"
   },
   {
      "name":"The Lordling",
      "artist":[
         "Toby Fox"
      ],
      "albumName":"Cherubim",
      "tags":[
         "Caliborn"
      ],
      "youtubeId":"nLrChSME0T8"
   },
   {
      "name":"Can Town",
      "artist":[
         "Alex Rosetti"
      ],
      "albumName":"Homestuck Vol. 5",
      "tags":[
         "WV",
         "Serenity",
         "Can Town"
      ],
      "youtubeId":"-BP2X5opz4M"
   },
   {
      "name":"Frostbite",
      "artist":[
         "Toby Fox"
      ],
      "albumName":"Homestuck Vol. 8",
      "tags":[
         "LoFaF"
      ],
      "youtubeId":"Gdb_nZs6plw"
   },
   {
      "name":"Chorale for Jaspers",
      "artist":[
         "Michael Guy Bowman"
      ],
      "albumName":"Homestuck Vol. 3",
      "tags":[
         "Rose",
         "Rose\'s mom"
      ],
      "youtubeId":"YkSq79X6MAw"
   },
   {
      "name":"Whistling Jackhammer",
      "artist":[
         "Robert J! Lake"
      ],
      "albumName":"Homestuck Vol. 9",
      "tags":[
         "Dirk",
         "Sawtooth",
         "Imperial Drones"
      ],
      "youtubeId":"a9ExRM4xlw8"
   },
   {
      "name":"Midnight Calliope",
      "artist":[
         "Mark Hadley"
      ],
      "albumName":"AlterniaBound",
      "tags":[
         "Equius",
         "Gamzee",
         "Meteor"
      ],
      "youtubeId":"owRKKJN5PkE"
   },
   {
      "name":"Calamity",
      "artist":[
         "Michael Guy Bowman"
      ],
      "albumName":"Homestuck Vol. 8",
      "tags":[
         "Karkat",
         "Gamzee"
      ],
      "youtubeId":"oNeKbfEVi0g"
   },
   {
      "name":"The Carnival",
      "artist":[
         "Erik Scheele"
      ],
      "albumName":"Homestuck Vol. 7: At the Price of Oblivion",
      "tags":[
         "Gamzee"
      ],
      "youtubeId":"Q5gjYuJrfbc"
   },
   {
      "name":"Pipeorgankind",
      "artist":[
         "James Roach"
      ],
      "albumName":"coloUrs and mayhem: Universe B",
      "tags":[
         "John",
         "LoWaS"
      ],
      "youtubeId":"hTpm_sDz62M"
   },
   {
      "name":"Cobalt Thief",
      "artist":[
         "Ray McDougall"
      ],
      "albumName":"coloUrs and mayhem: Universe A",
      "tags":[
         "Vriska"
      ],
      "youtubeId":"44un1ADquPA"
   },
   {
      "name":"Lies With The Sea",
      "artist":[
         "Michael Guy Bowman"
      ],
      "albumName":"Mobius Trip and Hadron Kaleido",
      "tags":[
         "Hadron Kaleido"
      ],
      "youtubeId":"LH_tBygClwg"
   },
   {
      "name":"Squiddle Song",
      "artist":[
         "Maya Kern"
      ],
      "albumName":"coloUrs and mayhem: Universe B",
      "tags":[
         "Squiddles"
      ],
      "youtubeId":"eWNMf4-ZMOQ"
   },
   {
      "name":"Trepidation",
      "artist":[
         "Svix"
      ],
      "albumName":"Homestuck Vol. 9",
      "tags":[
         "Squarewave"
      ],
      "youtubeId":"WxLtO42_AJQ"
   },
   {
      "name":"Walk-Stab-Walk (R&E)",
      "artist":[
         "Erik Scheele"
      ],
      "albumName":"Homestuck Vol. 6: Heir Transparent",
      "tags":[
         "Jack Noir",
         "Karkat",
         "LoPaH"
      ],
      "youtubeId":"4UpaT_FjsH8"
   },
   {
      "name":"ASSAULT",
      "artist":[
         "Toby Fox"
      ],
      "albumName":"Homestuck Vol. 9",
      "tags":[
         "Roxy"
      ],
      "youtubeId":"4u_Gk9bH5_4"
   },
   {
      "name":"Requiem for an Exile",
      "artist":[
         "Solatrus"
      ],
      "albumName":"The Wanderers",
      "tags":[
         "WV",
         "PM",
         "AR",
         "WQ"
      ],
      "youtubeId":"aAphwUVKFm4"
   },
   {
      "name":"Violet Mariner",
      "artist":[
         "Willow Ascenzo"
      ],
      "albumName":"coloUrs and mayhem: Universe A",
      "tags":[
         "Dualscar",
         "Alternia"
      ],
      "youtubeId":"aXpnjMsqC6k"
   },
   {
      "name":"Blackest Heart (With Honks)",
      "artist":[
         "Toby Fox"
      ],
      "albumName":"AlterniaBound",
      "tags":[
         "Equius",
         "Gamzee",
         "The Grand Highblood"
      ],
      "youtubeId":"-lJrvGkxPMI"
   },
   {
      "name":"March of the Batterwitch",
      "artist":[
         "Marcy Nabors"
      ],
      "albumName":"coloUrs and mayhem: Universe B",
      "tags":[
         "The Condesce",
         "Imperial Drones"
      ],
      "youtubeId":"1YyY6v87qsA"
   },
   {
      "name":"Crystalanthology",
      "artist":[
         "Solatrus"
      ],
      "albumName":"Homestuck Vol. 6: Heir Transparent",
      "tags":[
         "Vriska"
      ],
      "youtubeId":"3wsZuqG5IY0"
   },
   {
      "name":"A Little Fight Mewsic",
      "artist":[
         "Thomas Ferkol"
      ],
      "albumName":"Homestuck Vol. 9",
      "tags":[
         "God Cat"
      ],
      "youtubeId":"O-EtZR02vGA"
   },
   {
      "name":"The Blind Prophet",
      "artist":[
         "Toby Fox"
      ],
      "albumName":"AlterniaBound",
      "tags":[
         "Sollux"
      ],
      "youtubeId":"nClhUae7DMs"
   },
   {
      "name":"Green Ghost",
      "artist":[
         "Monobrow"
      ],
      "albumName":"coloUrs and mayhem: Universe B",
      "tags":[
         "John",
         "LoWaS"
      ],
      "youtubeId":"nx2CqNp16IA"
   },
   {
      "name":"The Sound of Pure Squid Giggles",
      "artist":[
         "Nick Smalley"
      ],
      "albumName":"Squiddles!",
      "tags":[
         "No tags"
      ],
      "youtubeId":"9tOJOpn1UD0"
   },
   {
      "name":"Clockwork Contrivance",
      "artist":[
         "Clark Powell"
      ],
      "albumName":"Homestuck Vol. 5",
      "tags":[
         "Dave",
         "LoWaS"
      ],
      "youtubeId":"b3VZo6aQyoc"
   },
   {
      "name":"Time on My Side",
      "artist":[
         "Tensei"
      ],
      "albumName":"Strife!",
      "tags":[
         "Dave",
         "LoHaC"
      ],
      "youtubeId":"_zfOKIREJX8"
   },
   {
      "name":"Wind",
      "artist":[
         "Clark Powell"
      ],
      "albumName":"Medium",
      "tags":[
         "No tags"
      ],
      "youtubeId":"mwFWnxZVIFk"
   },
   {
      "name":"Game Over",
      "artist":[
         "Erik Scheele"
      ],
      "albumName":"One Year Older",
      "tags":[
         "John"
      ],
      "youtubeId":"II2Fgetf2So"
   },
   {
      "name":"Eldritch",
      "artist":[
         "Alex Rosetti"
      ],
      "albumName":"The Felt",
      "tags":[
         "Felt Manor"
      ],
      "youtubeId":"6hDMo_pcaD8"
   },
   {
      "name":"Rex Duodecim Angelus",
      "artist":[
         "Malcolm Brown"
      ],
      "albumName":"AlterniaBound",
      "tags":[
         "BK",
         "Karkat",
         "Aradia",
         "Kanaya",
         "Vriska",
         "Battlefield",
         "Skaia"
      ],
      "youtubeId":"hn6Yl371mIc"
   },
   {
      "name":"Crystamanthequins",
      "artist":[
         "Erik Scheele"
      ],
      "albumName":"Homestuck Vol. 5",
      "tags":[
         "Aradia",
         "Terezi",
         "Vriska"
      ],
      "youtubeId":"9YhdlvBqG5w"
   },
   {
      "name":"Anbroids V2.0",
      "artist":[
         "Malcolm Brown"
      ],
      "albumName":"Homestuck Vol. 9",
      "tags":[
         "Dirk",
         "Squarewave",
         "SBaHJ"
      ],
      "youtubeId":"AVPmUpN_W3o"
   },
   {
      "name":"Phrenic Phever",
      "artist":[
         "Clark Powell"
      ],
      "albumName":"Homestuck Vol. 6: Heir Transparent",
      "tags":[
         "Karkat",
         "Gamzee",
         "Meteor"
      ],
      "youtubeId":"8rlgjJgyhyw"
   },
   {
      "name":"Earthsea Borealis",
      "artist":[
         "Toby Fox"
      ],
      "albumName":"Homestuck Vol. 7: At the Price of Oblivion",
      "tags":[
         "Rose",
         "Horrorterrors"
      ],
      "youtubeId":"KXgPNyd45A4"
   },
   {
      "name":"Frog Forager",
      "artist":[
         "Erik Scheele"
      ],
      "albumName":"Homestuck Vol. 8",
      "tags":[
         "Dave",
         "Jade",
         "Frogs",
         "Jack Noir",
         "LoFaF"
      ],
      "youtubeId":"5KihK9orAi0"
   },
   {
      "name":"Clockstopper",
      "artist":[
         "viaSatellite",
         "infiniteKnife"
      ],
      "albumName":"coloUrs and mayhem: Universe B",
      "tags":[
         "Dave",
         "LoHaC"
      ],
      "youtubeId":"CO5IN60Nt9E"
   },
   {
      "name":"Squiddles Campfire",
      "artist":[
         "Erik Scheele"
      ],
      "albumName":"Squiddles!",
      "tags":[
         "No tags"
      ],
      "youtubeId":"sBi2kKCDDCk"
   },
   {
      "name":"Carefree Princess Berryboo",
      "artist":[
         "Alex Rosetti"
      ],
      "albumName":"Squiddles!",
      "tags":[
         "No tags"
      ],
      "youtubeId":"_Z8qT1GBhds"
   },
   {
      "name":"Indigo Archer",
      "artist":[
         "Rachel Rose Mitchell"
      ],
      "albumName":"coloUrs and mayhem: Universe A",
      "tags":[
         "The Expatriate"
      ],
      "youtubeId":"3F0S20HUuNA"
   },
   {
      "name":"AlterniaBound",
      "artist":[
         "Toby Fox"
      ],
      "albumName":"AlterniaBound",
      "tags":[
         "No tags"
      ],
      "youtubeId":"FD5tJvMtzDo"
   },
   {
      "name":"Ugly Betty",
      "artist":[
         "Robert J! Lake"
      ],
      "albumName":"Homestuck Vol. 9",
      "tags":[
         "The Condesce"
      ],
      "youtubeId":"EdCZ1kKzb8A"
   },
   {
      "name":"Let\'s All Rock the Heist",
      "artist":[
         "Robert J! Lake"
      ],
      "albumName":"Homestuck Vol. 7: At the Price of Oblivion",
      "tags":[
         "Jack Noir",
         "DD",
         "CD",
         "HB"
      ],
      "youtubeId":"h1kBHhIFYIQ"
   },
   {
      "name":"Ohgodcat",
      "artist":[
         "Eligecos"
      ],
      "albumName":"coloUrs and mayhem: Universe B",
      "tags":[
         "God Cat"
      ],
      "youtubeId":"s-4SihyV1yU"
   },
   {
      "name":"Jade\'s Lullaby",
      "artist":[
         "David Ko"
      ],
      "albumName":"Homestuck Vol. 5",
      "tags":[
         "Jade",
         "Squiddles"
      ],
      "youtubeId":"7gD-IPfN6FU"
   },
   {
      "name":"Moonsweater",
      "artist":[
         "David Ellis"
      ],
      "albumName":"Homestuck Vol. 10",
      "tags":[
         "Jack Noir",
         "DD",
         "CD",
         "HB"
      ],
      "youtubeId":"mB3xE2h_Bo4"
   },
   {
      "name":"Cairo Overcoat",
      "artist":[
         "Thomas Ferkol"
      ],
      "albumName":"Homestuck Vol. 9",
      "tags":[
         "Lord English"
      ],
      "youtubeId":"PLYF-Zlfd2c"
   },
   {
      "name":"Knife\'s Edge",
      "artist":[
         "Tensei"
      ],
      "albumName":"Strife!",
      "tags":[
         "Jack Noir",
         "Davesprite",
         "Dave\'s bro",
         "LoWaS"
      ],
      "youtubeId":"eYdneOkIvvc"
   },
   {
      "name":"Clockwork",
      "artist":[
         "Clark Powell"
      ],
      "albumName":"Medium",
      "tags":[
         "No tags"
      ],
      "youtubeId":"AeUNnQ0ZkvA"
   },
   {
      "name":"Pink Cat",
      "artist":[
         "Ryan Ames"
      ],
      "albumName":"coloUrs and mayhem: Universe B",
      "tags":[
         "Roxy",
         "Cats"
      ],
      "youtubeId":"mF7WdQcSkHI"
   },
   {
      "name":"dESPERADO ROCKET CHAIRS,",
      "artist":[
         "Toby Fox"
      ],
      "albumName":"Alternia",
      "tags":[
         "Tavros",
         "LoSaZ"
      ],
      "youtubeId":"KPYJ9TuXZmQ"
   },
   {
      "name":"Escape Pod",
      "artist":[
         "Michael Guy Bowman"
      ],
      "albumName":"Homestuck Vol. 8",
      "tags":[
         "WV",
         "CD",
         "Rabbit"
      ],
      "youtubeId":"GJo7ZH6p6wA"
   },
   {
      "name":"Ocean Stars Falling",
      "artist":[
         "Michael Guy Bowman"
      ],
      "albumName":"Homestuck Vol. 8",
      "tags":[
         "Squiddles"
      ],
      "youtubeId":"QmEZzp7o8i8"
   },
   {
      "name":"Dissension (Remix)",
      "artist":[
         "David Ko"
      ],
      "albumName":"Homestuck Vol. 3",
      "tags":[
         "Jade",
         "Jade\'s grandpa"
      ],
      "youtubeId":"mkPXt83h73w"
   },
   {
      "name":"Gust of Heir",
      "artist":[
         "James Dever",
         "Michael Guy Bowman"
      ],
      "albumName":"Homestuck Vol. 8",
      "tags":[
         "John"
      ],
      "youtubeId":"pGlTekY3qZI"
   },
   {
      "name":"Purple Bard",
      "artist":[
         "Eligecos"
      ],
      "albumName":"coloUrs and mayhem: Universe A",
      "tags":[
         "Gamzee",
         "Dark Carnival"
      ],
      "youtubeId":"H9PK2xF_b3I"
   },
   {
      "name":"Space Prankster",
      "artist":[
         "David Ko"
      ],
      "albumName":"Homestuck Vol. 5",
      "tags":[
         "John"
      ],
      "youtubeId":"Vho9Rgk2kHQ"
   },
   {
      "name":"Break Shot",
      "artist":[
         "Kalibration"
      ],
      "albumName":"Homestuck Vol. 9",
      "tags":[
         "Lord English"
      ],
      "youtubeId":"E07s837gHWE"
   },
   {
      "name":"Nightmare",
      "artist":[
         "Malcolm Brown"
      ],
      "albumName":"The Wanderers",
      "tags":[
         "WV",
         "Carapacians",
         "Battlefield"
      ],
      "youtubeId":"CVxTL43Czuc"
   },
   {
      "name":"psych0ruins",
      "artist":[
         "Toby Fox"
      ],
      "albumName":"Alternia",
      "tags":[
         "Aradia"
      ],
      "youtubeId":"ueccsD074a8"
   },
   {
      "name":"The Lemonsnout Turnabout",
      "artist":[
         "Toby Fox"
      ],
      "albumName":"Alternia",
      "tags":[
         "Terezi",
         "Scalemates"
      ],
      "youtubeId":"pvgyx0w8B1E"
   },
   {
      "name":"An Unbreakable Union",
      "artist":[
         "Kalibration"
      ],
      "albumName":"Homestuck Vol. 5",
      "tags":[
         "Jade",
         "Becquerel"
      ],
      "youtubeId":"jc_q5lCjw0Q"
   },
   {
      "name":"Revered Return",
      "artist":[
         "Nick Smalley",
         "Michael Guy Bowman"
      ],
      "albumName":"Homestuck Vol. 8",
      "tags":[
         "Terezi",
         "Vriska"
      ],
      "youtubeId":"x3ruWSdc1Vo"
   },
   {
      "name":"Moonsetter",
      "artist":[
         "Toby Fox"
      ],
      "albumName":"Homestuck Vol. 9",
      "tags":[
         "Dirk",
         "Earth"
      ],
      "youtubeId":"75kJb_aAvKY"
   },
   {
      "name":"Moonshatter",
      "artist":[
         "Toby Fox"
      ],
      "albumName":"Homestuck Vol. 5",
      "tags":[
         "Dave"
      ],
      "youtubeId":"pGgEeNu-WpY"
   },
   {
      "name":"Blue Noir",
      "artist":[
         "David Ko"
      ],
      "albumName":"Midnight Crew: Drawing Dead",
      "tags":[
         "DD"
      ],
      "youtubeId":"YvMyo3opDOY"
   },
   {
      "name":"Oppa Toby Style",
      "artist":[
         "Toby Fox"
      ],
      "albumName":"[S] Collide.",
      "tags":[
         "The Condesce",
         "John",
         "Rose",
         "Roxy",
         "John\'s nanna",
         "Derse"
      ],
      "youtubeId":"bjAK8D5ytiU"
   },
   {
      "name":"Atomic Bonsai",
      "artist":[
         "Tensei"
      ],
      "albumName":"Strife!",
      "tags":[
         "Jade"
      ],
      "youtubeId":"ncLsScNc4ZY"
   },
   {
      "name":"Karkat\'s Theme",
      "artist":[
         "Toby Fox"
      ],
      "albumName":"AlterniaBound",
      "tags":[
         "Karkat",
         "Meteor"
      ],
      "youtubeId":"8M5FxI_p5wg"
   },
   {
      "name":"Olive Rogue",
      "artist":[
         "Eston Schweickart"
      ],
      "albumName":"coloUrs and mayhem: Universe A",
      "tags":[
         "Nepeta",
         "Karkat"
      ],
      "youtubeId":"L2BLUyv7UT0"
   },
   {
      "name":"Let the Squiddles Sleep (End Theme)",
      "artist":[
         "Robert J! Lake"
      ],
      "albumName":"Squiddles!",
      "tags":[
         "No tags"
      ],
      "youtubeId":"mpn7lahHvEc"
   },
   {
      "name":"Solar Voyage",
      "artist":[
         "Marcy Nabors"
      ],
      "albumName":"Homestuck Vol. 10",
      "tags":[
         "Jack Noir",
         "PM",
         "Green Sun",
         "Dream Bubbles"
      ],
      "youtubeId":"r78qOIX8ITI"
   },
   {
      "name":"Pilot Light",
      "artist":[
         "Thomas Ibarra"
      ],
      "albumName":"coloUrs and mayhem: Universe B",
      "tags":[
         "Jade",
         "Green Sun",
         "LoWaS",
         "LoLaR",
         "LoHaC",
         "LoFaF"
      ],
      "youtubeId":"05nEUculjxM"
   },
   {
      "name":"Stoke the Forge",
      "artist":[
         "Alex Rosetti"
      ],
      "albumName":"Genesis Frog",
      "tags":[
         "Frogs",
         "LoFaF"
      ],
      "youtubeId":"6jLZLPJE_Qc"
   },
   {
      "name":"Beatdown DX",
      "artist":[
         "Curt Blakeslee"
      ],
      "albumName":"Homestuck Vol. 10",
      "tags":[
         "Dave",
         "Dave\'s bro",
         "Earth"
      ],
      "youtubeId":"EJPtdTzq6uY"
   },
   {
      "name":"Dapper Dueling",
      "artist":[
         "Max Wright"
      ],
      "albumName":"coloUrs and mayhem: Universe B",
      "tags":[
         "Jake"
      ],
      "youtubeId":"-bnKY83hEAc"
   },
   {
      "name":"John Sleeps / Skaian Magicant",
      "artist":[
         "Toby Fox"
      ],
      "albumName":"Homestuck Vol. 1-4",
      "tags":[
         "Jade"
      ],
      "youtubeId":"ZAKLIH_anJI"
   },
   {
      "name":"Vodka Mutiny",
      "artist":[
         "Catboss"
      ],
      "albumName":"coloUrs and mayhem: Universe B",
      "tags":[
         "Roxy",
         "Cats"
      ],
      "youtubeId":"H1Ry5qhIaOs"
   },
   {
      "name":"Explore Remix",
      "artist":[
         "Gabe Nezovic"
      ],
      "albumName":"Homestuck Vol. 3",
      "tags":[
         "No tags"
      ],
      "youtubeId":"06qu70cLPaE"
   },
   {
      "name":"Afraid of the Darko",
      "artist":[
         "Robert J! Lake"
      ],
      "albumName":"Homestuck Vol. 8",
      "tags":[
         "Doc Scratch"
      ],
      "youtubeId":"PSbPXcKGYto"
   },
   {
      "name":"Riches to Ruins Movements I & II",
      "artist":[
         "Toby Fox"
      ],
      "albumName":"The Wanderers",
      "tags":[
         "WK"
      ],
      "youtubeId":"K7WRrBG6BM8"
   },
   {
      "name":"Vagabounce Remix",
      "artist":[
         "David Ko"
      ],
      "albumName":"The Wanderers",
      "tags":[
         "WV",
         "Ruined Earth"
      ],
      "youtubeId":"P_pCaNbhExo"
   },
   {
      "name":"Unlabeled",
      "artist":[
         "Erik Scheele"
      ],
      "albumName":"One Year Older",
      "tags":[
         "John",
         "Rose",
         "Dave",
         "Jade",
         "Karkat",
         "Aradia",
         "Tavros",
         "Sollux",
         "Nepeta",
         "Kanaya",
         "Terezi",
         "Vriska",
         "Equius",
         "Gamzee",
         "Eridan",
         "Feferi",
         "John\'s dad",
         "Lil Cal",
         "Earth"
      ],
      "youtubeId":"xVuZ5DGLsNk"
   },
   {
      "name":"Revelawesome",
      "artist":[
         "Malcolm Brown"
      ],
      "albumName":"Homestuck Vol. 4",
      "tags":[
         "John"
      ],
      "youtubeId":"yeIEkiIjw5c"
   },
   {
      "name":"Humphrey\'s Lullaby",
      "artist":[
         "Toby Fox"
      ],
      "albumName":"The Felt",
      "tags":[
         "DD",
         "The Felt",
         "Felt Manor"
      ],
      "youtubeId":"N_Iddbz7p-k"
   },
   {
      "name":"Sound Judgement",
      "artist":[
         "Malcolm Brown"
      ],
      "albumName":"Homestuck Vol. 10",
      "tags":[
         "Terezi"
      ],
      "youtubeId":"SgFCP-3EkNw"
   },
   {
      "name":"Nine Lives One Love",
      "artist":[
         "Dallas Ross Hicks"
      ],
      "albumName":"coloUrs and mayhem: Universe A",
      "tags":[
         "The Signless",
         "The Disciple",
         "Nepeta",
         "Karkat",
         "Alternia"
      ],
      "youtubeId":"woprOPxAOAE"
   },
   {
      "name":"Years in the Future",
      "artist":[
         "Robert J! Lake"
      ],
      "albumName":"The Wanderers",
      "tags":[
         "WV",
         "PM",
         "AR",
         "WQ",
         "WK",
         "Carapacians",
         "Ruined Earth",
         "Can Town"
      ],
      "youtubeId":"CadBaY2j6pE"
   },
   {
      "name":"Shade",
      "artist":[
         "Clark Powell"
      ],
      "albumName":"Medium",
      "tags":[
         "No tags"
      ],
      "youtubeId":"uptvTul6I3Q"
   },
   {
      "name":"3 In The Morning (Pianokind)",
      "artist":[
         "Erik Scheele"
      ],
      "albumName":"Homestuck Vol. 6: Heir Transparent",
      "tags":[
         "Snowman"
      ],
      "youtubeId":"OesxlnyaBGE"
   },
   {
      "name":"Maplehoof\'s Adventure",
      "artist":[
         "Michael Guy Bowman"
      ],
      "albumName":"Homestuck Vol. 7: At the Price of Oblivion",
      "tags":[
         "Rose",
         "Maplehoof"
      ],
      "youtubeId":"d-7SMRSvnv8"
   },
   {
      "name":"Indigo Heir",
      "artist":[
         "Paul Tuttle Starr"
      ],
      "albumName":"coloUrs and mayhem: Universe A",
      "tags":[
         "Equius"
      ],
      "youtubeId":"tdzG9kkxGt8"
   },
   {
      "name":"Bronze Page",
      "artist":[
         "Ian White",
         "Eston Schweickart"
      ],
      "albumName":"coloUrs and mayhem: Universe A",
      "tags":[
         "Tavros",
         "LoSaZ"
      ],
      "youtubeId":"2ZmG8q5z1XE"
   },
   {
      "name":"Feel (Alive)",
      "artist":[
         "Luke Benjamins",
         "Robert J! Lake"
      ],
      "albumName":"Homestuck Vol. 10",
      "tags":[
         "Jade",
         "Rose",
         "Dave",
         "Karkat",
         "Kanaya",
         "Terezi",
         "Gamzee",
         "Green Sun",
         "Meteor"
      ],
      "youtubeId":"Yngy9XIzRLY"
   },
   {
      "name":"Temporary",
      "artist":[
         "Robert J! Lake"
      ],
      "albumName":"Homestuck Vol. 8",
      "tags":[
         "Dave",
         "The Felt",
         "Earth"
      ],
      "youtubeId":"CYh1OlRWzp4"
   },
   {
      "name":"Rainbow Valley",
      "artist":[
         "David Ko"
      ],
      "albumName":"Squiddles!",
      "tags":[
         "No tags"
      ],
      "youtubeId":"MrOqTjNrB2Q"
   },
   {
      "name":"Questant\'s Lament",
      "artist":[
         "Thomas Ferkol"
      ],
      "albumName":"Homestuck Vol. 8",
      "tags":[
         "WQ",
         "Ruined Earth"
      ],
      "youtubeId":"ILt6Qyxc1bw"
   },
   {
      "name":"Stargaze",
      "artist":[
         "Nick Smalley"
      ],
      "albumName":"Homestuck Vol. 9",
      "tags":[
         "Jane",
         "Roxy",
         "Dirk",
         "Jake",
         "Maplehoof",
         "Lil Cal",
         "Earth"
      ],
      "youtubeId":"ZTkdO7c4MEE"
   },
   {
      "name":"Skaian Skirmish",
      "artist":[
         "Andrew Huo",
         "Toby Fox"
      ],
      "albumName":"Homestuck Vol. 5",
      "tags":[
         "WV",
         "Carapacians"
      ],
      "youtubeId":"_EB_Y3biviY"
   },
   {
      "name":"WV: Become the Mayor of Cans",
      "artist":[
         "Solarbear"
      ],
      "albumName":"coloUrs and mayhem: Universe B",
      "tags":[
         "WV",
         "Serenity",
         "Ruined Earth",
         "Can Town"
      ],
      "youtubeId":"uQ-OLuD6WAM"
   },
   {
      "name":"Clockwork Reversal",
      "artist":[
         "Thomas Ferkol"
      ],
      "albumName":"The Felt",
      "tags":[
         "Felt Manor"
      ],
      "youtubeId":"b9BtuKqfPkY"
   },
   {
      "name":"Swing of the Clock",
      "artist":[
         "Solatrus"
      ],
      "albumName":"The Felt",
      "tags":[
         "Felt Manor"
      ],
      "youtubeId":"kEGailT_BHs"
   },
   {
      "name":"Do You Remem8er Me",
      "artist":[
         "Malcolm Brown"
      ],
      "albumName":"Homestuck Vol. 8",
      "tags":[
         "John",
         "Vriska",
         "Dream Bubbles"
      ],
      "youtubeId":"x8mLKTY5TWM"
   },
   {
      "name":"Temporal Piano",
      "artist":[
         "Erik Scheele"
      ],
      "albumName":"The Felt",
      "tags":[
         "Felt Manor"
      ],
      "youtubeId":"rzNIMmObP3k"
   },
   {
      "name":"Lazybones",
      "artist":[
         "Steve Everson"
      ],
      "albumName":"Squiddles!",
      "tags":[
         "No tags"
      ],
      "youtubeId":"15iiMuYvA_Q"
   },
   {
      "name":"Red Sucker",
      "artist":[
         "Kalibration"
      ],
      "albumName":"Cherubim",
      "tags":[
         "Caliborn"
      ],
      "youtubeId":"lp7kDAHaWIc"
   },
   {
      "name":"Skaianet",
      "artist":[
         "Mark Hadley"
      ],
      "albumName":"Homestuck Vol. 9",
      "tags":[
         "Earth"
      ],
      "youtubeId":"QLdoDAn290A"
   },
   {
      "name":"The Scratch",
      "artist":[
         "Erik Scheele"
      ],
      "albumName":"One Year Older",
      "tags":[
         "John",
         "LoHaC"
      ],
      "youtubeId":"5UrMv4IQclk"
   },
   {
      "name":"Blackest Heart",
      "artist":[
         "Mark Hadley"
      ],
      "albumName":"Homestuck Vol. 6: Heir Transparent",
      "tags":[
         "Gamzee"
      ],
      "youtubeId":"t12NlwsrlAQ"
   },
   {
      "name":"FIDUSPAWN, GO!",
      "artist":[
         "David Ko"
      ],
      "albumName":"AlterniaBound",
      "tags":[
         "Tavros",
         "Tavros\' lusus",
         "Karkat",
         "Vriska"
      ],
      "youtubeId":"fCBr-v4JswY"
   },
   {
      "name":"Tangled Waltz",
      "artist":[
         "Michael Guy Bowman"
      ],
      "albumName":"Squiddles!",
      "tags":[
         "No tags"
      ],
      "youtubeId":"3KdAsyPq4UM"
   },
   {
      "name":"Starfall",
      "artist":[
         "Solatrus"
      ],
      "albumName":"Homestuck Vol. 10",
      "tags":[
         "Rose",
         "Jaspers",
         "Earth"
      ],
      "youtubeId":"1H26iJNOtx4"
   },
   {
      "name":"Checkmate",
      "artist":[
         "David Dycus"
      ],
      "albumName":"coloUrs and mayhem: Universe B",
      "tags":[
         "Jack Noir",
         "Derse"
      ],
      "youtubeId":"Z6IMxFibTTQ"
   },
   {
      "name":"Courser",
      "artist":[
         "Seth Peelle",
         "Alex Rosetti"
      ],
      "albumName":"Homestuck Vol. 6: Heir Transparent",
      "tags":[
         "Becquerel"
      ],
      "youtubeId":"EmdRALDqt7k"
   },
   {
      "name":"mIrAcLeS",
      "artist":[
         "Toby Fox"
      ],
      "albumName":"Alternia",
      "tags":[
         "Gamzee",
         "Gamzee\'s lusus",
         "Alternia"
      ],
      "youtubeId":"ztovKMiiolU"
   },
   {
      "name":"Blue Atom",
      "artist":[
         "David Ellis"
      ],
      "albumName":"coloUrs and mayhem: Universe B",
      "tags":[
         "Jade",
         "Frogs"
      ],
      "youtubeId":"QrB8PrwBIY0"
   },
   {
      "name":"Ascend",
      "artist":[
         "Tensei"
      ],
      "albumName":"Homestuck Vol. 10",
      "tags":[
         "John",
         "Aradia"
      ],
      "youtubeId":"e9gGXYbtSt8"
   },
   {
      "name":"Savior of the Dreaming Dead",
      "artist":[
         "Malcolm Brown"
      ],
      "albumName":"Homestuck Vol. 7: At the Price of Oblivion",
      "tags":[
         "Dave"
      ],
      "youtubeId":"Y0PIafh0ssQ"
   },
   {
      "name":"Lotus (Bloom)",
      "artist":[
         "Seth Peelle"
      ],
      "albumName":"Homestuck Vol. 8",
      "tags":[
         "Lotus"
      ],
      "youtubeId":"RrIofwv86HM"
   },
   {
      "name":"Walls Covered in Blood DX",
      "artist":[
         "Toby Fox"
      ],
      "albumName":"Alternia",
      "tags":[
         "Nepeta",
         "Nepeta\'s lusus",
         "Alternia"
      ],
      "youtubeId":"WPqca-NWV2k"
   },
   {
      "name":"Medical Emergency",
      "artist":[
         "Alex Rosetti"
      ],
      "albumName":"Homestuck Vol. 5",
      "tags":[
         "John"
      ],
      "youtubeId":"aCci9GxbsSM"
   },
   {
      "name":"Black Hole / Green Sun",
      "artist":[
         "Malcolm Brown",
         "Toby Fox"
      ],
      "albumName":"Homestuck Vol. 8",
      "tags":[
         "No tags"
      ],
      "youtubeId":"B6e2XacZSIs"
   },
   {
      "name":"Rustless Fall",
      "artist":[
         "Gabe Stilez"
      ],
      "albumName":"coloUrs and mayhem: Universe B",
      "tags":[
         "Rose",
         "Horrorterrors",
         "Green Sun"
      ],
      "youtubeId":"YDzxho9uztI"
   }
]